export function truncate_text(str, n, useWordBoundary) {
    if (str.length <= n) { return str; }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary
        ? subString.slice(0, subString.lastIndexOf(" "))
        : subString) + " ...";
};

// set date to init of the day
export function set_date_to_init_of_day(date) {
    date.setHours(0, 0, 0, 0);
    return date;
};

// set date to end of the day
export function set_date_to_end_of_day(date) {
    date.setHours(23, 59, 59, 999);
    return date;
};