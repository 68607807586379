import React, { createContext, useContext, useState } from 'react';
import LoadingOverlay from "components/loadingOverlay/LoadingOverlay";

const LoadingContext = createContext();

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    const [loading, setLoading] = context;
    return { loading, setLoading };
};


export const useIsLoading = () => {
    const { loading } = useLoading();
    return loading;
};


export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    return (
        <LoadingContext.Provider value={[loading, setLoading]}>
            {children}
            {loading && <LoadingOverlay isLoading={loading} />}
        </LoadingContext.Provider>
    );
};

// If you need direct access to the context in other components
export { LoadingContext };
export default LoadingContext;