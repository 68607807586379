import React from 'react';
import { Box, Typography } from '@mui/material';

const ProductProfitableTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { name, value, quantity, total_usd_invoice_value, total_usd_cost_value, usd_margin, totalProfit } = payload[0].payload;

        const pct_profit = (value / totalProfit) * 100;

        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '4px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '250px',
                }}
            >
                <Typography variant="body2" color="textPrimary" mb={1.5} sx={{ fontWeight: 'bold' }}>
                    {name}
                </Typography>
                <Typography variant="body1" color="textPrimary" mb={1}>
                    Profit: {value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} | ({pct_profit.toFixed(2)}%)
                </Typography>
                <Typography variant="body1" color="textPrimary" mb={1}>
                    Quantity: {quantity}
                </Typography>
                <Typography variant="body2" color="textSecondary" mb={0.5}>
                    Total Cost: {total_usd_cost_value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </Typography>
                <Typography variant="body2" color="textSecondary" mb={0.5}>
                    Total Sold: {total_usd_invoice_value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </Typography>

            </Box>
        );
    }

    return null;
};

export default ProductProfitableTooltip;
