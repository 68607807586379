import { useState, useContext } from "react";
import React from 'react';
import { useFetchData } from "services/queries";
import Box from '@mui/material/Box';
import {
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import { GRID_AGGREGATION_ROOT_FOOTER_ROW_ID } from '@mui/x-data-grid-premium';


import { API_BASE_URL } from "config";
import Dropdown from "components/dropdown";
import { AiOutlineMenu } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { downloadFile } from 'utils/downloadFile';
import AuthContext from 'contexts/AuthProvider';
import { useLoading } from 'contexts/LoadingContext';
import { withLoadingAndAlert } from 'utils/withLoading';
import { useNotification } from 'contexts/NotificationContext'
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const USDcurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

const RMBcurrencyFormatter = new Intl.NumberFormat('zh-CN', {
  style: 'currency',
  currency: 'CNY',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

const CommercialInvoice = ({ formData, setShouldUpdateData }) => {
  const resourcePath = 'order/commercial-invoice';
  const relatedResources = `order/commercial-invoice/?product_item_sales_order__sales_order=${formData.id}`;
  const [tableData, setTableData] = useState([]);
  const { data: products, isLoading: isLoadingProducts, error: errorProducts } = useFetchData('product', true);
  const { data: skuClientData, error: errorSkuClientData, isLoading: isLoadingSkuClientData } = useFetchData(`product/client-sku/?client=${formData.buyer_id}`, true);
  const apiRef = useGridApiRef();
  const [commercialFiles, setCommercialFiles] = useState([]);
  const { apiRequest } = useContext(AuthContext);
  const { setLoading } = useLoading();
  const { setNotification } = useNotification();
  const downloadWithLoading = withLoadingAndAlert(setLoading, setNotification, downloadFile);
  const { data: files, isLoading: isLoadingFiles, error: errorFiles } = useFetchData(`order/sales-order-files/?file_type=CI&sales_order=${formData.id}`, true);

  const actions = {
    create: false,
    edit: true,
    delete: false,
  }

  const columnsDataItems = [
    // product picture
    {
      field: 'picture',
      headerName: '',
      editable: false,
      groupable: false,
      aggregable: false,
      maxWidth: 50,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
          return [];
        }
        return (
          <Box style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', justify: 'center' }}>
            {
              params.row.product && params.row.product.picture ? (
                <img src={params.row.product.picture} className="w-10 h-10 object-contain rounded-full drop-shadow-md" />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-100"></div>
              )
            }
          </Box>
        )
      }
    },
    {
      field: 'product_id',
      headerName: 'PRODUCT',
      editable: false,
      groupable: false,
      type: 'singleSelect',
      minWidth: 250,
      maxWidth: 300,
      flex: 1,
      renderCell: (params) => {
        const { product, quantity } = params.row;
        if (product) {
          return (
            <Grid container>
              <Grid sx={{ lineHeight: 'normal' }} py={0.5} justifyContent="center" alignItems="center">
                <Typography variant="subtitle2">{product.name}</Typography>
                <Typography variant="caption">{product.sku} - {quantity} pcs</Typography>
              </Grid>
            </Grid>

          );
        }
        return '';
      },
      valueSetter: (value, row) => {
        const selectedProduct = products.find(product => product.id === value);
        if (!selectedProduct) {
          return row;
        }
        const updatedRow = {
          ...row,
          product_id: selectedProduct.id,
          product: selectedProduct,
          quantity: row.quantity
        };
        return updatedRow;
      },
      valueGetter: (value, row) => {
        return `${row.product?.name} - ${row.product?.sku}`;
      },
    },
    // sku
    {
      field: 'sku',
      headerName: 'SKU',
      editable: false,
      groupable: false,
      valueGetter: (value, row) => {
        return row.product?.sku;
      },
    },
    // client sku
    {
      field: 'client_sku',
      headerName: 'Client SKU',
      editable: false,
      groupable: false,
      type: 'string',
      valueGetter: (value, row) => {
        return skuClientData?.find(sku => sku.product === row.product?.id)?.sku;
      },
    },
    {
      field: 'quantity',
      headerName: 'QUANTITY',
      editable: false,
      groupable: false,
      type: 'number',
    },
    // us_per_unit
    {
      field: 'us_per_unit',
      headerName: 'US PER UNIT',
      editable: true,
      groupable: false,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return USDcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    },
    {
      field: 'total_amount',
      headerName: 'TOTAL AMOUNT',
      editable: false,
      groupable: false,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return USDcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    },
    {
      field: 'rmb_per_unit',
      headerName: 'RMB PER UNIT',
      editable: true,
      groupable: false,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return RMBcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    },
    {
      field: 'hs_code_ncm',
      headerName: 'HS CODE NCM',
      editable: true,
      groupable: false,
      type: 'string',
    },
    {
      field: 'og_manufacturer',
      headerName: 'OG MANUFACTURER',
      editable: true,
      groupable: false,
      type: 'string',
    },
    // type
    {
      field: 'type',
      headerName: 'TYPE',
      editable: true,
      groupable: false,
      type: 'string',
    },
    {
      field: 'country',
      headerName: 'COUNTRY',
      editable: true,
      groupable: false,
      type: 'string',
    },
  ];

  if (isLoadingProducts || isLoadingFiles || isLoadingSkuClientData) return <div>Loading...</div>
  if (errorProducts) return <div>Error</div>
  if (errorFiles) return <div>Error</div>
  if (errorSkuClientData) return <div>Error</div>

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <div className='flex justify-between items-center'>
        <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">Commercial Invoice Items</h3>
        {formData.order_identifier && (
          <div className='relative flex'>
            <Dropdown
              button={
                <button type="button" className="bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200 rounded-xl px-5 py-3 text-base font-medium text-white transition duration-200 dark:text-white drop-shadow">
                  <AiOutlineMenu />
                </button>
              }
              children={
                <div className="px-1 flex h-max w-62 flex-col justify-start rounded-[10px] bg-white bg-cover bg-no-repeat pb-4 shadow-[0_20px_25px_-5px] shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                  <div className="mt-3 ml-4 flex flex-col">
                    <button
                      className="text-sm text-gray-800 dark:text-white hover:dark:text-white hover:text-brand-500 flex items-center"
                      onClick={async () => {
                        await downloadWithLoading(`${API_BASE_URL}/order/generate-pdf-commercial-invoice/${formData.id}/`);
                        setShouldUpdateData(true);
                      }}
                      type="button"
                    >
                      <BsFillFileEarmarkPlusFill className="mr-2" />
                      New Commercial Invoice
                    </button>
                  </div>
                  <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                  {commercialFiles.map((file) => (
                    <div key={file.id} className="mt-3 ml-4 flex flex-col">
                      <button
                        className="text-xs text-gray-800 dark:text-white hover:dark:text-white hover:text-brand-500 flex items-center"
                        onClick={() => downloadWithLoading(file['file'])}
                        type="button"
                      >
                        <FaFilePdf className="mr-2" />
                        {file['file_name']}
                      </button>
                    </div>
                  ))}
                </div>
              }
              classNames={"py-2 top-[40px] -left-[250px] w-max drop-shadow-2xl"}
            />
          </div>
        )}
      </div>
      <Box>
        <EditableDataGrid
          columns={columnsDataItems}
          resourcePath={resourcePath}
          tableData={tableData}
          setTableData={setTableData}
          relatedResources={relatedResources}
          apiRef={apiRef}

          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            pinnedColumns: { left: ['actions', 'picture'] },
            aggregation: {
              model: {
                total_amount: 'sum',
              }
            },
            columns: {
              columnVisibilityModel: {
                sku: false,
                quantity: false,
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: false,
                },
              },
            }
          }}
          autosizeOptions={{
            columns: ['product_id', 'quantity', 'hs_code_ncm', 'og_manufacturer', 'rmb_per_unit', 'us_per_unit', 'total_amount', 'type', 'country'],
            includeOutliers: true,
            includeHeaders: true,
          }}
          actions={actions}
          autosizeOnMount={true}
        />
      </Box>
    </div>
  );

};

export default CommercialInvoice;
