import Details from "./components/Details";
import OrderStatus from "./components/OrderStatus";
import Receipt from "./components/Receipt";
import { useFetchDataId } from 'services/queries';
import { useParams } from "react-router-dom";

const OrderDetails = () => {
  const { id } = useParams();
  const { data: order, error: errorOrder, isLoading: isLoadingOrder } = useFetchDataId('order/sales-order', id, true);

  if (isLoadingOrder) return <div>Loading...</div>;
  if (errorOrder) return <div>Error! {errorOrder.message}</div>;


  console.log("order: ", order)
  return (
    <div className="mt-3 grid h-full w-full grid-cols-7 gap-[20px] rounded-[20px]">
      <div className="z-0 col-span-7 lg:col-span-7">
        <Details order={order} />
      </div>
      <div className="z-0 col-span-7 lg:col-span-7">
        <Receipt order={order} />
      </div>

      {/* <div className="z-0 col-span-7 lg:col-span-2">
        <OrderStatus />
      </div> */}

    </div>
  );
};

export default OrderDetails;
