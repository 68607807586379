
import SolidSubtitleAlert from 'components/alert/SolidSubtitleAlert'
import { BsFillCheckCircleFill } from 'react-icons/bs'

const SuccessAlert = ({ title, description, display, setNotification }) => {

    if (!display) return null;

    return (
        <SolidSubtitleAlert
            title={title}
            description={description}
            icon={<BsFillCheckCircleFill />}
            iconColor="text-white dark:!text-navy-900"
            closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
            bg="bg-green-500 dark:!bg-green-300"
            mb="mb-6"
            solid="solid"
            setNotification={setNotification}
        />
    )
}

export default SuccessAlert