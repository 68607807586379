import { useState } from "react";
import React from 'react';
import { useFetchData } from "services/queries";
import Box from '@mui/material/Box';
import {
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Tooltip from '@mui/material/Tooltip';
import { GRID_AGGREGATION_ROOT_FOOTER_ROW_ID } from '@mui/x-data-grid-premium';
import { CATEGORY_CHOICES } from "constants";

const USDcurrencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
});

const AdditionalCost = ({ formData }) => {
    const resourcePath = 'order/additional-cost';
    const relatedResources = `order/additional-cost/?sales_order=${formData.id}`;
    const { data: suppliers, isLoading: isLoadingSuppliers, error: errorSuppliers } = useFetchData('supplier', true);
    const [tableData, setTableData] = useState([]);
    const apiRef = useGridApiRef();

    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleOpenModal = (imageUrl) => {
        // if is pdf, open in new tab
        if (imageUrl.includes('.pdf')) {
            // open new tab with the pdf
            window.open(imageUrl, '_blank');
            return;
        }

        setSelectedImage(imageUrl);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage(null);
    };

    const handleImageUpload = (event, params) => {
        console.log("params: ", params)
        const file = event.target.files[0];
        const updatedRows = tableData.map(row => {
            if (row.id === params.id) {
                return { ...row, file: file };
            }
            return row;
        });
        setTableData(() => updatedRows);
        apiRef.current.setEditCellValue({ id: params.id, field: 'file', value: file });
        console.log("updatedRows: ", updatedRows)
    };

    const actions = {
        create: true,
        edit: true,
        delete: true
    };
    const newRowTemplate = {
        sales_order: formData.id,
        amount: 0,
        description: '',
        date: null,
        category: 'OT',
        supplier: null,
        isNew: true,
    };

    console.log(
        "CATEGORY_CHOICES", CATEGORY_CHOICES
    )
    const columns = [
        {
            field: 'file',
            headerName: 'File',
            editable: true,
            groupable: false,
            aggregable: false,
            valueGetter: (value) => {
                return value;
            },
            renderCell: (params) => {
                const imageUrl = params.row.file_url;
                if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
                    return [];
                }
                return (
                    <div>
                        {imageUrl ? (
                            <IconButton m={0} p={0} color="primary" onClick={() => handleOpenModal(imageUrl)}>
                                <VisibilityIcon />
                            </IconButton>
                        ) : <Tooltip title={"Double click to edit"} >
                            <IconButton>
                                <VisibilityOffIcon />
                            </IconButton>
                        </Tooltip>}
                    </div>
                );
            },
            renderEditCell: (params) => {
                return (
                    <div>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            style={{ textTransform: 'none' }}
                        >
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                hidden
                                onChange={(event) => handleImageUpload(event, params)}
                            />
                        </Button>
                    </div>
                );
            },
        },
        // category
        {
            field: 'category',
            headerName: 'Category',
            editable: true,
            type: 'singleSelect',
            minWidth: 150,
            valueOptions: CATEGORY_CHOICES,
        },
        // suppliers
        {
            field: 'supplier',
            headerName: 'Supplier',
            editable: true,
            type: 'singleSelect',
            minWidth: 200,
            valueOptions: suppliers?.map((supplier) => ({ value: supplier.id, label: supplier.name })),
        },
        // date
        {
            field: 'date',
            headerName: 'Date',
            editable: true,
            groupable: false,
            aggregable: false,
            width: 115,
            type: 'date',
            valueGetter: (value, row) => value ? new Date(value + "T12:00:00") : null,  // Converts string to Date object for editing
            valueSetter: (value, row) => {
                const oldValue = row.date;
                if (value instanceof Date) {
                    return { ...row, date: value.toISOString().split('T')[0] };  // Converts Date object back to string in 'YYYY-MM-DD' format
                }
                return { ...row, date: oldValue };  // Keeps the old value if the new value isn't a Date instance
            },
            valueFormatter: (value) => {
                if (value == null) {
                    return '';
                }
                //   format the date to 'YYYY-MM-DD'
                return value.toISOString().split('T')[0];
            }
        },
        {
            field: 'amount',
            headerName: 'Amount ($)',
            editable: true,
            type: 'number',
            minWidth: 100,
            valueFormatter: (value) => USDcurrencyFormatter.format(value),
            valueGetter: (value) => (value ? parseFloat(value) : 0),
        },
        {
            field: 'description',
            headerName: 'Description',
            editable: true,
            type: 'string',
            flex: 1,
            minWidth: 200,
        }
    ];

    if (isLoadingSuppliers) return 'Loading...';
    if (errorSuppliers) return `Error! ${errorSuppliers.message}`;


    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">Additional Costs</h3>
            <Box>
                <EditableDataGrid
                    columns={columns}
                    resourcePath={resourcePath}
                    tableData={tableData}
                    setTableData={setTableData}
                    relatedResources={relatedResources}
                    apiRef={apiRef}

                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        pinnedColumns: { left: ['actions'] },
                        aggregation: {
                            model: {
                                amount: 'sum',
                            },
                        },
                    }}
                    autosizeOptions={{
                        columns: ['description', 'amount'],
                        includeOutliers: true,
                        includeHeaders: true,
                    }}
                    actions={actions}
                    autosizeOnMount={true}
                    newRowTemplate={newRowTemplate}
                />
            </Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: 16,
                    borderRadius: 8,
                    outline: 'none',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                    maxWidth: '90%',
                    maxHeight: '90%',
                    overflow: 'auto',
                    width: 'auto', // Explicitly set width or maxWidth
                    height: 'auto', // Explicitly set height or maxHeight
                }}>
                    <IconButton
                        style={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={handleCloseModal}
                    >
                        <CloseIcon />
                    </IconButton>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Uploaded"
                            style={{ maxWidth: '100%', maxHeight: 'auto', objectFit: 'contain' }}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );


}

export default AdditionalCost