import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import Resource from "./components/steps/Resource";
import Address from "./components/steps/Address";
import BankDetails from "./components/steps/BankDetails";
import Card from "components/card";
import getInputChange from "utils/handleInputChange";
import { useFetchDataId, useCreateData, useUpdateData } from "services/queries";


const Form = () => {
  const resource_path = 'shipper';
  const resource_name = 'Shipper';
  const { id } = useParams();
  const isEditMode = Boolean(id);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({ address: {} });
  const navigate = useNavigate();

  const createShipper = useCreateData(resource_path);
  const updateShipper = useUpdateData(resource_path);

  console.log("createShipper: ")

  const { data: shipper, error: errorShipper, isLoading: isLoadingShipper } = useFetchDataId('shipper', id, true);

  useEffect(() => {
    if (shipper) {
      setFormData(() => shipper);
    }
  }, [shipper]);

  const handleInputChange = (e) => {
    getInputChange(e, formData, setFormData)
  };


  const steps = [
    { stepNo: 1, name: `${resource_name} Info` },
    { stepNo: 2, name: `Address Info` },
    { stepNo: 3, name: `Bank Details` },
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Resource
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      case 2:
        return <Address
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      case 3:
        return <BankDetails
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    isEditMode ? updateShipper.mutate({ id, newData: formData }) : createShipper.mutate(formData);
  };

  useEffect(() => {
    if (isEditMode) {
      if (updateShipper.isSuccess) {
        navigate("/admin/shipper/list");
      }
    }
    else {
      if (createShipper.isSuccess) {
        navigate("/admin/shipper/list");
      }
    }
  }, [updateShipper.isSuccess, createShipper.isSuccess]);

  if (isLoadingShipper) return <div>Loading...</div>;
  if (errorShipper) return <div>Error! {errorShipper.message}</div>;

  console.log("shipper: ", shipper)

  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">

        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <form onSubmit={handleSubmit}>

            <div className="rounded-[20px]">

              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </div>
            {/* navigation button */}
            <StepperControl
              handleClick={handleClick}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              steps={steps}
            />
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Form;
