import Barcode from "assets/img/ecommerce/Code-128.png";
const Details = ({ order }) => {
  const buyer = order.buyer;
  return (
    <div className="flex h-full w-full flex-col items-center justify-between rounded-[20px] bg-white bg-clip-border px-[35px] pt-8 pb-11 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-row">
      {/* left side */}
      <div className="flex w-full flex-col items-center justify-between gap-16 md:flex-row">
        <div>
          <p className="text-base font-medium leading-6 text-gray-600">
            Customer Details:
          </p>
          <h3 className="text-xl font-bold leading-7 text-navy-700 dark:text-white">
            {buyer.social_reason}
          </h3>

          <p className="text-base font-medium text-gray-600">{buyer.phone}</p>
          <p className="font-medium text-navy-700">
            <a
              className="text-brand-500 underline hover:text-brand-500 dark:text-white"
              href=" "
            >
              {buyer.email}
            </a>
          </p>
          <p className="text-base font-medium leading-6 text-gray-600">
            {buyer.cnpj}
          </p>
        </div>
        <div className="hidden md:block" >
          <br />
          <p className="text-base font-medium leading-6 text-gray-600">
            {buyer.address.street}
          </p>
          <p className="text-base font-medium text-gray-600">{buyer.address.city} - {buyer.address.province} / {buyer.address.country}</p>
          <p className="text-base font-medium text-gray-600">
            {buyer.address.postal_code}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Details;
