import React from "react";
import { AiOutlineClose } from "react-icons/ai";

function SolidSubtleAlert(props) {
    const {
        icon,
        title,
        description,
        bg,
        justify,
        mb,
        closeBg,
        solid,
        iconColor,
        setNotification
    } = props;
    return (
        <div
            className={`flex justify-between ${mb} h-fit w-[90%] md:w-fit items-center py-4 pl-[17px] pr-[6px] ${bg} rounded-lg z-[100] fixed bottom-0 translate-x-4`}
            style={{ left: "50%", transform: "translateX(-50%) translateY(-50%)" }}
        >
            <div className={`flex h-full w-full items-center gap-2 ${justify} `}>
                <p className={`text-2xl ${iconColor} `}> {icon} </p>
                <h5
                    className={`text-base ${solid
                        ? "text-white dark:!text-navy-900"
                        : "text-navy-700 dark:!text-white"
                        } `}
                >
                    <span className="pr-[5px] font-bold"> {title} </span> {description}
                </h5>
            </div>
            <div
                className={`flex rounded-md ${closeBg} h-full w-10 cursor-pointer items-center justify-center text-xl font-bold `}
            >
                <AiOutlineClose
                    onClick={() => setNotification({ display: false })}
                />
            </div>
        </div>
    );
}

export default SolidSubtleAlert;
