import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PinInput from 'react-pin-input';
import { API_BASE_URL } from 'config';
import toast from 'react-hot-toast';

function NewPasswordDefault() {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [pin, setPin] = useState('');
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState('');

  const navigate = useNavigate(); //local storage

  //

  const location = useLocation();

  // retrieve email from local storage
  useEffect(() => {
    const email = localStorage.getItem('email');
    localStorage.setItem('email', ''); //clear local storage memory

    setEmail(email);
  }, []);

  // Extract the "email" parameter from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromURL = queryParams.get('email');
    if (emailFromURL) {
      setEmail(emailFromURL);
    }
  }, [location.search]);

  //Function to validate fields
  useEffect(() => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email format.';
    }

    if (pin.length !== 6) {
      newErrors.pin = 'PIN must be 6 digits.';
    }

    if (pwd.length < 8) {
      newErrors.pwd = 'Password must be at least 8 characters.';
    } else {
      const strength = checkPasswordStrength(pwd);
      setPasswordStrength(strength);
      if (strength === 'weak') {
        newErrors.pwd =
          'Password is too weak. Consider adding uppercase letters, numbers, and special characters.';
      }
    }

    if (confirmPwd !== pwd) {
      newErrors.confirmPwd = 'Passwords do not match.';
    }

    setErrors(newErrors);
    setIsButtonDisabled(Object.keys(newErrors).length > 0);
  }, [email, pin, pwd, confirmPwd]);

  // Function to check password strength
  const checkPasswordStrength = (password) => {
    let strength = 'weak';
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length >= 8) {
      if (hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars) {
        strength = 'strong';
      } else if (
        (hasUpperCase || hasLowerCase) &&
        hasNumbers &&
        (hasSpecialChars || hasUpperCase || hasLowerCase)
      ) {
        strength = 'medium';
      }
    }
    return strength;
  };
  // Function to handle clicking the reset password button
  const handlerClick = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/password/change_pwd/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code: pin, new_password: pwd }),
      });
      if (response.status === 200) {
        toast.success('Password reset successfully!');
        navigate('/auth/sign-in');
      } else {
        const data = await response.json();
        if (data.error === 'invalid_code') {
          toast.error('The code is incorrect.');
        } else if (data.error === 'weak_password') {
          toast.error('The password is too weak.');
        } else {
          toast.error('An error has occurred. Please try again.');
        }
      }
    } catch (error) {
      toast.error('Error connecting to server. Please try again.');
    }
  };

  return (
    <Default
      maincard={
        <div className="mb-12 flex h-full w-[85%] items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
          {/* Sign in section */}
          <div className="lg:-w-[85%] lg:w-[85%]-pr-0 xl:-w-[85%] mt-[5vh] flex w-full flex-col place-content-center gap-4 rounded-[20px] pb-0 pr-6 pt-12 md:max-w-[85%] xl:pr-0">
            <h2 className="mb- text-4xl font-bold text-navy-700 dark:text-white">
              Reset Password
            </h2>

            {/* Email */}
            <InputField
              variant="auth"
              label="Email"
              placeholder="mail@simmmple.com"
              id="email"
              type="text"
              value={email}
              disabled={true}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500 ">{errors.email}</p>
            )}

            {/* Validation code */}
            <h2 className="mb- text-xl text-navy-700 dark:text-white">
              Validation code email
            </h2>
            <PinInput
              length={6}
              initialValue=""
              onChange={(value) => setPin(value)}
              type="numeric"
              inputMode="number"
              autoComplete="off"
              name="unique-pin"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
              inputStyle={{
                borderWidth: '2px',
                width: '60px',
                height: '60px',
                borderColor: '#E0E5F2',
                borderRadius: '16px',
                color: '#2B3674',
                fontSize: '36px',
              }}
              inputFocusStyle={{ borderColor: '#4318FF', borderWidth: '3px' }}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
              onComplete={(value) => setPin(value)}
            />
            {errors.pin && (
              <p className="mt-1 text-sm text-red-500">{errors.pin}</p>
            )}

            {/* New password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="New password"
              placeholder="Min 8 characters"
              id="password"
              type="password"
              value={pwd}
              autoComplete="off"
              name="unique-password"
              onChange={(e) => setPwd(e.target.value)}
              className="w-150px"
            />
            {errors.pwd && (
              <p className="mt-1 text-sm text-red-500">{errors.pwd}</p>
            )}
            {pwd && (
              <p
                className={`mt-1 text-sm ${
                  passwordStrength === 'weak'
                    ? 'text-red-500'
                    : passwordStrength === 'medium'
                    ? 'text-yellow-500'
                    : 'text-green-500'
                }`}
              >
                Password strength: {passwordStrength}
              </p>
            )}
            {/* Confirmar nova senha */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Confirm new password"
              placeholder="Min 8 characters"
              id="confirm-password"
              type="password"
              value={confirmPwd}
              autoComplete="off"
              name="unique-confirm-password"
              onChange={(e) => setConfirmPwd(e.target.value)}
            />
            {errors.confirmPwd && (
              <p className="mt-1 text-sm text-red-500">{errors.confirmPwd}</p>
            )}
            {/* Botão */}
            <button
              className={`mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                isButtonDisabled ? 'cursor-not-allowed opacity-50' : ''
              }`}
              disabled={isButtonDisabled}
              onClick={handlerClick} //local storage
            >
              Reset password
            </button>
          </div>
        </div>
      }
    />
  );
}

export default NewPasswordDefault;