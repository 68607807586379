import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import ErrorAlert from "components/alert/ErrorAlert";
import SuccessAlert from "components/alert/SuccessAlert";


const NotificationContext = createContext();

const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    const [notification, setNotification] = context;
    return { notification, setNotification };
}

const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState({ title: 'Title', description: 'Description', type: '', display: false });

    useEffect(() => {
        let timer;
        if (notification.display && notification.type === 'success') {
            timer = setTimeout(() => {
                setNotification(prevNotification => ({
                    ...prevNotification,
                    display: false
                }));
            }, 4000);
        }
        if (notification.display && notification.type === 'error') {
            timer = setTimeout(() => {
                setNotification(prevNotification => ({
                    ...prevNotification,
                    display: false
                }));
            }, 10000);
        }
        return () => clearTimeout(timer);
    }, [notification]);

    return (
        <NotificationContext.Provider value={[notification, setNotification]}>
            {children}
            {notification.display && notification.type === 'error' && <ErrorAlert {...notification} setNotification={setNotification} />}
            {notification.display && notification.type === 'success' && <SuccessAlert {...notification} setNotification={setNotification} />}
        </NotificationContext.Provider>
    );
}
export { NotificationProvider, useNotification };
