import { useDropzone } from "react-dropzone";
import React, { useState } from "react";

const DropZonefile = ({ content, formData, handleImageUpload }) => {
    const [file, setFile] = useState(null);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setFile(acceptedFiles[0]);
            handleImageUpload(acceptedFiles[0]);
        },
    });

    return (
        <div
            className="flex h-full !w-[700px] cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700"
            {...getRootProps({ className: "dropzone" })}
        >
            <input {...getInputProps()} />
            {formData.picture ? (
                <div className="flex items-center gap-3">
                    <div className="flex h-[90px] w-[90px] items-center justify-center rounded-full cursor-pointer hover:opacity-40">
                        <img
                            src={typeof formData.picture === "string" ? formData.picture : URL.createObjectURL(formData.picture)}
                            alt="Uploaded"
                            name="picture"
                            className="h-full w-full rounded-full"
                        />
                    </div>
                </div>
            ) : (
                <button type="button" className="h-full w-full">{content}</button>
            )}
        </div>
    );
};

export default DropZonefile;
