import { createContext, useState, useEffect, useCallback } from "react";
import jwt_decode from "jwt-decode";
import useAuth from "hooks/useAuth";
import { API_BASE_URL } from 'config';
import toast from 'react-hot-toast';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [loading, setLoading] = useState(true);

    const refreshAccessToken = useCallback(async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await fetch(`${API_BASE_URL}/authentication/api/token/refresh/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "refresh": refreshToken,
            }),
        });
        console.log("ERROR REFRESHING TOKEN")
        // redirect to login if refresh token is invalid
        if (response.status === 401) {

            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            setAuth({});
            toast.error("Your session has expired. Please log in again.");
            return null;
        }

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        if (data.access) {
            const accessToken = data.access;
            localStorage.setItem('accessToken', accessToken);

            const decodedToken = jwt_decode(accessToken);

            setAuth(() => ({ ...decodedToken, accessToken, refreshToken }));

            console.log("Access token refreshed")
            return accessToken;
        } else {
            throw new Error('Failed to refresh token');
        }
    }, []);


    const apiRequest = useCallback(async (url, options = {}) => {
        const response = await fetch(url, {
            ...options,
            headers: {
                'Authorization': `Bearer ${auth.accessToken}`,
                ...options.headers,
            },
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            if (newAccessToken) {
                const retryResponse = await fetch(url, {
                    ...options,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${newAccessToken}`,
                        ...options.headers,
                    },
                });
                if (!retryResponse.status === 401) throw new Error(`HTTP error! status: ${retryResponse.status}`);
                return await retryResponse.json().then(data => ({ status_code: retryResponse.status, ...data }));
            } else {
                throw new Error('Unable to refresh token');
            }
        }
        return await response.json().then(data => ({ status_code: response.status, ...data }));
    }, [auth, refreshAccessToken]);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (accessToken && refreshToken) {
            const decodedToken = jwt_decode(accessToken);

            setAuth(() => ({ ...decodedToken, accessToken, refreshToken }));
        }
        setLoading(false);

    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, loading, apiRequest, refreshAccessToken }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;