import React, { useMemo, useEffect, useState } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useFetchData } from 'services/queries';
import { CircularProgress, Typography, Button, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { green, grey } from '@mui/material/colors';
import PlaceIcon from '@mui/icons-material/Place';


const MovesGrid = ({ mainVoyageId, containerNumber, voyage }) => {
    const { data: moveStatus, error: errorMoveStatus, isLoading: isLoadingMoveStatus } = useFetchData('delivery/move-status', true);
    const { data: transshipmentPort, error: errorTransshipmentPort, isLoading: isLoadingTransshipmentPort } = useFetchData(`delivery/transshipment-port/?main_voyage=${mainVoyageId}&container=${containerNumber}`, true);
    const { data: status, error: errorStatus, isLoading: isLoadingStatus } = useFetchData(`delivery/status/?voyage=${mainVoyageId}&container=${containerNumber}`, true);
    const { data: ports, error: errorPorts, isLoading: isLoadingPorts } = useFetchData('delivery/port', true);
    const { data: vessels, error: errorVessels, isLoading: isLoadingVessels } = useFetchData('delivery/vessel', true);

    const [moves, setMoves] = useState([]);

    const getMoveStatus = (status) => {
        const moveStatusObj = moveStatus.find(ms => ms.id === status);
        return moveStatusObj ? moveStatusObj.name : 'Unknown';
    };

    useEffect(() => {

        const statuses = status?.map(s => ({
            ...s,
            move_status: getMoveStatus(s.move_status),
        }));

        const newMoves = [];

        const from_port_id = voyage.find(
            (voyage) => voyage.id === mainVoyageId
        )?.from_port;
        const from_port = ports.find((port) => port.id === from_port_id)?.name;

        const to_port_id = voyage.find(
            (voyage) => voyage.id === mainVoyageId
        )?.to_port;
        const to_port = ports.find((port) => port.id === to_port_id)?.name;
        let first_vessel = vessels.find(v => v.id === voyage.find(v => v.id === mainVoyageId)?.vessel)?.name;
        let vessel = vessels.find(v => v.id === voyage.find(v => v.id === mainVoyageId)?.vessel)?.name;

        const loadedStatus = statuses?.find(s => s.move_status === 'Loaded');
        if (loadedStatus) {
            newMoves.push({
                port: from_port,
                move: loadedStatus.is_actual === 'Actual' ? 'Loaded' : 'Load',
                date: loadedStatus.date,
                date_is_actual: loadedStatus.is_actual !== 'Estimate',
                vessel: first_vessel,
                duration_days: 0
            });
        }

        const departedStatus = statuses?.find(s => s.move_status === 'Departed');
        if (departedStatus) {
            newMoves.push({
                port: from_port,
                move: departedStatus.is_actual === 'Actual' ? 'Departed' : 'Depart',
                date: departedStatus.date,
                date_is_actual: departedStatus.is_actual !== 'Estimate',
                vessel: first_vessel
            });
        }

        transshipmentPort?.forEach(tp => {
            // find port in ports and get the name
            const portName = ports.find(p => p.id === tp.port)?.name;
            const vesselName = vessels.find(v => v.id === tp.vessel)?.name;

            newMoves.push({
                port: portName,
                move: tp.arrival_date_is_actual ? 'Arrived' : 'Arrival',
                date: tp.arrival_date,
                date_is_actual: tp.arrival_date_is_actual,
                vessel: vessel
            });

            // updating vessel
            vessel = vessels.find(v => v.id === tp.vessel)?.name;
            newMoves.push({
                port: portName,
                move: tp.departure_date_is_actual ? 'Departed' : 'Depart',
                date: tp.departure_date,
                date_is_actual: tp.departure_date_is_actual,
                vessel: vessel
            });
        });

        const arrivedStatus = statuses?.find(s => s.move_status === 'Arrived');
        if (arrivedStatus) {
            newMoves.push({
                port: to_port,
                move: arrivedStatus.is_actual === 'Actual' ? 'Arrived' : 'Arrival',
                date: arrivedStatus.date,
                date_is_actual: arrivedStatus.is_actual !== 'Estimate',
                vessel: vessel
            });
        }

        const dischargedStatus = statuses?.find(s => s.move_status === 'Discharged');
        if (dischargedStatus) {
            newMoves.push({
                port: to_port,
                move: dischargedStatus.is_actual === 'Actual' ? 'Discharged' : 'Discharge',
                date: dischargedStatus.date,
                date_is_actual: dischargedStatus.is_actual !== 'Estimate',
                vessel: vessel
            });
        }
        // for each move, get the duration_days difference between the current move and the previous move
        for (let i = 1; i < newMoves.length; i++) {
            const prevMove = newMoves[i - 1];
            const currentMove = newMoves[i];
            const prevDate = new Date(prevMove.date);
            const currentDate = new Date(currentMove.date);
            const diff = currentDate - prevDate;
            const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
            newMoves[i].duration_days = diffDays;
        }

        setMoves(() => newMoves);
        console.log('newMoves', newMoves);
    }, [isLoadingMoveStatus, isLoadingTransshipmentPort, isLoadingStatus, isLoadingPorts, isLoadingVessels]);


    const columns = [
        { field: 'port', headerName: 'Port', flex: 1, },
        { field: 'move', headerName: 'Move', flex: 1, minWidth: 100 },
        {
            field: 'duration_days',
            headerName: 'Duration',
            width: 80,
            type: 'number',
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => (
                <>
                    <CheckIcon sx={{ color: params.row.date_is_actual ? green[500] : grey[500], mr: 1 }} />
                    {params.value}
                </>
            ),
        },

        { field: 'vessel', headerName: 'Vessel', flex: 1 }, // Added vessel column
    ];

    const getRowId = (row) => {
        // combination of columns port, move and date
        return `${row.port}-${row.move}-${row.date}`;
    }

    const handleRedirect = () => {
        const url = `https://shipsgo.com/live-map-container-tracking?query=${containerNumber}`;
        window.open(url, '_blank');
      };

    return (
      <div>
        {isLoadingMoveStatus ||
        isLoadingTransshipmentPort ||
        isLoadingStatus ||
        isLoadingPorts ||
        isLoadingVessels ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h6" gutterBottom>
                {containerNumber}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: '16px', padding: '10px' }} // Espaço entre o número do container e o botão
                onClick={handleRedirect}
              >
                <PlaceIcon sx={{ marginRight: '5px' }} />
                Live Position
              </Button>
            </Box>
            <DataGridPremium
              rows={moves}
              columns={columns}
              getRowId={getRowId}
            />
          </>
        )}
      </div>
    );
};

export default MovesGrid;