import React, { useState } from "react";
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import avatar from 'assets/img/avatars/avatar_bewise.png';


const ReceiptTable = (props) => {
  const { columnsData, tableData, order } = props;

  const columns = [
    {
      field: 'name',
      headerName: 'Product',
      groupable: false,
      aggregable: false,
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        const product = params.row.product;
        const name = product?.name || '';
        const picture = product?.picture || avatar;
        const sku = product?.sku || '';

        if (product) {
          return (
            <Grid container alignItems="center" pr={2} style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                xs={2}
                mx={0.5} marginRight={1}
                maxWidth={45}
              >
                {
                  picture ? (
                    <img src={picture} className="w-10 h-10 object-contain drop-shadow-md" />
                  ) : (
                    <div className="w-10 h-10 bg-gray-100"></div>
                  )
                }
              </Grid>
              <Grid flex={1}
                sx={{ lineHeight: 'normal' }} py={0.5}>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {name}
                </Typography>
                <Typography variant="caption">{sku}</Typography>
              </Grid>
            </Grid>

          );
        }
        return '';
      }
    },
    // quantity
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 150,
      editable: false,
      groupable: false,
      type: 'number',
    },
    // unit price -> commercial_invoice_item -> us_per_unit
    {
      field: 'unit_price',
      headerName: 'Unit Price ($)',
      width: 150,
      editable: false,
      aggregable: false,
      groupable: false,
      type: 'number',
      valueGetter: (value, row) => {
        const us_per_unit = row?.commercial_invoice_item?.us_per_unit || null;
        return us_per_unit;
      },
      renderCell: (params) => {
        const value = params.value;
        return (
          <div>
            {value?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        );
      }
    },
    {
      field: 'total_amount',
      headerName: 'Total Amount ($)',
      width: 150,
      editable: false,
      type: 'number',
      valueGetter: (value, row) => {
        const total_amount = row?.commercial_invoice_item?.total_amount || 0;
        return parseFloat(total_amount);
      },
      renderCell: (params) => {
        const value = params.value;
        return (
          <div>
            {value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </div>
        );
      }
    },
    // nw total
    {
      field: 'nw_total',
      headerName: 'NW Total',
      width: 120,
      editable: false,
      type: 'number',
      valueGetter: (value, row) => {
        const total_net_weight = row?.packing_list_item?.total_net_weight || 0;
        return parseFloat(total_net_weight);
      },
    },
    // gw total
    {
      field: 'gw_total',
      headerName: 'GW Total',
      width: 120,
      editable: false,
      type: 'number',
      valueGetter: (value, row) => {
        const total_gross_weight = row?.packing_list_item?.total_gross_weight || 0;
        return parseFloat(total_gross_weight);
      },
    },
    // cbm total
    {
      field: 'cbm_total',
      headerName: 'CBM Total',
      width: 120,
      editable: false,
      type: 'number',
      valueGetter: (value, row) => {
        const cbm_total = row?.packing_list_item?.cmb_per_total || 0;
        return parseFloat(cbm_total);
      },
    },
  ];

  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box
        sx={{
          // height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
          '& .MuiDataGrid-root': {
            border: 'none',
          },

        }}
      >
        <DataGridPremium
          rows={order.product_item_sales_order}
          columns={columns.map(column => ({
            ...column,
            editable: false
          }))}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
          initialState={{
            density: 'comfortable',
            aggregation: {
              model: {
                total_amount: 'sum',
                nw_total: 'sum',
                gw_total: 'sum',
                cbm_total: 'sum',
                quantity: 'sum',
              },
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default ReceiptTable;
