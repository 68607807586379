import useAuth from "hooks/useAuth";

const useRoleBasedColumns = (columns) => {
    const { auth } = useAuth();

    const filterColumns = (columns) => {
        const userRoles = auth?.groups || [];

        return columns.filter(column => {
            // If 'visibleTo' is not specified, the column is visible to everyone
            if (!column.visibleTo) {
                return true;
            }

            // Check if the user has at least one of the roles required to see the column
            return column.visibleTo.some(role => userRoles.includes(role));
        });
    };

    return filterColumns(columns);
};

export default useRoleBasedColumns;
