import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const COLORS = ['#0088FE', '#00784f', '#1463d7', '#FFBB28', '#00a67a', '#2fa6ff', '#FF8042', '#1943b8'];

const CustomTooltip = ({ active, payload }) => {
    // box with rounded corners and shadow
    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '4px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '200px',
                }}
            >
                <Typography variant="body2" color="textPrimary">
                    {payload[0].payload.name}
                </Typography>
                <Typography variant="body1" color="textPrimary" style={{ fontWeight: 'bold' }}>
                    {payload[0].value}
                </Typography>
            </Box>
        );
    }

    return null;
};

const TemplatePieChart = ({ data, title, CustomTooltipComponent }) => {
    console.log("data", data)
    return (
        <>
            <Typography variant="h5" gutterBottom align="center">
                {title}
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        innerRadius={10}
                        outerRadius={100}
                        paddingAngle={5}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={CustomTooltipComponent ? CustomTooltipComponent() : CustomTooltip} />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
};

export default TemplatePieChart;