const ProductInfo = ({ data, resource }) => {
  return (
    <div className="h-full w-full rounded-xl bg-white px-2 py-3 dark:!bg-navy-800 xl:!pl-10 xl:!pr-4">

      <h1 className="pt-[10px] text-[1.4rem] font-semibold text-navy-700 dark:text-white md:text-[1.5rem]">
        {data.name}
      </h1>

      <p className=" text-[16px] text-navy-700  dark:text-white preserve-whitespace">
        <span>{data.category.name_path} <i className="text-[14px]">({data.category.value_path})</i></span>
      </p>
      <p className="mt-[18px] text-[15px] text-navy-700  dark:text-white preserve-whitespace">
        {data.short_description}
      </p>
    </div >
  );
};

export default ProductInfo;
