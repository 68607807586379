// src/utils/handleError.js
import React from 'react';

const getErrorMessage = (response) => {

    if (!response) {
        return 'Something went wrong';
    }

    if (response.code === 'server_error') {
        return getServerError(response);
    }

    if (response.errorMessage) {
        return response.errorMessage;
    }

    if (response.error) {
        return getError(response.error);
    }
    console.log(response)

    if (typeof response === 'object') {
        return getObjectError(response);
    }

    if (typeof response === 'string') {
        response = JSON.parse(response);
        if (response.error) {
            return getError(response.error);
        }
    }

    return 'Something went wrong';
};

const getServerError = (response) => {
    if (!response.error || !response.error.message) {
        return 'Something went wrong';
    }

    const errorMessages = Array.isArray(response.error.message)
        ? response.error.message
        : [response.error.message];

    return (
        <ul>
            {errorMessages.map((errorMessage, index) => (
                <li key={index}>{errorMessage}</li>
            ))}
        </ul>
    );
};

const getError = (error) => {
    if (typeof error === 'string') {
        return error;
    }

    if (error.message) {
        return error.message;
    }

    return 'Something went wrong';
};

const getObjectError = (response) => {
    console.log(response)
    const errors = Object.entries(response)
        .filter(([key]) => key !== 'status_code')
        .map(([field, fieldErrors]) => {
            if (Array.isArray(fieldErrors)) {
                return fieldErrors.map((fieldErrorMessage) => ({
                    field,
                    message: fieldErrorMessage,
                }));
            }

            if (typeof fieldErrors === 'object') {
                return Object.entries(fieldErrors).map(
                    ([subfield, subfieldErrors]) =>
                        subfieldErrors.map((subfieldErrorMessage) => ({
                            field: `${field}.${subfield}`,
                            message: subfieldErrorMessage,
                        }))
                );
            }

            return [{ field, message: fieldErrors }];
        })
        .flat();
    console.log(errors)

    return (
        <ul>
            {errors.map(({ field, message }, index) => (
                <li key={index}>
                    <b>{field}</b>: {message}
                </li>
            ))}
        </ul>
    );
};

const handleError = (error) => {
    console.error(error);
    error = error.response?.data || error;

    console.log(error)

    let errorMessage = null;
    try {
        errorMessage = getErrorMessage(error);
    } catch (e) {
        errorMessage = 'Something went wrong';
    }

    return errorMessage;
};

export default handleError;
