import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "config";
import { useFetchData } from 'services/queries';
import Autocomplete from '@mui/material/Autocomplete';
import TextFieldMUI from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Toaster, toast } from 'react-hot-toast';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const Product = ({ formData, setFormData, handleInputChange }) => {
  const { data: categories, error: errorCategories, isLoading: isLoadingCategories } = useFetchData('product/category');
  const { data: productTypes, error: errorProductTypes, isLoading: isLoadingProductTypes } = useFetchData('product/product-type');

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (formData.auto_generate_sku === undefined || null) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        auto_generate_sku: true,
      }));
    }
  }, [formData.auto_generate_sku, setFormData]);

  const handleCategoryChange = (event, newValue) => {
    setFormData(() => ({
      ...formData,
      category_id: newValue ? newValue.id : "",
    }));

    if (formData.id && formData.auto_generate_sku === true) {
      setOpen(true);
    }
  };

  const handleAutoSkuChange = (event) => {
    const switch_auto_sku = event.target.checked;

    if (switch_auto_sku === true && formData.auto_generate_sku === false && formData.id) {
      setFormData(() => ({
        ...formData,
        sku: '',
        auto_generate_sku: switch_auto_sku,
      }));
    } else {
      setFormData(() => ({
        ...formData,
        auto_generate_sku: switch_auto_sku,
      }));
    }

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProductTypeChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      product_type: newValue ? newValue.value : null,
    }));
  };

  if (isLoadingCategories || isLoadingProductTypes) return <div>Loading...</div>
  if (errorCategories || errorProductTypes) return <div>Error...</div>

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <Toaster position="bottom-center" reverseOrder={false} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Category Changed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The SKU will change automatically when the category is changed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Product Info
      </h4>
      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-2"
            label="Product Name"
            placeholder="eg. Elegant Chair"
            id="name"
            type="text"
            value={formData.name}
            onChange={handleInputChange}
          />
          <div className="flex items-center justify-between mt-2 w-100%">
            <InputField
              extra="mb-4 w-2/3"
              label="SKU"
              placeholder={formData.auto_generate_sku === true ? "Generate SKU automatically" : "Eg. 50H2001"}
              id="sku"
              type="text"
              value={formData.sku}
              onChange={handleInputChange}
              disabled={formData.auto_generate_sku !== false}
            />

            <FormControlLabel
              control={<Switch value={formData.auto_generate_sku} checked={formData.auto_generate_sku !== false} onChange={handleAutoSkuChange}/>}
              label="Auto SKU"
              labelPlacement="bottom"
            />
          </div>
          <div className="w-full md:col-span-1">
              <label
                htmlFor="category_id"
                className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
              >
                Category
              </label>
              <Autocomplete
                id="category_id"
                options={categories}
                getOptionLabel={(option) => `${option.name_path} (${option.value_path})`}
                value={categories.find(category => category.id === formData.category_id) || null}
                onChange={handleCategoryChange}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params} label="" variant="outlined" placeholder="Eg. Household" InputProps={{
                      ...params.InputProps,
                      sx: { borderRadius: '12px', marginTop: '6px' }
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  const { key, ...rest } = props;
                  return (
                    <li key={option.id} {...rest}>
                      <div>
                        {option.name_path}
                        <br />
                        <span style={{ fontStyle: 'italic', fontSize: '0.8em' }}>
                          Category Value: {option.value_path}
                        </span>
                      </div>
                    </li>
                  );
                }}
            />
          </div>
          <div className="w-full mt-2  md:col-span-1">
            <label
              htmlFor="product_type"
              className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
            >
              Product Type
            </label>
            <Autocomplete
              id="product_type"
              fullWidth
              options={productTypes}
              onChange={handleProductTypeChange}
              value={productTypes.find(productType => productType.value === formData.product_type) || null}
              renderInput={(params) =>
                <TextFieldMUI {...params} label="" variant="outlined" InputProps={{
                  ...params.InputProps,
                  sx: { borderRadius: '12px', marginTop: '6px' }
                }} />}
            />
          </div>
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          {/* text area */}
          <TextField
            label="Description"
            placeholder="Short description about the product!"
            id="short_description"
            name="short_description"
            cols="30"
            rows="16"
            value={formData.short_description}
            onChange={handleInputChange}
          />

        </div>
        <div className="col-span-2">
          {/* text area */}
          <TextField
            label="Additional Information"
            placeholder="Additional information about the product"
            id="additional_information"
            name="additional_information"
            rows="7"
            value={formData.additional_information}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div >
  );
};

export default Product;