const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;  // Assuming you set up your environment variables

async function downloadFile(endpoint, filenameFallback = 'download.pdf') {
    try {
        const response = await fetch(`${endpoint}`);
        if (!response.ok) {
            throw response;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        // Extract filename from Content-Disposition header
        let filename = filenameFallback;
        const contentDisposition = response.headers.get('Content-Disposition');
        console.debug(endpoint)
        if (endpoint.includes('.pdf')) {
            const urlParts = endpoint.split('/');
            filename = urlParts[urlParts.length - 1];
        }
        else if (endpoint.includes('.xlsx')) {
            const urlParts = endpoint.split('/');
            filename = urlParts[urlParts.length - 1];
        }
        else if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="(.+)"/);
            filename = filenameMatch ? filenameMatch[1] : filename;
        } else {
            filename = filenameFallback;
        }
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        return filename;  // Optionally return filename for further use
    } catch (error) {
        console.error("Error downloading the file:", error);
        throw error;  // Re-throwing the error to handle it in the component if needed
    }
}

export { downloadFile };
