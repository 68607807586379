import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>; // or replace with a loading spinner
    }

    if (auth?.groups?.find(role => allowedRoles?.includes(role))) {
        return <Outlet />;
    } else if (auth?.email) {
        return <Navigate to="/admin/order/sales-order/tracking" state={{ from: location }} replace />;
    } else {
        return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
    }
}

export default RequireAuth;