import DynamicFormField from "components/form/DynamicFormField";
import Grid from '@mui/material/Unstable_Grid2';

const Resource = ({ formData, setFormData, handleInputChange }) => {
  const formFields = [
    {
      id: "social_reason",
      label: "Social Reason",
      placeholder: "eg. Boyle and Sons",
      type: "text",
      mdColSpan: 6,
      handleInputChange: handleInputChange,
      value: formData.social_reason
    },
    {
      id: "cnpj",
      label: "CNPJ",
      placeholder: "eg. 71.690.067/0001-43",
      type: "cnpj",
      mdColSpan: 6,
      handleInputChange: handleInputChange,
      value: formData.cnpj
    },
    {
      id: "phone",
      label: "Phone",
      placeholder: "eg. +55 11 99999-9999",
      type: "tel",
      mdColSpan: 6,
      handleInputChange: handleInputChange,
      value: formData.phone
    },
    {
      id: "tax_regime",
      label: "Tax Regime",
      placeholder: "eg. Simples Nacional",
      type: "select",
      mdColSpan: 6,
      options: [
        { value: "Simples Nacional", label: "Simples Nacional" },
        { value: "Lucro Presumido", label: "Lucro Presumido" },
        { value: "Lucro Real", label: "Lucro Real" }
      ],
      handleInputChange: handleInputChange,
      value: formData.tax_regime
    },
    {
      id: "email",
      label: "Email",
      placeholder: "eg. email@email.com",
      type: "email",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.email
    },
    {
      id: "additional_information",
      label: "Additional Information",
      placeholder: "Additional information about the product",
      type: "textarea",
      handleInputChange: handleInputChange,
      value: formData.additional_information,
      mdColSpan: 12,
      cols: 30, // Set an appropriate number of columns for small screens
      mdCols: 1000, // Set an appropriate number of columns for medium screens
    }
  ];

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Client
      </h4>

      {/* content */}
      {/* inputs */}
      <Grid container spacing={2}>
        {formFields.map(field => {
          return (
            <DynamicFormField
              key={field.id}
              field={field}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          );
        })}

      </Grid>
    </div >
  );
};

export default Resource;
