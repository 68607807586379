import React from "react";
import authImg from "assets/img/bewiseapp/logo.png";
import { Link } from "react-router-dom";
import Footer from "components/footer/FooterAuthDefault";
function Default(props) {
  const { maincard } = props;
  return (
    <div className="relative flex">
      <div className="mx-auto flex min-h-full w-[80%] flex-col justify-center  align-middle items-center pt-12 md:max-w-[60%] lg:max-w-[550px]">
        <div className="mb-auto h-full flex flex-col justify-center items-center  align-middle lg:pl-0 px-4">
          <div className="flex w-full pt-14">
            <img src={authImg} alt="auth" />
          </div>
          {maincard}

          {/* <div className="absolute right-0 h-full w-full md:block lg:w-[49vw] 2xl:w-[44vw]">
            <div
              className="absolute flex h-full w-full align-middle items-center justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
            // style={{ backgroundImage: `url(${authImg})` }}
            >
              <img src={authImg} alt="auth" />

            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    </div >
  );
}

export default Default;
