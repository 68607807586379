import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import PinInput from 'react-pin-input';
import { API_BASE_URL } from 'config';
import toast from 'react-hot-toast';
import authImg from "assets/img/bewiseapp/logo.png";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

function FirstPassword() {
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [pin, setPin] = useState('');
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [passwordStrength, setPasswordStrength] = useState('');
    const [isSendCodeButtonDisabled, setIsSendCodeButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    // Function to send code
    const sendCode = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/password/generate_code/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.status === 201) {
                toast.success('Code sent successfully!');
                setIsSendCodeButtonDisabled(true);
                setCountdown(60); // Set countdown to 60 seconds
                const interval = setInterval(() => {
                    setCountdown((prevCountdown) => {
                        if (prevCountdown <= 1) {
                            clearInterval(interval);
                            setIsSendCodeButtonDisabled(false);
                            return 0;
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
            } else {
                toast.error('An error has occurred. Please try later.');
            }
        } catch (error) {
            toast.error('Error connecting to server. Please try again.');
        }
    };

    // Extract the "email" parameter from the URL
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromURL = queryParams.get('email');
        if (emailFromURL) {
            setEmail(emailFromURL);
        }
    }, [location.search]);

    // Function to validate fields
    useEffect(() => {
        const newErrors = {};

        if (!email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Invalid email format.';
        }

        if (pin.length !== 6) {
            newErrors.pin = 'PIN must be 6 digits.';
        }

        if (pwd.length < 8) {
            newErrors.pwd = 'Password must be at least 8 characters.';
        } else {
            const strength = checkPasswordStrength(pwd);
            setPasswordStrength(strength);
            if (strength === 'weak') {
                newErrors.pwd =
                    'Password is too weak. Consider adding uppercase letters, numbers, and special characters.';
            }
        }

        if (confirmPwd !== pwd) {
            newErrors.confirmPwd = 'Passwords do not match.';
        }

        setErrors(newErrors);
        setIsButtonDisabled(Object.keys(newErrors).length > 0);
    }, [email, pin, pwd, confirmPwd]);

    // Function to check password strength
    const checkPasswordStrength = (password) => {
        let strength = 'weak';
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (password.length >= 8) {
            if (hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars) {
                strength = 'strong';
            } else if (
                (hasUpperCase || hasLowerCase) &&
                hasNumbers &&
                (hasSpecialChars || hasUpperCase || hasLowerCase)
            ) {
                strength = 'medium';
            }
        }
        return strength;
    };

    // Function to handle clicking the reset password button
    const handlerClick = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/password/change_pwd/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, code: pin, new_password: pwd }),
            });
            if (response.status === 200) {
                toast.success('Password reset successfully!');
                navigate('/auth/sign-in');
            } else {
                const data = await response.json();
                if (data.error === 'invalid_code') {
                    toast.error('The code is incorrect.');
                } else if (data.error === 'weak_password') {
                    toast.error('The password is too weak.');
                } else {
                    toast.error('An error has occurred. Please try again.');
                }
            }
        } catch (error) {
            toast.error('Error connecting to server. Please try again.');
        }
    };

    return (
        <Container
            sx={{
                mx: 'auto',
                mb: 10,
                width: {
                    xs: '90%', // width for extra-small screens
                    sm: '80%', // width for small screens
                    md: '50%', // width for medium screens
                    lg: '30%', // width for large screens
                    xl: '20%', // width for extra-large screens
                },
            }}
        >
            <Box
                py={4}
                sx={{
                    mx: 'auto',
                }}
            >
                <img src={authImg} alt="auth" />
            </Box>
            <h2 className="mb-5 text-3xl font-bold text-navy-700 dark:text-white">
                Welcome to Bewiseapp!
            </h2>
            <p className="mb-3 text-navy-700 dark:text-white ">
                Here's what you need to do before your first access:
            </p>
            <div className="mb-3 ml-6 text-navy-700 dark:text-white ">
                <ul className="list-decimal space-y-1">
                    <li>
                        <span className="ml-1">
                            Click the "Send Code" button below to receive a new email with a code.
                        </span>
                    </li>
                    <li>
                        <span className="ml-1">
                            Get the code from this new email and enter it in the 'Validation Code' field below.
                        </span>
                    </li>
                    <li>
                        <span className="ml-1">Enter your new password and confirm it.</span>
                    </li>
                    <li>
                        <span className="ml-1">
                            Click on the "Create Password" button
                        </span>
                    </li>
                    <li>
                        <span className="ml-1">
                            After these steps, you will be redirected to the login page where you can log in normally.
                        </span>
                    </li>
                </ul>
            </div>

            {/* add a button that will call the function sendCode */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={sendCode}
                    sx={{
                        mt: 2,
                        fontSize: '1.10rem', // Increase font size
                        width: '90%', // Optional: make the button full width
                    }}
                    endIcon={<AdsClickIcon />} // Add the Send icon
                    disabled={isSendCodeButtonDisabled} // Disable button based on state
                >
                    Send code
                </Button>
            </Box>

            {/* Countdown message */}
            {isSendCodeButtonDisabled && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    sx={{ mt: 1 }}
                >
                    Please wait {countdown} seconds before requesting a new code.
                </Typography>
            )}

            <Divider sx={{ my: 3 }} />

            {/* Email */}
            <InputField
                variant="auth"
                label="Email"
                placeholder="mail@simmmple.com"
                id="email"
                type="text"
                value={email}
                disabled={true}
                onChange={(e) => setEmail(e.target.value)}
                extra="mb-3"
            />
            {errors.email && (
                <p className="mt-1 text-sm text-red-500 ">{errors.email}</p>
            )}

            {/* Validation code */}
            <div className="my-5">
                <h2 className="my-1 text-xl text-navy-700 dark:text-white">
                    Validation code email
                </h2>
                <PinInput
                    extra="mb-3"
                    length={6}
                    initialValue=""
                    onChange={(value) => setPin(value)}
                    type="numeric"
                    inputMode="number"
                    autoComplete="off"
                    name="unique-pin"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                    inputStyle={{
                        borderWidth: '2px',
                        width: '60px',
                        height: '60px',
                        borderColor: '#E0E5F2',
                        borderRadius: '16px',
                        color: '#2B3674',
                        fontSize: '36px',
                    }}
                    inputFocusStyle={{ borderColor: '#4318FF', borderWidth: '3px' }}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                    onComplete={(value) => setPin(value)}
                />
                {errors.pin && (
                    <p className="mt-1 text-sm text-red-500">{errors.pin}</p>
                )}
            </div>

            {/* New password */}
            <InputField
                variant="auth"
                extra="mb-3"
                label="New password"
                placeholder="Min 8 characters"
                id="password"
                type="password"
                value={pwd}
                autoComplete="off"
                name="unique-password"
                onChange={(e) => setPwd(e.target.value)}
                className="w-150px"
            />
            {errors.pwd && (
                <p className="mt-1 text-sm text-red-500">{errors.pwd}</p>
            )}
            {pwd && (
                <p
                    className={`mt-1 text-sm ${passwordStrength === 'weak'
                        ? 'text-red-500'
                        : passwordStrength === 'medium'
                            ? 'text-yellow-500'
                            : 'text-green-500'
                        }`}
                >
                    Password strength: {passwordStrength}
                </p>
            )}
            {/* Confirmar nova senha */}
            <InputField
                variant="auth"
                extra="mb-3"
                label="Confirm new password"
                placeholder="Min 8 characters"
                id="confirm-password"
                type="password"
                value={confirmPwd}
                autoComplete="off"
                name="unique-confirm-password"
                onChange={(e) => setConfirmPwd(e.target.value)}
            />
            {errors.confirmPwd && (
                <p className="mt-1 text-sm text-red-500">{errors.confirmPwd}</p>
            )}
            {/* Botão */}
            <button
                className={`mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${isButtonDisabled ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                disabled={isButtonDisabled}
                onClick={handlerClick}
            >
                Create Password
            </button>
        </Container>
    );
}

export default FirstPassword;