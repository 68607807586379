import React, { useEffect, useState } from "react";
import { useFetchData, useFetchDataId } from 'services/queries';
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
    DataGridPremium,
    GRID_STRING_COL_DEF,
    useGridApiRef,
    gridFilteredSortedRowIdsSelector,
    useKeepGroupedColumnsHidden,
    GridToolbar,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import { LineChart } from '@mui/x-charts/LineChart';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const USDcurrencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
});

const RMBcurrencyFormatter = new Intl.NumberFormat('zh-CN', {
    style: 'currency',
    currency: 'CNY',
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
});

// % pct formater
const pctFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const sparklineColumnType = {
    ...GRID_STRING_COL_DEF,
    type: 'custom',
    resizable: false,
    filterable: false,
    sortable: false,
    editable: false,
    groupable: false,
    aggregable: false,
};

const columnGroupingModel = [
    {
        groupId: 'Margin',
        children: [
            { field: 'margin_profit_us', },
            { field: 'margin_profit_rmb', },
        ]
    },
    {
        groupId: 'Supplier',
        children: [
            { field: 'supplier', },
            { field: 'purchase_usd_per_unit', },
            { field: 'purchase_rmb_per_unit', },
        ],
        headerClassName: 'border-r-2 border-l-2 pl-3 ml-2 pr-3 mr-2 '
    },
    {
        groupId: 'Buyer',
        children: [
            { field: 'buyer', },
            { field: 'sold_us_per_unit', },
            { field: 'sold_rmb_per_unit', },
        ]
    },

];


function WrappedDataGrid(props) {
    const { products, status } = props;
    const apiRef = useGridApiRef();

    const [visibleRows, setVisibleRows] = useState([]);


    const columnsData = [
        {
            field: 'product_id',
            headerName: 'PRODUCT',
            editable: false,
            groupable: true,
            aggregable: false,
            type: 'string',
            width: 300,
            minWidth: 300,
            maxWidth: 400,
            groupingValueGetter: (value) => {
                const product_id = value;
                const product = products.find((item) => item.id === product_id);
                return product?.name;
            },
            renderCell: (params) => {
                const product_id = params.row.product_id;
                const product = products.find((item) => item.id === product_id);
                if (product) {
                    return (
                        <Grid container alignItems="center" pr={2}>
                            <Grid m={1}>
                                <img src={product.picture} alt={product.name} className="w-10 h-10 object-contain rounded-full drop-shadow-md" />
                            </Grid>
                            <Grid sx={{ lineHeight: 'normal' }} py={0.5}>
                                <Typography variant="subtitle2">{product.name}</Typography>
                                <Typography variant="caption">{product.sku}</Typography>
                            </Grid>
                        </Grid>
                    );
                }
                return '';
            }
        },
        {
            field: 'margin_profit_us',
            headerName: '$ Margin (%)',
            type: 'number',
            aggregable: true,
            valueFormatter: (value) => pctFormatter.format(value),
        },
        {
            field: 'margin_profit_rmb',
            headerName: '¥ Margin (%)',
            type: 'number',
            aggregable: true,
            valueFormatter: (value) => pctFormatter.format(value),
        },
        // supplier
        {
            field: 'supplier',
            headerName: 'Supplier',
            editable: false,
            groupable: true,
            aggregable: false,
            minWidth: 150,
            cellClassName: 'border-l-2 pl-3 ml-2',
            headerClassName: 'border-l-2 pl-3 ml-2',

        },
        {
            field: 'purchase_usd_per_unit',
            headerName: 'Purchase ($)',
            type: 'number',
            aggregable: true,
            valueFormatter: (value) => USDcurrencyFormatter.format(value),
        },
        {
            field: 'purchase_rmb_per_unit',
            headerName: 'Purchase (¥)',
            type: 'number',
            aggregable: true,
            valueFormatter: (value) => RMBcurrencyFormatter.format(value),
            cellClassName: 'pr-3 mr-2 border-r-2',
            headerClassName: 'pr-3 mr-2 border-r-2'
        },
        // buyer
        {
            field: 'buyer',
            headerName: 'Buyer',
            editable: false,
            groupable: true,
            aggregable: false,
            minWidth: 200,
            flex: 1,

        },
        {
            field: 'sold_us_per_unit',
            headerName: 'Sold ($)',
            type: 'number',
            aggregable: true,
            valueFormatter: (value) => USDcurrencyFormatter.format(value),
        },
        {
            field: 'sold_rmb_per_unit',
            headerName: 'Sold (¥)',
            type: 'number',
            aggregable: true,
            valueFormatter: (value) => RMBcurrencyFormatter.format(value),
        },
        {
            field: 'order_identifier',
            headerName: 'Order',
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'string',
            cellClassName: 'border-l-2 pl-3 ml-2',
            headerClassName: 'border-l-2 pl-3 ml-2',

        },

        {
            field: 'order_status',
            headerName: 'Order Status',
            type: 'singleSelect',
            editable: false,
            groupable: true,
            aggregable: false,
            minWidth: 150,
            valueOptions: status?.map((status) => ({
                value: status,
                label: status,
            })),
        },
        {
            field: 'sku',
            headerName: 'SKU',
            editable: false,
            groupable: false,
            aggregable: false,
            valueGetter: (value, row, column, apiRef) => {
                const product_id = row.product_id;
                const product = products.find((item) => item.id === product_id);
                return product?.sku;
            }
        },
        {
            field: 'order_updated_at',
            headerName: 'Issue Date',
            type: 'date',
            editable: false,
            groupable: false,
            aggregable: false,
            valueGetter: (value) => value && new Date(value),
        },
    ];

    const columns = useRoleBasedColumns(columnsData);

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            pagination: { paginationModel: { pageSize: 10 } },
            columns: {
                columnVisibilityModel: {
                    sku: false,
                    product_id: false,
                }
            },
            filter: {
                filterModel: {
                    items: [],
                    quickFilterExcludeHiddenColumns: false,
                },
            },
        },
    });


    const updateVisibleRows = (params) => {
        const visibleRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleRows = visibleRowIds.map(id => apiRef.current.getRow(id));
        const filteredVisibleRows = visibleRows.filter(row => row && typeof row.id === 'number');
        const sortedFilteredVisibleRows = filteredVisibleRows.sort((a, b) => new Date(a.order_updated_at) - new Date(b.order_updated_at));

        sortedFilteredVisibleRows.map(item => {
            item.order_updated_at = new Date(item.order_updated_at);
        })
        console.log("sortedFilteredVisibleRows", sortedFilteredVisibleRows)
        setVisibleRows(() => sortedFilteredVisibleRows)
    }

    useEffect(() => {
        let unsubscribe;
        const handleStateChange = () => {
            unsubscribe?.();
            updateVisibleRows();
            unsubscribe?.();
        };
        updateVisibleRows();
        return apiRef.current.subscribeEvent?.(
            'filterModelChange',
            () => (unsubscribe = apiRef.current.subscribeEvent('stateChange', handleStateChange))
        );
    }, [apiRef]);

    return (
        <>
            <LineChart
                xAxis={[
                    {
                        dataKey: 'order_updated_at',
                        scaleType: 'time',
                    },
                ]}
                series={[
                    { dataKey: 'purchase_usd_per_unit', label: 'Purchase (USD)', color: '#007bff', scaleType: 'linear' },
                    { dataKey: 'sold_us_per_unit', label: 'Sold (USD)', color: '#28a745', scaleType: 'linear' },
                    { dataKey: 'profit_us', label: 'Profit (USD)', color: '#dc3545', scaleType: 'linear' },
                    // Add other series here if you want them to be included in the chart
                ]}
                dataset={visibleRows}
                height={200}
                showHighlight={true}
                showTooltip={true}
                slotProps={
                    {
                        legend: {
                            hidden: false,
                            layout: 'vertical',
                            align: 'center',
                            verticalAlign: 'bottom',
                        }
                    }}
            />
            <DataGridPremium
                apiRef={apiRef}
                {...props}
                initialState={initialState}
                columns={columns}
                autosizeOnMount
                autosizeOptions={{
                    columns: ['supplier'],
                    includeOutliers: true,
                    includeHeaders: true,
                }}
                autoHeight
            />
        </>
    );
}


const ProductAnalytics = ({ product_id }) => {
    const resource = 'product';
    const resource_analytics = `product/get_analytics/?id=${product_id}`;
    const { data: products, error: errorData, isLoading: isLoadingData } = useFetchData(resource, true);
    const { data: data_analytics, error: errorDataAnalytics, isLoading: isLoadingDataAnalytics } = useFetchData(resource_analytics, true);
    const { auth, authLoading } = useAuth();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [isDataGridReady, setDataGridReady] = useState(false);

    useEffect(() => {
        if (!isLoadingData && !authLoading && !isLoadingDataAnalytics && !errorData && !errorDataAnalytics) {
            setDataGridReady(true);
        }
    }, [isLoadingData, authLoading, isLoadingDataAnalytics]);

    const [status, setStatus] = useState(null);



    useEffect(() => {
        if (!isLoadingData && !authLoading && !isLoadingDataAnalytics && !errorData && !errorDataAnalytics) {
            const statusList = data_analytics.map(item => item.order_status);
            const uniqueStatus = [...new Set(statusList)];
            setStatus(uniqueStatus);
        }
    }, [isLoadingData, authLoading, isLoadingDataAnalytics]);



    if (isLoadingData | authLoading | isLoadingDataAnalytics) return <div>Loading...</div>;
    if (errorData | errorDataAnalytics) return <div>Error! {errorData.message}</div>;

    if (!data_analytics) return <div>No data found</div>;


    return (
        <Card extra={'w-full h-full bg-white mt-3 p-3'}>
            <Box>
                {isDataGridReady ? (
                    <WrappedDataGrid
                        rows={data_analytics}
                        getRowId={(row) => row.id}
                        pagination
                        pageSizeOptions={[10, 25, 50, 100]}
                        products={products}
                        status={status}
                        autosizeOnMount={true}
                        ignoreDiacritics
                        autoHeight
                        headerFilters
                        slots={{
                            toolbar: GridToolbar,
                            headerFilterMenu: null,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                excelOptions: { disableToolbarButton: true },
                            },
                        }}
                        columnGroupingModel={columnGroupingModel}

                    />
                ) : (
                    <div>Loading...</div>
                )}
            </Box>
            <DeleteConfirmDialog
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                selectedItem={selectedItem}
                resource={resource}
            />
        </Card>

    )
}

export default ProductAnalytics