import React, { useEffect, useState } from "react";
import { useFetchData, useFetchDataId } from 'services/queries';
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
  GRID_STRING_COL_DEF,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector,
  useKeepGroupedColumnsHidden,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import avatar from 'assets/img/avatars/avatar_bewise.png';


const USDcurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

const RMBcurrencyFormatter = new Intl.NumberFormat('zh-CN', {
  style: 'currency',
  currency: 'CNY',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

// % pct formater
const pctFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const sparklineColumnType = {
  ...GRID_STRING_COL_DEF,
  type: 'custom',
  resizable: false,
  filterable: false,
  sortable: false,
  editable: false,
  groupable: false,
  aggregable: false,
};

function WrappedDataGrid(props) {
  const { products, status } = props;
  const apiRef = useGridApiRef();

  const [visibleRows, setVisibleRows] = useState([]);


  const columnsData = [
    {
      field: 'product_id',
      headerName: 'PRODUCT',
      editable: false,
      groupable: true,
      aggregable: false,
      type: 'string',
      width: 300,
      minWidth: 100,
      maxWidth: 400,
      groupingValueGetter: (value) => {
        const product_id = value;
        const product = products.find((item) => item.id === product_id);
        return product?.name;
      },
      valueGetter: (value, row) => {
        const product_id = value;
        const product = products.find((item) => item.id === product_id);
        return product?.name;
      },
      renderCell: (params) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        const product_id = params.row.product_id;
        const product = products.find((item) => item.id === product_id);
        const name = product?.name || '';
        const picture = product?.picture || avatar;
        const sku = product?.sku || '';

        if (product) {
          return (
            <Grid container alignItems="center" pr={2} style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                xs={2}
                mx={0.5} marginRight={1}
                maxWidth={45}
              >
                {
                  picture ? (
                    <img src={picture} className="w-10 h-10 object-contain drop-shadow-md" />
                  ) : (
                    <div className="w-10 h-10 bg-gray-100"></div>
                  )
                }
              </Grid>
              <Grid flex={1}
                sx={{ lineHeight: 'normal' }} py={0.5}>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {name}
                </Typography>
                <Typography variant="caption">{sku}</Typography>
              </Grid>
            </Grid>

          );
        }
        return '';
      }
    },
    // product.hs_code_or_ncm
    {
      field: 'hs_code_or_ncm',
      headerName: 'NCM / HSCODE',
      editable: false,
      groupable: true,
      aggregable: false,
      minWidth: 120,
      type: 'string',
      valueGetter: (value, row) => {
        const product_id = row.product_id;
        const product = products.find((item) => item.id === product_id);
        return product?.hs_code_or_ncm;
      },
      groupingValueGetter: (value, row) => {
        const product_id = row.product_id;
        const product = products.find((item) => item.id === product_id);
        return product?.hs_code_or_ncm;
      }
    },
    {
      field: 'order_identifier',
      headerName: 'Order',
      editable: false,
      groupable: true,
      aggregable: false,
      type: 'string',
      cellClassName: 'border-l-2 pl-3 ml-2',
      headerClassName: 'border-l-2 pl-3 ml-2',
      renderCell: (params) => {
        return (
          <Link
            href={`/admin/order/sales-order/edit/${params.row.order_id}`}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'order_status',
      headerName: 'Order Status',
      type: 'singleSelect',
      editable: false,
      groupable: true,
      aggregable: false,
      minWidth: 150,
      valueOptions: status?.map((status) => ({
        value: status,
        label: status,
      })),
    },
    {
      field: 'order_updated_at',
      headerName: 'Issue Date',
      type: 'date',
      editable: false,
      groupable: false,
      aggregable: false,
      valueGetter: (value) => value && new Date(value),
    },
    // year order_updated_at
    {
      field: 'year_order_updated_at',
      headerName: 'Year',
      editable: false,
      groupable: true,
      aggregable: false,
      valueGetter: (value, row) => row?.order_updated_at && new Date(row.order_updated_at).getFullYear(),
      groupingValueGetter: (value, row) => row?.order_updated_at && new Date(row.order_updated_at).getFullYear(),

    },
    // month order_updated_at
    {
      field: 'month_order_updated_at',
      headerName: 'Month',
      editable: false,
      groupable: true,
      aggregable: false,
      valueGetter: (value, row) => row?.order_updated_at && new Date(row.order_updated_at).toLocaleString('default', { month: 'short' }),
      groupingValueGetter: (value, row) => row?.order_updated_at && new Date(row.order_updated_at).toLocaleString('default', { month: 'short' }),
    },
    // quantity
    {
      field: 'quantity',
      headerName: 'Quantity',
      type: 'number',
      editable: false,
      groupable: false,
      aggregable: true,
      cellClassName: 'border-l-2 pl-3 ml-2',
      headerClassName: 'border-l-2 pl-3 ml-2',
    },
    // total_profit_us
    {
      field: 'total_profit_us',
      headerName: 'Total Profit ($)',
      type: 'number',
      minWidth: 120,
      aggregable: true,
      valueFormatter: (value) => USDcurrencyFormatter.format(value),
    },
    // total_profit_rmb
    {
      field: 'total_profit_rmb',
      headerName: 'Total Profit (¥)',
      type: 'number',
      minWidth: 120,
      aggregable: true,
      valueFormatter: (value) => RMBcurrencyFormatter.format(value),
    },
    // total_purchase_us
    {
      field: 'total_purchase_us',
      headerName: 'Total Purchase ($)',
      type: 'number',
      minWidth: 150,
      aggregable: true,
      valueFormatter: (value) => USDcurrencyFormatter.format(value),
    },
    // total_purchase_rmb
    {
      field: 'total_purchase_rmb',
      headerName: 'Total Purchase (¥)',
      type: 'number',
      minWidth: 120,
      aggregable: true,
      valueFormatter: (value) => RMBcurrencyFormatter.format(value),
    },
    // total_sold_us
    {
      field: 'total_sold_us',
      headerName: 'Total Sold ($)',
      type: 'number',
      minWidth: 120,
      aggregable: true,
      valueFormatter: (value) => USDcurrencyFormatter.format(value),
    },
    // total_sold_rmb
    {
      field: 'total_sold_rmb',
      headerName: 'Total Sold (¥)',
      type: 'number',
      minWidth: 120,
      aggregable: true,
      valueFormatter: (value) => RMBcurrencyFormatter.format(value),
    },
    // supplier
    {
      field: 'supplier',
      headerName: 'Supplier',
      editable: false,
      groupable: true,
      aggregable: false,
      minWidth: 150,
      cellClassName: 'border-l-2 pl-3 ml-2',
      headerClassName: 'border-l-2 pl-3 ml-2',

    },
    {
      field: 'purchase_usd_per_unit',
      headerName: 'Purchase ($)',
      type: 'number',
      aggregable: true,
      valueFormatter: (value) => USDcurrencyFormatter.format(value),
    },
    {
      field: 'purchase_rmb_per_unit',
      headerName: 'Purchase (¥)',
      type: 'number',
      aggregable: true,
      valueFormatter: (value) => RMBcurrencyFormatter.format(value),
      cellClassName: 'pr-3 mr-2 border-r-2',
      headerClassName: 'pr-3 mr-2 border-r-2'
    },
    // buyer
    {
      field: 'buyer',
      headerName: 'Buyer',
      editable: false,
      groupable: true,
      aggregable: false,
      minWidth: 200,
      flex: 1,

    },
    {
      field: 'sold_us_per_unit',
      headerName: 'Sold ($)',
      type: 'number',
      aggregable: true,
      valueFormatter: (value) => USDcurrencyFormatter.format(value),
    },
    {
      field: 'sold_rmb_per_unit',
      headerName: 'Sold (¥)',
      type: 'number',
      aggregable: true,
      valueFormatter: (value) => RMBcurrencyFormatter.format(value),
    },
    {
      field: 'margin_profit_us',
      headerName: '$ Margin (%)',
      type: 'number',
      aggregable: true,
      valueFormatter: (value) => pctFormatter.format(value),
      cellClassName: 'border-l-2 pl-3 ml-2',
      headerClassName: 'border-l-2 pl-3 ml-2',
    },
    {
      field: 'margin_profit_rmb',
      headerName: '¥ Margin (%)',
      type: 'number',
      aggregable: true,
      valueFormatter: (value) => pctFormatter.format(value),
    },
    {
      field: 'sku',
      headerName: 'SKU',
      editable: false,
      groupable: false,
      aggregable: false,
      valueGetter: (value, row, column, apiRef) => {
        const product_id = row.product_id;
        const product = products.find((item) => item.id === product_id);
        return product?.sku;
      }
    },

  ];

  const columns = useRoleBasedColumns(columnsData);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pagination: { paginationModel: { pageSize: 10 } },
      columns: {
        columnVisibilityModel: {
          sku: false,
          margin_profit_us: false,
          margin_profit_rmb: false,
          total_profit_rmb: false,
          total_purchase_rmb: false,
          total_sold_rmb: false,
        }
      },
      filter: {
        filterModel: {
          items: [],
          quickFilterExcludeHiddenColumns: false,
        },
      },
      aggregation: {
        model: {
          total_profit_us: 'sum',
          total_profit_rmb: 'sum',
          total_purchase_us: 'sum',
          total_purchase_rmb: 'sum',
          total_sold_us: 'sum',
          total_sold_rmb: 'sum',
          purchase_usd_per_unit: 'avg',
          purchase_rmb_per_unit: 'avg',
          sold_us_per_unit: 'avg',
          sold_rmb_per_unit: 'avg',
          margin_profit_us: 'avg',
          margin_profit_rmb: 'avg',
          quantity: 'sum',
        },
      },
    },
  });


  const updateVisibleRows = (params) => {
    const visibleRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleRows = visibleRowIds.map(id => apiRef.current.getRow(id));
    const filteredVisibleRows = visibleRows.filter(row => row && typeof row.id === 'number');
    const sortedFilteredVisibleRows = filteredVisibleRows.sort((a, b) => new Date(a.order_updated_at) - new Date(b.order_updated_at));

    sortedFilteredVisibleRows.map(item => {
      item.order_updated_at = new Date(item.order_updated_at);
    })
    setVisibleRows(() => sortedFilteredVisibleRows)
  }

  useEffect(() => {
    let unsubscribe;
    const handleStateChange = () => {
      unsubscribe?.();
      updateVisibleRows();
      unsubscribe?.();
    };
    updateVisibleRows();
    return apiRef.current.subscribeEvent?.(
      'filterModelChange',
      () => (unsubscribe = apiRef.current.subscribeEvent('stateChange', handleStateChange))
    );
  }, [apiRef]);

  return (
    <>
      <DataGridPremium
        apiRef={apiRef}
        {...props}
        initialState={initialState}
        columns={columns}
        autosizeOnMount
        autosizeOptions={{
          columns: ['supplier'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        autoHeight
        rowGroupingColumnMode="multiple"
      />
    </>
  );
}


const ProductsAnalytics = ({ product_id }) => {
  const resource = 'product';
  const resource_analytics = `product/get_analytics`;
  const { data: products, error: errorData, isLoading: isLoadingData } = useFetchData(resource, true);
  const { data: data_analytics, error: errorDataAnalytics, isLoading: isLoadingDataAnalytics } = useFetchData(resource_analytics, true);
  const { auth, authLoading } = useAuth();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [isDataGridReady, setDataGridReady] = useState(false);

  useEffect(() => {
    if (!isLoadingData && !authLoading && !isLoadingDataAnalytics && !errorData && !errorDataAnalytics) {
      setDataGridReady(true);
    }
  }, [isLoadingData, authLoading, isLoadingDataAnalytics]);

  const [status, setStatus] = useState(null);



  useEffect(() => {
    if (!isLoadingData && !authLoading && !isLoadingDataAnalytics && !errorData && !errorDataAnalytics) {
      const statusList = data_analytics.map(item => item.order_status);
      const uniqueStatus = [...new Set(statusList)];
      setStatus(uniqueStatus);
    }
  }, [isLoadingData, authLoading, isLoadingDataAnalytics]);



  if (isLoadingData | authLoading | isLoadingDataAnalytics) return <div>Loading...</div>;
  if (errorData | errorDataAnalytics) return <div>Error! {errorData.message}</div>;

  if (!data_analytics) return <div>No data found</div>;


  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box>
        {isDataGridReady ? (
          <WrappedDataGrid
            rows={data_analytics}
            getRowId={(row) => row.id}
            pagination
            pageSizeOptions={[10, 25, 50, 100]}
            products={products}
            status={status}
            autosizeOnMount={true}
            ignoreDiacritics
            autoHeight
            slots={{
              toolbar: GridToolbar,
              headerFilterMenu: null,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                excelOptions: { disableToolbarButton: false },
              },
            }}

          />
        ) : (
          <div>Loading...</div>
        )}
      </Box>
      <DeleteConfirmDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedItem={selectedItem}
        resource={resource}
      />
    </Card>

  )
}

export default ProductsAnalytics