import React, { useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
// eye and pencil icons
import { BsFillEyeFill, BsPencilSquare, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { API_BASE_URL } from "config";
import SolidSubtleAlert from "./SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";


function SearchTable(props) {
  const { columnsData, tableData, setTableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const resource = 'order/sales-order'
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState({ title: "Error", description: "", display: false });
  const [message, setMessage] = useState({ title: "Success", description: "", display: false });

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };


  const handleDeleteConfirmed = () => {
    setMessage(() => ({ ...message, display: false }));
    setError(() => ({ ...error, display: false }));
    fetch(`${API_BASE_URL}/${resource}/${deleteId}`, {
      method: 'DELETE',
    })
      .then((response) => {
        console.log(response);
        if (!response.ok || !response.status === 204) {
          throw new Error(response.statusText)
        }
        return response
      })
      .then((data) => {
        // Remove the deleted element from the tableData prop
        const updatedData = tableData.filter((item) => item.id !== deleteId);
        setTableData(updatedData);

        setMessage(() => ({
          title: "Success",
          description: "Record deleted successfully",
          display: true,
        }));
      })
      .catch((error) => {
        // Handle any error that occurred during the delete action
        console.error(error);
        setError(() => ({
          title: "Error",
          description: error.message,
          display: true,
        }));
      })
      .finally(() => {
        // Close the delete confirmation modal
        setShowDeleteModal(false);
        setDeleteId(null);
      });
  };


  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  return (
    <div className="h-full w-full">
      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 shadow">
          <div className="bg-white dark:bg-navy-800 rounded-lg p-8  shadow">
            <h3 className="text-lg font-medium mb-4">
              Are you sure you want to delete?
            </h3>
            <div className="flex justify-end">
              <button
                className="mr-2 text-sm font-medium text-red-500 hover:text-red-700"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-sm font-medium text-brand-500 hover:text-red-700"
                onClick={handleDeleteConfirmed}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white p-[8px] pt-[18px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:ml-3 md:w-[400px]"
      >
        <div className="flex h-9 w-[400px] flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {/* table */}
      {/* error */}

      {error.display && (
        <div className="pt-4 px-4">
          <SolidSubtleAlert
            title={error.title}
            description={error.description}
            icon={<AiFillExclamationCircle />}
            iconColor="text-white dark:!text-navy-900"
            closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
            bg="bg-red-500 dark:!bg-red-300"
            mb="mb-0"
            solid="solid"
            data={error}
            setAlert={setError}
          />
        </div>
      )}
      {message.display && (
        <div className="pt-4 px-4">
          <SolidSubtleAlert
            title={message.title}
            description={message.description}
            icon={<BsFillCheckCircleFill />}
            iconColor="text-white dark:!text-navy-900"
            closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
            bg="bg-green-500 dark:!bg-green-300"
            mb="mb-6"
            solid="solid"
            data={message}
            setAlert={setMessage}
          />
        </div>
      )}

      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className=" items-center border-b border-gray-200 dark:!border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=" px-4 pb-[10px] text-xs text-gray-600 text-start"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="w-full" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:!border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.type === "text") {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    }
                    if (cell.column.type === "date") {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {new Date(cell.value).toLocaleDateString('pt-BR')}
                        </div>
                      );
                    }
                    else if (cell.column.type === "text-image") {
                      data = (
                        <div className="flex w-full items-center gap-[14px]">
                          <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full ">
                            <img
                              className="rounded-full"
                              src={cell.value[1]}
                              alt=""
                            />
                          </div>
                          <p className="font-medium text-navy-700 dark:text-white">
                            {cell.value[0]}
                          </p>
                        </div>
                      );
                    }
                    else if (cell.column.type === "actions") {
                      data = (
                        <div
                          className="flex flex-row gap-3 text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white"
                          id={cell.value}
                        >
                          <Link to={`/admin/${resource}/edit/${cell.value}`}>
                            <BsPencilSquare />
                          </Link>
                          <BsTrash onClick={() => handleDeleteClick(cell.row.original.id)} />
                          <Link to={`/admin/${resource}/detail/${cell.value}`}>
                            <BsFillEyeFill />
                          </Link>
                        </div>
                      );
                    }
                    return (
                      <td
                        className=" mt-[20px] px-4 py-[16px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        <div className="text-sm text-gray-700">
          Showing {pageSize * pageIndex + 1} to{" "}
          {pageSize * (pageIndex + 1) <= tableData.length
            ? pageSize * (pageIndex + 1)
            : tableData.length}{" "}
          of {tableData.length} entries
        </div>

        <div className="flex space-x-2">
          {pageIndex > 0 && (
            <button
              className="h-10 w-10 rounded-full text-md text-gray-600 hover:underline dark:text-lightPrimary dark:hover:underline"
              onClick={() => tableInstance.previousPage()}
            >
              Prev
            </button>
          )}

          {tableInstance.pageCount > 1 && (
            <div className="h-10 w-10 rounded-full bg-brand-500 text-lg text-white flex items-center justify-center">
              {pageIndex + 1}
            </div>
          )}

          {pageIndex < tableInstance.pageCount - 1 && (
            <button
              className="h-10 w-10 rounded-full text-md text-gray-600 hover:underline dark:text-lightPrimary dark:hover:underline"
              onClick={() => tableInstance.nextPage()}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div >
  );
}

export default SearchTable;
