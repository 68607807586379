import React from 'react';
import { Stack, Box, Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import {
    GridToolbarQuickFilter,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton
} from '@mui/x-data-grid-premium';

const ToolbarCrud = (props) => {
    const { resource, name } = props;
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            py={1}
        >
            {!isMobile && (
                <>
                    <Box color="secondary" display="flex" justifyContent="flex-end" alignItems="flex-start" >
                        <GridToolbarQuickFilter />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={3}
                    >
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarExport />
                        <Button
                            variant="contained"
                            display="flex"
                            color="primary"
                            component={Link}
                            to={`/admin/${resource}/new`}
                            startIcon={<MdAdd />}
                        >
                            Create {name}
                        </Button>
                    </Stack>
                </>
            )}
            {isMobile && (
                <>
                    <Box color="secondary" display="flex" justifyContent="flex-end" alignItems="flex-start" >
                        <GridToolbarQuickFilter />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={3}
                    >
                        <Button
                            variant="contained"
                            display="flex"
                            color="primary"
                            component={Link}
                            to={`/admin/${resource}/new`}
                            startIcon={<MdAdd />}
                        >
                            Create
                        </Button>
                    </Stack>
                </>
            )}
        </Stack>
    );
}

export default ToolbarCrud;
