import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import Resource from "./components/steps/Resource";
import Address from "./components/steps/Address";
import Card from "components/card";
import { API_BASE_URL } from 'config';
import SolidSubtleAlert from "./components/SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";


const Form = () => {
  const resource = 'supplier';
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({ address: {} });
  const [error, setError] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const [message, setMessage] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const navigate = useNavigate();

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleCloseError = () => {
    setError({ ...error, display: false });
  };

  const handleCloseMessage = () => {
    setMessage({ ...message, display: false });
  };


  useEffect(() => {
    if (id) {
      fetch(`${API_BASE_URL}/${resource}/${id}`)
        .then((response) => response.json())
        .then((data) => {
          const updatedData = Object.entries(data).reduce((acc, [key, value]) => {
            acc[key] = value === null ? '' : value;
            return acc;
          }, {});
          setFormData(updatedData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id.includes(".")) {
      const nestedFields = id.split(".");
      let updatedFormData = { ...formData };
      let currentField = updatedFormData;

      for (let i = 0; i < nestedFields.length - 1; i++) {
        const field = nestedFields[i];
        currentField = currentField[field] || {};
      }

      const lastField = nestedFields[nestedFields.length - 1];
      currentField[lastField] = value;

      setFormData(updatedFormData);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    }
  };

  const steps = [
    { stepNo: 1, name: `${capitalizeFirstLetter(resource)} Info` },
    { stepNo: 2, name: `Address Info` },
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Resource
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      case 2:
        return <Address
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError({ title: `Problem with ${resource}`, description: '', display: false });
    setMessage({ ...message, display: false });


    // Make a request to your backend API with the dataToSubmit
    const method = id ? 'PUT' : 'POST';
    const url = id ? `${API_BASE_URL}/${resource}/${id}/` : `${API_BASE_URL}/${resource}/`;

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }).then(response => {
      return response.json().then(data => ({ status_code: response.status, data }));
    }).then(response => {
      console.log(response);
      if (response.status_code === 200 || response.status_code === 201 || response.status_code === 204) {
        setMessage({
          title: `${capitalizeFirstLetter(resource)} saved successfully`,
          display: true
        });
        navigate(`/admin/${resource}/edit/${response.data.id}`)
      }
      else {
        console.error(
          `Error ${response.status_code} ${response.data}`
        )
        console.error(response.data)
        setError({
          title: `Problem with ${resource}`,
          display: true,
          description: Object.entries(response.data).map(([key, value]) => (
            <div key={key}>
              <ul>
                {Array.isArray(value) ? (
                  value.map((message, index) => (
                    <li key={index}>{key}: {message}</li>
                  ))
                ) : (
                  Object.entries(value).map(([subKey, subValue]) => (
                    <li key={subKey}>{subKey}: {subValue}</li>
                  ))
                )}
              </ul>
            </div>
          ))
        });
      }
    }).catch(error => {
      console.error(error)
      setError({
        title: `Problem with ${resource}`, description: error
      })
    }
    );
  };



  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">

        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          {error.display && (
            <div className="h-100">
              {
                <div className="pt-4 px-4">

                  <SolidSubtleAlert
                    title={error.title}
                    description={error.description}
                    icon={<AiFillExclamationCircle />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-red-500 dark:!bg-red-300"
                    mb="mb-0"
                    solid="solid"
                    data={error}
                    setAlert={setError}
                  />
                </div>

              }
            </div>

          )}
          {message.display && (
            <div className="h-100">
              {
                <div className="pt-4 px-4">

                  <SolidSubtleAlert
                    title={message.title}
                    description={message.description}
                    icon={<BsFillCheckCircleFill />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-green-500 dark:!bg-green-300"
                    mb="mb-6"
                    solid="solid"
                    data={message}
                    setAlert={setMessage}
                  />
                </div>

              }
            </div>

          )}

          <form onSubmit={handleSubmit}>

            <div className="rounded-[20px]">

              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </div>
            {/* navigation button */}
            <StepperControl
              handleClick={handleClick}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              steps={steps}
            />
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Form;
