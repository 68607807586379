import React, { useEffect, useState, useContext } from "react";
import Card from 'components/card';
import { API_BASE_URL } from 'config';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import {
    DataGridPremium,
    GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import * as XLSX from "xlsx";
import Tooltip from '@mui/material/Tooltip';
import AuthContext from 'contexts/AuthProvider';
import { useLoading } from 'contexts/LoadingContext';
import { useNotification } from 'contexts/NotificationContext'
import { useFetchData } from 'services/queries';

import JSZip from 'jszip';
import ImportProductsTemplate from './helpers/Import Products Template.zip';
import DownloadIcon from '@mui/icons-material/Download';

function truncateFloatNumber(number) {
    const numberString = number.toString();
    const decimalPlaces = numberString.split('.')[1];
    if (decimalPlaces && decimalPlaces.length > 5) {
        return parseFloat(number).toFixed(5);
    }
    return number;
}

function getErrorMessageResponse(response) {
    let message = {};
    if (response.code === 'server_error') {
        message['Error'] = [response.error.message];
        const errorMessage = response.error.message;
        const detailIndex = errorMessage.indexOf('DETAIL:');
        if (detailIndex !== -1) {
            message['Error'] = [errorMessage.slice(detailIndex + 7)];
        }
    } else {
        Object.keys(response).forEach(key => {
            if (key !== 'status_code' && key !== 'server_error') {
                message[key] = response[key].join(', ');
            }
        });
    }
    return message;
}



const LoadData = () => {
    const resource = 'product';
    const [rows, setRows] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const { apiRequest } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const { setNotification } = useNotification();
    const { data: categories, error: errorCategories, isLoading: isLoadingCategories } = useFetchData('product/category');
    const { data: products, error: errorProducts, isLoading: isLoadingProducts } = useFetchData('product');

    useEffect(() => {
        setIsDataLoaded(() => rows.length > 0);
    }, [rows]);

    function EditToolbar(props) {
        return (
            <GridToolbarContainer>
                <Tooltip title="Upload a Excel file">
                    <Button
                        startIcon={<UploadIcon />}
                        component="label"
                        sx={{
                            opacity: isDataLoaded ? 0.4 : 1,
                            pointerEvents: isDataLoaded ? 'none' : 'auto',
                        }}
                    >
                        Upload File
                        <input type="file" accept=".zip" hidden onChange={handleZipUpload} />
                    </Button>
                </Tooltip>

                {isDataLoaded && (
                    <Tooltip title="Import data to the system">
                        <Button startIcon={<CheckIcon />} color="info" onClick={handleDataLoad} >Import it </Button>
                    </Tooltip>
                )}

                <Tooltip title="Download Template">
                    <Button startIcon={<DownloadIcon />} color="primary">
                        <a href={ImportProductsTemplate} download="Import Products Template.zip">
                            Download Template
                        </a>
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    const handleDataLoad = async () => {
        try {
            setLoading(true);
            const updatedRows = [...rows];

            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                try {
                    const products_found = products.filter((product) =>
                        (product.sku === String(row.SKU) && !row.auto_generate_sku) ||
                        (product.name.toUpperCase() === row.Name.toUpperCase() && row.auto_generate_sku)
                    );
                    let category = categories.filter((category) => category.name_path === row.Category);

                    if (category.length > 1) {
                        updatedRows[i] = {
                            ...row,
                            STATUS: 'ERROR',
                            MESSAGE: { 'Error': ['Multiple categories found. Please, use the Full Path if it is a subcategory (eg: Electronics > Smartphones).'] }
                        };
                        setRows([...updatedRows]);
                        continue;
                    } else if (category.length < 1) {
                        updatedRows[i] = {
                            ...row,
                            STATUS: 'ERROR',
                            MESSAGE: { 'Error': ['Category not found. Please, use the Full Path if it is a subcategory (eg: Electronics > Smartphones).'] }
                        };
                        setRows([...updatedRows]);
                        continue;
                    } else {
                        category = category[0];
                    }

                    const category_id = category.id;
                    const data = {
                        name: row.Name,
                        ...(row.auto_generate_sku ? {} : { sku: row.SKU }),
                        category_id: category_id,
                        short_description: row.Description,
                        hs_code_or_ncm: row['NCM/HSCODE'],
                        type: row.Type,
                        unit_per_ctn: row['PCS/CTN'],
                        nw_per_ctn: truncateFloatNumber(row['NW/CTN']),
                        gw_per_ctn: truncateFloatNumber(row['GW/CTN']),
                        master_box_height: row['HEIGHT/CTN'],
                        master_box_length: row['LENGTH/CTN'],
                        master_box_width: row['WIDTH/CTN'],
                        auto_generate_sku: row['auto_generate_sku'],
                    };
                    if (row.picture) {
                        data['picture'] = row.picture;
                    }

                    const formData = new FormData();
                    for (const [key, value] of Object.entries(data)) {
                        if (value) {
                            formData.set(key, value);
                        }
                    }

                    if (products_found.length === 1) {
                        // Update existing product
                        const product = products_found[0];
                        const response = await updateProduct(product.id, formData, 'PUT');

                        if (response.status_code !== 200) {
                            updatedRows[i] = {
                                ...row,
                                MESSAGE: getErrorMessageResponse(response),
                                STATUS: 'ERROR',
                            };
                            setRows([...updatedRows]);
                            continue;
                        }

                        updatedRows[i] = {
                            ...row,
                            STATUS: 'UPDATED',
                        };
                    } else if (products_found.length === 0) {
                        // Create new product
                        const response = await updateProduct('', formData, 'POST');

                        if (response.status_code !== 201) {
                            updatedRows[i] = {
                                ...row,
                                STATUS: 'ERROR',
                                MESSAGE: getErrorMessageResponse(response),
                            };
                            setRows([...updatedRows]);
                            continue;
                        }
                        updatedRows[i] = {
                            ...row,
                            STATUS: 'CREATED',
                        };
                    } else {
                        updatedRows[i] = {
                            ...row,
                            STATUS: 'ERROR',
                            MESSAGE: { 'Error': ['Unknown error.'] }
                        };
                        setRows([...updatedRows]);
                        continue;
                    }

                    // Update the state after each row
                    setRows([...updatedRows]);

                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error.message || (error.error && error.error.message) || 'Unknown error.';
                    updatedRows[i] = {
                        ...row,
                        STATUS: 'ERROR',
                        MESSAGE: { 'Error': [errorMessage] }
                    };
                    setRows([...updatedRows]);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            setNotification({
                title: `Problem with ${resource}`,
                display: true,
                description: "Something went wrong.",
                type: 'error'
            });
        }
    };

    const updateProduct = async (id, data, method) => {
        const url = id ? `${API_BASE_URL}/${resource}/${id}/` : `${API_BASE_URL}/${resource}/`;
        const response = await apiRequest(url, {
            method: method,
            body: data,
        });
        return response;
    };

    const handleZipUpload = async (event) => {
        try {
            setLoading(true);
            const file = event.target.files[0];
            const zip = new JSZip();
            const content = await zip.loadAsync(file);
            const excelFileName = Object.keys(content.files).find((fileName) => fileName.endsWith('.xlsx') || fileName.endsWith('.xls'));
            const imageFolderName = 'pictures/';
            const images = {};
            const file_images = {};

            if (excelFileName) {
                const excelFile = await content.files[excelFileName].async('binarystring');
                const wb = XLSX.read(excelFile, { type: 'binary' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
                const header = data[1];
                data = data.filter((row) => row.some((cell) => cell));

                let newRows = data.slice(4).map((row, index) => {
                    const newRow = {};
                    header.forEach((key, i) => {
                        newRow[key] = row[i];
                    });
                    const sku = String(newRow.SKU);
                    const name = String(newRow.Name);
                    let product;
                    if (sku) {
                        product = products.find((product) => (product.sku === sku));
                    } else if (name) { // if sku is not provided, search by name (Auto SKU)
                        product = products.filter((product) => product.name.toUpperCase() === name.toUpperCase());
                        if (product.length > 1) {
                            newRow['STATUS'] = 'ERROR';
                            newRow['MESSAGE'] = { 'Error': ['Several products found with this name. Please use the SKU to select the correct product.'] };
                            return newRow;
                        }
                        else if (product.length === 1) {
                            product = product[0];
                        } else {
                            product = null;
                        }
                    }
                    if (product) {
                        newRow['STATUS'] = 'Exists';
                        newRow['auto_generate_sku'] = product.auto_generate_sku;
                        if (product.auto_generate_sku) {
                            newRow['SKU'] = product.sku;
                        }
                        if (!sku) {
                            newRow['SKU'] = product.sku;
                        }
                    } else {
                        newRow['STATUS'] = 'Not Exists';
                        newRow['auto_generate_sku'] = !sku;
                    }
                    newRow['MESSAGE'] = '';
                    newRow.id = index + 1;

                    return newRow;
                });

                // Load images from the 'pictures' folder
                await Promise.all(Object.keys(content.files).map(async (fileName) => {
                    if (fileName.includes(imageFolderName) && !fileName.endsWith('/')) {
                        const imgBlob = await content.files[fileName].async('blob'); // Convert to Blob
                        const imageName = fileName.split('/').pop().split('.')[0];
                        const extension = fileName.split('.').pop();
                        const mimeType = extension === 'png' ? 'image/png' : 'image/jpeg';
                        images[imageName] = URL.createObjectURL(imgBlob); // Create a URL for display in the DataGrid
                        file_images[imageName] = new File([imgBlob], `${imageName}.${extension}`, { type: mimeType }); // Create a File object
                    }
                }));

                // Merge images with rows
                newRows = newRows.map(row => ({
                    ...row,
                    image: row['Image Name'] ? images[row['Image Name']] : null,
                    picture: row['Image Name'] ? file_images[row['Image Name']] : null,
                }));

                setRows(() => newRows);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 80,
            renderCell: (params) => (
                params.value ? <img src={params.value} alt="Product" style={{ maxWidth: '100%', maxHeight: '100%', padding: "4px" }} /> : null
            ),
        },
        { field: 'Name', headerName: 'Name', width: 280, editable: true },
        {
            field: 'SKU',
            headerName: 'SKU',
            width: 120,
        },
        {
            field: 'auto_generate_sku',
            headerName: 'Auto Generate SKU',
            width: 150,
            renderCell: (params) => (
                <div className="flex items-center">
                    <span
                        className={`w-3 h-3 rounded-full inline-block mr-2 ${params.value
                            ? 'bg-green-500'
                            : 'bg-gray-500'
                            }`}
                    ></span>
                    {params.value ? 'Yes' : 'No'}
                </div>
            ),
        },
        {
            field: 'Category',
            headerName: 'Category (Full Path)',
            width: 150,
        },
        {
            field: 'Description',
            headerName: 'Description',
            width: 180,
        },
        {
            field: 'NCM/HSCODE',
            headerName: 'NCM/HSCODE',
            width: 130,
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 70,
        },
        {
            field: 'PCS/CTN',
            headerName: 'PCS/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'NW/CTN',
            headerName: 'NW/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'GW/CTN',
            headerName: 'GW/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'HEIGHT/CTN',
            headerName: 'HEIGHT/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'LENGTH/CTN',
            headerName: 'LENGTH/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'WIDTH/CTN',
            headerName: 'WIDTH/CTN',
            width: 120,
            type: "number",
        },
        {
            field: 'STATUS',
            headerName: 'STATUS',
            width: 150,
            defaultValue: 'WAITING',
            // render a tag style for different status (WAITING, SUCCESS, ERROR)
            renderCell: (params) => (
                <div className="flex items-center">
                    <span
                        className={`w-3 h-3 rounded-full inline-block mr-2 ${params.value === 'Not Exists' || params.value === 'Awaiting Import'
                            ? 'bg-gray-500'
                            : params.value === 'Exists'
                                ? 'bg-yellow-500'
                                : (params.value === 'SUCCESS' || params.value === 'UPDATED' || params.value === 'CREATED')
                                    ? 'bg-green-500'
                                    : 'bg-red-500'
                            }`}
                    ></span>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'MESSAGE',
            headerName: 'MESSAGE',
            width: 200,
            renderCell: (params) => (
                <div className="flex flex-col">
                    {params.row.MESSAGE &&
                        Object.entries(params.row.MESSAGE).map(([key, value]) => (
                            <span key={key}>
                                <b>{key}</b>: {value}
                            </span>
                        ))}
                </div>
            ),
        },
    ];

    if (isLoadingCategories | isLoadingProducts) return <div>Loading...</div>;
    if (errorCategories) return <div>Error: {errorCategories.message}</div>;
    if (errorProducts) return <div>Error: {errorProducts.message}</div>;


    return (
        <Card extra={'w-full h-full bg-white mt-3 p-3'}>
            <Box
                sx={{
                    // height: 500,
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                }}
            >
                <DataGridPremium
                    rows={rows}
                    columns={columns.map(column => ({
                        ...column,
                        editable: false
                    }))}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    getRowId={(row) => row.id}
                    initialState={{ pinnedColumns: { left: ['STATUS', 'MESSAGE'] } }}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                    }}
                />
            </Box>
        </Card>
    );
}


export default LoadData;
