import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGridPremium,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { useFetchData, useFetchDataId, useCreateData, useUpdateData, useDeleteData } from "services/queries";


const Skus = () => {
    const { id } = useParams();
    const { data: clientsSkuData, error: errorclientsSkuData, isLoading: isLoadingclientsSkuData, refetch } = useFetchData(`product/client-sku/?product=${id}`, true);
    const { data: clients, error: errorClients, isLoading: isLoadingClients } = useFetchData(`client`, true);
    const { data: productData, error: errorProductData, isLoading: isLoadingProductData } = useFetchDataId(`product`, id, true);
    const deleteClientSku = useDeleteData(`product/client-sku`);
    const updateClientSku = useUpdateData(`product/client-sku`);
    const createClientSku = useCreateData(`product/client-sku`);
    const [rows, setRows] = React.useState({});

    function EditToolbar(props) {
        console.log(props)
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const id = 'NEW';
            const newRow = { id, client: '', sku: '', product: productData?.id, isNew: true };
            setRows((oldRows) => [...oldRows, newRow]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'client' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add SKU
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        refetch();
        setRows(() => clientsSkuData)
    }, [clientsSkuData, deleteClientSku.isSuccess, updateClientSku.isSuccess, createClientSku.isSuccess]);


    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        deleteClientSku.mutate(id);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const oldRow = rows.find((row) => row.id === newRow.id);

        if (newRow.isNew) {
            createClientSku.mutate(newRow);
            if (!createClientSku.isSuccess) {
                throw updateClientSku.error
            }
        }

        const updatedRow = { ...newRow, isNew: false };
        if (!newRow.isNew) {
            updateClientSku.mutate({ id: updatedRow.id, newData: updatedRow });
            if (!updateClientSku.isSuccess) {
                throw updateClientSku.error
            }
        }

        return updatedRow;
    };

    const handleProcessRowUpdateError = (error) => {
        console.log(error)
    }

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            field: 'client',
            headerName: 'Client',
            type: 'singleSelect',
            width: 270,
            editable: true,
            valueOptions: clients?.map((client) => ({
                value: client.id,
                label: client.social_reason,
            }))
        },
        {
            field: 'sku',
            headerName: 'Sku',
            width: 100,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'product',
            headerName: 'Product',
            type: 'singleSelect',
            width: 200,
            editable: false,
            valueOptions: [{ value: productData?.id, label: productData?.name }]

        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    if (isLoadingclientsSkuData || isLoadingClients || isLoadingProductData) return (<div>Loading...</div>)
    if (errorclientsSkuData || errorClients || errorProductData) return (<div>Error! {errorclientsSkuData.message}</div>)


    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Client SKUs
            </h1>
            <div className="mt-10 flex flex-col h-full w-full items-center justify-center">
                <Box
                    sx={{
                        // height: 500,
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                    }}
                >

                    {!isLoadingProductData && rows && (
                        <DataGridPremium
                            rows={rows}
                            columns={columns}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            slotProps={{
                                toolbar: { setRows, setRowModesModel },
                            }}
                        />
                    )}
                </Box>
            </div>
        </div>
    )
}

export default Skus