import React from 'react'
import { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { API_BASE_URL } from "config";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { CiCircleRemove } from "react-icons/ci";
import { useRef } from 'react';


const Suppliers = ({ formData, setFormData, handleInputChange, setShouldUpdateFormData }) => {
    // get all suppliers from API to use in the auto complete field latter
    const [suppliersRemains, setSuppliersRemains] = useState([])
    const [newSupplierId, setNewSupplierId] = useState('')
    const [suppliers, setSuppliers] = useState([])
    const autoCompleteRef = useRef(null);


    useEffect(() => {
        const getSuppliers = async () => {
            const suppliersFromServer = await fetchSuppliers()
            // filter supplier that already are in the product
            if (formData.suppliers && formData.suppliers.length > 0) {
                const suppliersFiltered = suppliersFromServer.filter((supplier) =>
                    formData.suppliers.every((supplierObj) => supplier.id !== supplierObj.id)
                );
                setSuppliersRemains(suppliersFiltered)
            }
            else {
                setSuppliersRemains(suppliersFromServer)
            }

            setSuppliers(suppliersFromServer)
        }
        getSuppliers()

    }, [formData.suppliers])


    const fetchSuppliers = async () => {
        const res = await fetch(`${API_BASE_URL}/supplier/`)
        if (!res.ok) {
            throw Error('Could not fetch the data for that resource')
        }
        const data = await res.json()
        return data.results
    }

    const handleSupplierChange = (event, value) => {
        if (value) {
            const selectedSupplier = suppliers.find((supplier) => supplier.name === value);
            if (selectedSupplier) {
                const selectedSupplierId = selectedSupplier.id;
                setNewSupplierId(selectedSupplierId);
                console.log("Selected Supplier ID:", selectedSupplierId);
            }
        }
    };

    const addSupplierToProduct = async () => {
        try {
            const url = `${API_BASE_URL}/product/${formData.id}/add_supplier/`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ supplier_id: newSupplierId }),
            });
            if (!response.ok) {
                throw new Error('Failed to add supplier to product');
            }

            setShouldUpdateFormData(() => true);

        } catch (error) {
            console.error(error);
        }
    };

    const removeProductSupplier = async (supplierId) => {
        try {
            console.log("Supplier ID:", supplierId);
            const url = `${API_BASE_URL}/product/${formData.id}/remove_supplier/`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ supplier_id: supplierId }),
            });
            if (!response.ok) {
                throw new Error('Failed to remove supplier from product');
            }
            // Handle success scenario as needed
            console.log('Supplier removed from the product successfully');
            // Update the suppliers list
            setShouldUpdateFormData(() => true);
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Suppliers
            </h1>
            {/* inputs */}
            <div className="mt-10 flex flex-col h-full w-full items-center justify-center">
                {/* select field with auto complete */}
                <div className="w-full flex flex-row items-center gap-3">
                    <Autocomplete
                        ref={autoCompleteRef}
                        disablePortal
                        id={`combo-box-supplier`}
                        options={suppliersRemains && suppliersRemains.map((option) => option.name)}
                        sx={{ width: 300 }}
                        onChange={handleSupplierChange}
                        renderInput={(params) => <TextField {...params} label="Add Supplier" />}
                    />
                    {/* when select the supplier option, allow click a circle check button to add the supplier */}
                    <button
                        onClick={addSupplierToProduct}
                        type="button"
                    >
                        <MdOutlineAddCircleOutline
                            // className="text-green-500 border-green-500"
                            className="h-5 w-5 rounded-full text-md text-green-500 hover:underline hover:text-green-600 hover:h-7 hover:w-7 dark:text-lightPrimary dark:hover:underline"
                            role="button"
                        />
                    </button>
                </div>
                {/* Suppliers List */}
                <div className="mt-5 w-full h-full">
                    <ul className="w-full h-full">
                        {formData.suppliers && formData.suppliers.map((supplierObj) => {

                            const supplier = suppliers.find((supplier) => supplier.id === supplierObj.id);
                            if (!supplier) {
                                return (<li>No suppliers</li>);
                            }
                            return (
                                <li key={supplierObj.id} className="flex flex-row items-center justify-between w-full h-10 px-3 py-2 border-b border-gray-300 dark:border-gray-700">
                                    <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                                        {supplier.name}
                                    </span>
                                    <button
                                        onClick={() => removeProductSupplier(supplierObj.id)}
                                        id={supplierObj.id}
                                        type="button"
                                    >
                                        <CiCircleRemove
                                            // className="text-green-500 border-green-500"
                                            className="h-5 w-5 rounded-full text-md text-red-500 hover:underline hover:text-red-600 hover:h-7 hover:w-7 dark:text-lightPrimary dark:hover:underline"
                                            role="button"
                                        />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Suppliers