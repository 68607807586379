import InputField from "components/fields/InputField";
import TagsField from "components/fields/TagsField";

const Details = ({ formData, setFormData, handleInputChange }) => {
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Product Details
      </h1>
      {/* inputs */}
      <div className="mt-10 grid h-full w-full grid-cols-1 gap-3 md:grid-cols-2">
        {/* left side */}
        <div className="flex h-fit flex-col gap-3 md:col-span-2 md:grid md:grid-cols-2">
          <InputField
            label="NCM/HSCODE"
            placeholder="8465.93.10"
            id="hs_code_or_ncm"
            name="hs_code_or_ncm"
            type="text"
            value={formData.hs_code_or_ncm}
            onChange={handleInputChange}
          />
          <InputField
            label="Type"
            placeholder="PC"
            id="type"
            name="type"
            type="text"
            value={formData.type}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex h-fit flex-col gap-3 md:col-span-2 md:grid md:grid-cols-3">
          <InputField
            label="PCS/CTN"
            placeholder="10"
            id="unit_per_ctn"
            type="number"
            name="unit_per_ctn"
            value={formData.unit_per_ctn}
            onChange={handleInputChange}
          />
          <InputField
            label="NW/CTN"
            placeholder="1.46"
            id="nw_per_ctn"
            type="number"
            name="nw_per_ctn"
            value={formData.nw_per_ctn}
            onChange={handleInputChange}
          />
          <InputField
            label="GW/CTN"
            placeholder="1.50"
            id="gw_per_ctn"
            type="number"
            name="gw_per_ctn"
            value={formData.gw_per_ctn}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex h-fit flex-col gap-3 md:col-span-2 md:grid md:grid-cols-3">

        </div>
        <div className="flex h-fit flex-col gap-3 md:col-span-2 md:grid md:grid-cols-3">
          <InputField
            label="HEIGHT/CTN"
            placeholder="21"
            id="master_box_height"
            type="number"
            name="master_box_height"
            value={formData.master_box_height}
            onChange={handleInputChange}
          />
          <InputField
            label="LENGTH/CTN"
            placeholder="12.30"
            id="master_box_length"
            type="number"
            name="master_box_length"
            value={formData.master_box_length}
            onChange={handleInputChange}
          />
          <InputField
            label="WIDTH/CTN"
            placeholder="23.50"
            id="master_box_width"
            type="number"
            name="master_box_width"
            value={formData.master_box_width}
            onChange={handleInputChange}
          />

        </div>
      </div>
    </div>
  );
};

export default Details;
