import React, { createContext, useState } from 'react';

export const ColorModeContext = createContext({
    isDarkMode: false,
    toggleColorMode: () => { },
    setIsDarkMode: () => { },
});

export const ColorModeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleColorMode = () => {
        setIsDarkMode(!isDarkMode);
        console.log("Toggled Dark Mode:", !isDarkMode); // Check if state is toggling

    };

    return (
        <ColorModeContext.Provider value={{ isDarkMode, toggleColorMode, setIsDarkMode }}>
            {children}
        </ColorModeContext.Provider>
    );
};
