import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import Resource from "./components/steps/Resource";
import Media from "./components/steps/Media";
import Card from "components/card";
import { API_BASE_URL } from 'config';
import SolidSubtleAlert from "./components/SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import AuthContext from 'contexts/AuthProvider';
import { useLoading } from 'contexts/LoadingContext';
import { withLoadingAndAlert } from 'utils/withLoading';
import { useNotification } from 'contexts/NotificationContext'
import { useFetchData } from 'services/queries';


const Form = () => {
  const resource = 'authentication/users';
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const [message, setMessage] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const navigate = useNavigate();
  const [shouldUpdateFormData, setShouldUpdateFormData] = useState(false);
  const { apiRequest } = useContext(AuthContext);

  const { setLoading } = useLoading();
  const { setNotification } = useNotification();
  const apiRequestWithLoading = withLoadingAndAlert(setLoading, setNotification, apiRequest);

  const { data: clientStaffGroups, error: errorClientStaffGroups, isLoading: isLoadingClientStaffGroups } = useFetchData('authentication/groups/?name__icontains=[Client Staff]', true);
  const { data: clientAdminGroups, error: errorClientAdminGroups, isLoading: isLoadingClientAdminGroups } = useFetchData('authentication/groups/?name__icontains=[Client Admin]', true);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleCloseError = () => {
    setError({ ...error, display: false });
  };

  const handleCloseMessage = () => {
    setMessage({ ...message, display: false });
  };


  useEffect(() => {
    if (id && !isLoadingClientAdminGroups && !isLoadingClientStaffGroups) {

      apiRequestWithLoading(`${API_BASE_URL}/${resource}/${id}`)
        .then((data) => {
          const updatedData = Object.entries(data).reduce((acc, [key, value]) => {
            acc[key] = value === null ? '' : value;
            return acc;
          }, {});

          setFormData(prevFormData => ({
            ...updatedData,
            client_staff_groups: clientStaffGroups.filter(group => updatedData.groups?.includes(group.id)).map(group => group.id),
            client_admin_groups: clientAdminGroups.filter(group => updatedData.groups?.includes(group.id)).map(group => group.id),
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, shouldUpdateFormData, clientStaffGroups, clientAdminGroups, isLoadingClientAdminGroups, isLoadingClientStaffGroups]);

  // update formData when shouldUpdateFormData is true
  useEffect(() => {
    if (shouldUpdateFormData) {
      setShouldUpdateFormData(false);
    }
  }, [shouldUpdateFormData]);

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    if (id === 'client_staff_groups') {
      console.log('client_staff_groups', value);
      setFormData(prevFormData => {
        return {
          ...prevFormData,
          ['client_staff_groups']: value
        };
      });
    } else if (id === 'client_admin_groups') {
      console.log('client_admin_groups', value);
      setFormData(prevFormData => {
        return {
          ...prevFormData,
          ['client_admin_groups']: value
        };
      });
    }
    else if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: checked,
      }));
    } else if (id.includes(".")) {
      const nestedFields = id.split(".");
      let updatedFormData = { ...formData };
      let currentField = updatedFormData;

      for (let i = 0; i < nestedFields.length - 1; i++) {
        const field = nestedFields[i];
        currentField = currentField[field] || {};
      }

      const lastField = nestedFields[nestedFields.length - 1];
      currentField[lastField] = value;

      setFormData(updatedFormData);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    }
  };

  const steps = [
    { stepNo: 1, name: `User Info` },
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Resource
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError({ title: `Problem with ${resource}`, description: '', display: false });
    setMessage({ ...message, display: false });


    var submitFormData = new FormData();
    var groupIds = formData.groups || [];

    const groupIdsNotInClientStaffGroups = groupIds.filter(groupId => !clientStaffGroups.map(group => group.id).includes(groupId));
    const groupIdsNotInClientAdminGroups = groupIds.filter(groupId => !clientAdminGroups.map(group => group.id).includes(groupId));

    // groupIds should be all groups in formData.groups except client staff and admin groups
    groupIds = groupIds.filter(groupId => groupIdsNotInClientStaffGroups.includes(groupId) && groupIdsNotInClientAdminGroups.includes(groupId));

    for (const [key, value] of Object.entries(formData)) {
      if (value === "" || value === null || (typeof value === "object" && Object.keys(value).length === 0)) {
        continue;
      }
      else if (key === "suppliers") {
        continue;
      }
      // before submit concat client_admin_groups and client_staff_groups into groups key
      else if (key === "client_admin_groups" || key === "client_staff_groups") {
        groupIds = [...groupIds, ...value];
      }
      else {
        submitFormData.set(key, value);
      }

    }
    let groupIdsStr = [];
    groupIds.forEach(groupId => {
      if (typeof groupId === 'string') {
        let ids = groupId.split(',').map(Number); // Convert strings to numbers
        groupIdsStr = [...groupIdsStr, ...ids];
      }
      else {
        groupIdsStr.push(groupId);
      }
    });
    // remove groups
    submitFormData.delete('groups');
    groupIdsStr.forEach(groupId => {
      submitFormData.append('groups', groupId);
    });

    if (formData.picture && typeof formData.picture === 'string') {
      submitFormData.delete('picture');
    }

    // Make a request to your backend API with the dataToSubmit
    const method = id ? 'PUT' : 'POST';
    const url = id ? `${API_BASE_URL}/${resource}/${id}/` : `${API_BASE_URL}/${resource}/`;
    apiRequestWithLoading(url, { method: method, body: submitFormData },)
      .then(response => {
        if (response.status_code === 200 || response.status_code === 201 || response.status_code === 204) {
          setMessage({
            title: `User saved successfully`,
            display: true
          })
          setTimeout(() => { }, 2000);
          // TO DO-> FIX RESOURCE
          navigate(`/admin/users/list`)
        }
        else {
          console.error(
            `Error ${response.status_code} ${response}`
          )
          setError({
            title: `Problem with ${resource}`,
            display: true,
            description: Object.entries(response).map(([key, value]) => (
              <div key={key}>
                <ul>
                  {value.map((message, index) => (
                    <li key={index}>{key}: {message}</li>
                  ))}
                </ul>
              </div>
            ))
          });
        }
      }).catch(error => {
        console.error(error)
        setError({
          title: `Problem with ${resource}`, description: error
        })
      });
  };



  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">

        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          {error.display && (
            <div className="h-100">
              {
                <div className="pt-4 px-4">

                  <SolidSubtleAlert
                    title={error.title}
                    description={error.description}
                    icon={<AiFillExclamationCircle />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-red-500 dark:!bg-red-300"
                    mb="mb-0"
                    solid="solid"
                    data={error}
                    setAlert={setError}
                  />
                </div>

              }
            </div>

          )}
          {message.display && (
            <div className="h-100">
              {
                <div className="pt-4 px-4">

                  <SolidSubtleAlert
                    title={message.title}
                    description={message.description}
                    icon={<BsFillCheckCircleFill />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-green-500 dark:!bg-green-300"
                    mb="mb-6"
                    solid="solid"
                    data={message}
                    setAlert={setMessage}
                  />
                </div>

              }
            </div>

          )}

          <form onSubmit={handleSubmit}>

            <div className="rounded-[20px]">

              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </div>
            {/* navigation button */}
            <StepperControl
              handleClick={handleClick}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              steps={steps}
              id={id}
            />
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Form;
