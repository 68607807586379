
import SolidSubtitleAlert from 'components/alert/SolidSubtitleAlert'
import { AiFillExclamationCircle } from 'react-icons/ai'

const ErrorAlert = ({ title, description, display, setNotification }) => {

    if (!display) return null;

    return (
        <SolidSubtitleAlert
            title={title}
            description={description}
            icon={<AiFillExclamationCircle />}
            iconColor="text-white dark:!text-navy-900"
            closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
            bg="bg-red-500 dark:!bg-red-300"
            mb="mb-0"
            solid="solid"
            setNotification={setNotification}
        />
    )
}

export default ErrorAlert