import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; // ensures the overlay is on top of other elements
`;

const LoadingOverlay = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <Overlay>
            <CircularProgress />
        </Overlay>
    );
};

export default LoadingOverlay;
