import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import Product from "./components/steps/Product";
import Media from "./components/steps/Media";
import Details from "./components/steps/Details";
import SubProducts from "./components/steps/SubProducts";
import Card from "components/card";
import { API_BASE_URL } from 'config';
import SolidSubtleAlert from "./components/SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Suppliers from "./components/steps/Suppliers";
import Skus from "./components/steps/Skus";
import { useFetchData } from 'services/queries';
import { Toaster, toast } from 'react-hot-toast';
import ProductAttribute from "./components/steps/ProductAttribute";
import { Box } from "@mui/system";


const Form = () => {
  const resource = 'product';
  const { id } = useParams();
  const initialFormData = id ? {} : { 'product_type': 'regular' };
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const [message, setMessage] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const navigate = useNavigate();
  const [shouldUpdateFormData, setShouldUpdateFormData] = useState(false);
  const { data: products, error: errorProducts, isLoading: isLoadingProducts } = useFetchData('product');

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (id) {
      fetch(`${API_BASE_URL}/${resource}/${id}`)
        .then((response) => response.json())
        .then((data) => {
          const updatedData = Object.entries(data).reduce((acc, [key, value]) => {
            acc[key] = value === null ? '' : value;
            return acc;
          }, {});
          console.log("updatedData: ", updatedData);
          console.log("data: ", data)

          setFormData(updatedData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, shouldUpdateFormData]);

  // update formData when shouldUpdateFormData is true
  useEffect(() => {
    if (shouldUpdateFormData) {
      setShouldUpdateFormData(false);
    }
  }, [shouldUpdateFormData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id.includes(".")) {
      const nestedFields = id.split(".");
      let updatedFormData = { ...formData };
      let currentField = updatedFormData;

      for (let i = 0; i < nestedFields.length - 1; i++) {
        const field = nestedFields[i];
        currentField = currentField[field] || {};
      }

      const lastField = nestedFields[nestedFields.length - 1];
      currentField[lastField] = value;

      setFormData(updatedFormData);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: value,
      }));
    }
  };


  const steps = [
    { stepNo: 1, name: `${capitalizeFirstLetter(resource)} Info` },
    { stepNo: 2, name: `Media` },
    ...(formData.product_type !== 'master' ? [{ stepNo: 3, name: `Details` }] : [{ stepNo: 7, name: `Components` }]),
    ...(formData.id ? [{ stepNo: 4, name: `Attributes` }] : []),
    ...(formData.id ? [{ stepNo: 5, name: `Suppliers` }] : []),
    ...(formData.id ? [{ stepNo: 6, name: `SKUs` }] : []),
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Product
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      case 2:
        return <Media
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      case 3:
        return <Details
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
      />;
      case 4:
        return <ProductAttribute
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      case 5:
        return <Suppliers
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateFormData={setShouldUpdateFormData}
        />;
      case 6:
        return <Skus
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateFormData={setShouldUpdateFormData}
        />;
      case 7:
        return <SubProducts
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (products) {
      const duplicateProduct = products.find(product => product.sku === formData.sku && product.id !== formData.id);
      if (duplicateProduct) {
        toast.error('SKU in use! Please choose another SKU.', { id: 'duplicate-sku' });
        return;
      }
    }

    setError({ title: `Problem with ${resource}`, description: '', display: false });
    setMessage({ ...message, display: false });

    // const suppliersArray = Array.isArray(formData.suppliers) ? formData.suppliers : [formData.suppliers];
    var submitFormData = new FormData();
    for (const [key, value] of Object.entries(formData)) {

      if ((typeof value === "object" && Object.keys(value).length === 0)) {
        continue;
      }
      else if (key === "suppliers") {
        continue;
      }
      else {
        submitFormData.set(key, value);
      }
    }
    // print submitFormData
    for (var pair of submitFormData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    if (formData.picture && typeof formData.picture === 'string') {
      submitFormData.delete('picture');
    }

    // Make a request to your backend API with the dataToSubmit
    const method = id ? 'PUT' : 'POST';
    const url = id ? `${API_BASE_URL}/${resource}/${id}/` : `${API_BASE_URL}/${resource}/`;
    fetch(url, {
      method: method,
      body: submitFormData,
    }).then(response => {
      return response.json().then(data => ({ status_code: response.status, data }));
    }).then(response => {
      console.log(response);
      if (response.status_code === 200 || response.status_code === 201 || response.status_code === 204) {
        setMessage({
          title: `${capitalizeFirstLetter(resource)} saved successfully`,
          display: true
        });
        navigate(`/admin/${resource}/detail/${response.data.id}`)
      }
      else {
        console.error(
          `Error ${response.status_code} ${response.data}`
        )
        setError({
          title: `Problem with ${resource}`,
          display: true,
          description: Object.entries(response.data).map(([key, value]) => (
            <Box key={key}>
              <ul>
                {value.map((message, index) => (
                  <li key={index}>{key}: {message}</li>
                ))}
              </ul>
            </Box>
          ))
        });
      }
    }).catch(error => {
      console.error(error)
      setError({
        title: `Problem with ${resource}`, description: error
      })
    }
    );
  };

  if ( isLoadingProducts) return 'Loading...';
  if ( errorProducts) return 'Error...';

  return (
    <Box className="mt-3 h-full w-full">
      <Toaster position="bottom-center" reverseOrder={false} />
      <Box className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <Box className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">

        <Box className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </Box>
        <Card extra={"h-full mx-auto pb-3"}>
          {error.display && (
            <Box className="h-100">
              {
                <Box className="pt-4 px-4">
                  <SolidSubtleAlert
                    title={error.title}
                    description={error.description}
                    icon={<AiFillExclamationCircle />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-red-500 dark:!bg-red-300"
                    mb="mb-0"
                    solid="solid"
                    data={error}
                    setAlert={setError}
                  />
                </Box>
              }
            </Box>
          )}
          {message.display && (
            <Box className="h-100">
              {
                <Box className="pt-4 px-4">
                  <SolidSubtleAlert
                    title={message.title}
                    description={message.description}
                    icon={<BsFillCheckCircleFill />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-green-500 dark:!bg-green-300"
                    mb="mb-6"
                    solid="solid"
                    data={message}
                    setAlert={setMessage}
                  />
                </Box>
              }
            </Box>
          )}

          <form onSubmit={handleSubmit}>
            <Box className="rounded-[20px]">
              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </Box>
            {/* navigation button */}
            <StepperControl
              handleClick={handleClick}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              steps={steps}
            />
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Form;
