import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useDeleteData } from "services/queries";

const DeleteConfirmDialog = (props) => {
    const { openDeleteDialog, setOpenDeleteDialog, selectedItem, resource } = props;
    const useDeleteApi = useDeleteData(resource);
    return (
        <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
        >
            <DialogTitle>{"Are you sure you want to delete this item?"}</DialogTitle>
            <DialogActions>
                <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                <Button
                    onClick={() => {
                        // Add your delete logic here

                        useDeleteApi.mutate(selectedItem)
                        console.log("Deleting item:", selectedItem);
                        setOpenDeleteDialog(false);
                    }}
                    color="primary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmDialog;