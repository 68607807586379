export const CATEGORY_CHOICES = [
    { value: 'IF', label: 'International Freight' },
    { value: 'DF', label: 'Destination Fee' },
    { value: 'CA', label: 'Company Audit' },
    { value: 'CI', label: 'Cargo Inspection' },
    { value: 'FA', label: 'Factory' },
    { value: 'OT', label: 'Others' },
];

export const SUPPLIER_TYPE_CHOICES = [
    { value: 'FACTORY', label: 'Factory' },
    { value: 'FORWARDER', label: 'Forwarder' },
    { value: 'COMPANY_AUDIT', label: 'Company Audit' },
];