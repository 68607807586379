import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import Resource from "./components/steps/Resource";
import CategoryAttribute from "./components/steps/CategoryAttribute";
import Card from "components/card";
import { useFetchDataId, useCreateData, useUpdateData } from "services/queries";
import toast from "react-hot-toast";

const Form = () => {
  const resource_path = 'product/category';
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const isEditMode = Boolean(id);
  const { data: category, error: errorCategories, isLoading: isLoadingCategories } = useFetchDataId('product/category', id, true);

  const createCategory = useCreateData(resource_path);
  const updateCategory = useUpdateData(resource_path);


  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (category) {
      setFormData(() => category);
    }
  }, [category]);


  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    isEditMode ? updateCategory.mutate({ id, newData: formData }) : createCategory.mutate(formData);
  };

  useEffect(() => {
    if (isEditMode) {
      if (updateCategory.isSuccess) {
        navigate("/admin/product/category/list");
      }
    }
    else {
      if (createCategory.isSuccess) {
        toast.success("Now you can add attributes to this category")
        navigate(`/admin/product/category/edit/${createCategory.data.id}`);
      }
    }
  }, [updateCategory.isSuccess, createCategory.isSuccess]);


  const steps = [
    { stepNo: 1, name: `Category Info` },
    ...(formData.id ? [{ stepNo: 2, name: `Attribute` }] : []),
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Resource
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />;
        case 2:
          return <CategoryAttribute
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}/>;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  if (isLoadingCategories) return <div>Loading...</div>;
  if (errorCategories) return <div>Error! {errorCategories.message}</div>;

  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">

        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <form onSubmit={handleSubmit}>
            <div className="rounded-[20px]">
              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </div>
            {/* navigation button */}
            <StepperControl
              handleClick={handleClick}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              steps={steps}
            />
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Form;