import React, { useState, useEffect } from 'react';
import { useFetchData } from "services/queries";
import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import ValueAttributes from '../ValueAttributes';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const CategoryAttribute = ({ formData }) => {
  const resourcePath = 'product/attribute/category-attribute';
  const relatedResources = `product/attribute/category-attribute/?category=${formData.id}`;
  const { data: categoriesAttributes, isLoading: isLoadingCategoriesAttributes, error: errorCategoriesAttributes, refetch: refetchCategoriesAttributes } = useFetchData(`product/attribute/category-attribute/?category=${formData.id}`, true);

  const [tableData, setTableData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('1');
  const [selectedAttribute, setSelectedAttribute] = useState(0);

  useEffect(() => {
    refetchCategoriesAttributes();
  }, [tableData]);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      type: 'string',
      flex: 1
    },
  ];

  const actions = {
    create: true,
    edit: true,
    delete: true
  };
  const newRowTemplate = {
    category: formData.id,
    name: ''
  };

  if ( isLoadingCategoriesAttributes) return 'Loading...';
  if ( errorCategoriesAttributes) return 'Error...';

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAttributeChange = (event, newValue) => {
    setSelectedAttribute(newValue);
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <Typography variant='h5' sx={{fontWeight:'bold', paddingTop: '5px'}} className="text-navy-700 dark:text-white"> 
        Attributes  
      </Typography>
      <Typography variant='subtitle2' sx={{paddingTop: '5px'}} className="text-navy-700 dark:text-white"> 
        Here you can manage the attributes and associated values.
      </Typography>

      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '20px' }}>
          <TabList onChange={handleTabChange} aria-label="horizontal tabs example">
            <Tab label="Attributes" value="1" />
            <Tab label="Values" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <EditableDataGrid
            columns={columns}
            resourcePath={resourcePath}
            tableData={tableData}
            setTableData={setTableData}
            relatedResources={relatedResources}
            actions={actions}
            autosizeOnMount={true}
            newRowTemplate={newRowTemplate}
            pagination={false}
          />
        </TabPanel>
        <TabPanel value="2">
          {categoriesAttributes.length > 0 && (
            <Box sx={{ display: 'flex' }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedAttribute}
                onChange={handleAttributeChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {categoriesAttributes.map((item, index) => (
                  <Tab
                    key={item.id}
                    label={item.name}
                    value={index}
                  />
                ))}
              </Tabs>
              <Box sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant="h6" className="text-xl font-bold text-navy-700 dark:text-white">
                  Attributes Values
                </Typography>
                <Typography variant="subtitle2" sx={{paddingTop: '5px'}} className="text-navy-700 dark:text-white">
                  Manage the values for the selected attribute.
                </Typography>
                <ValueAttributes category_attribute={categoriesAttributes[selectedAttribute]} />
              </Box>
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default CategoryAttribute;