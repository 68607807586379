import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "config";

const BankDetails = ({ formData, setFormData, handleInputChange }) => {
    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Shipper
            </h4>

            {/* content */}
            {/* inputs */}
            {/* help me add  bank_name, bank_address, swift_code, account_number, beneficiary_name, beneficiary_address with a good ux here*/}
            <div className="mt-6 grid grid-cols-1 gap-3">
                <div className="col-span-2 md:col-span-1">
                    <InputField
                        extra="mb-4"
                        label="Bank Name"
                        placeholder="eg. Boyle and Sons LTDA"
                        id="bank_name"
                        type="text"
                        value={formData.bank_name}
                        onChange={handleInputChange}
                    />
                    <InputField
                        extra="mb-4"
                        label="Bank Address"
                        placeholder="eg. Boyle and Sons LTDA"
                        id="bank_address"
                        type="text"
                        value={formData.bank_address}
                        onChange={handleInputChange}
                    />
                    <InputField
                        extra="mb-4"
                        label="Swift Code"
                        placeholder="eg. Boyle and Sons LTDA"
                        id="swift_code"
                        type="text"
                        value={formData.swift_code}
                        onChange={handleInputChange}
                    />
                    <InputField
                        extra="mb-4"
                        label="Account Number"
                        placeholder="eg. Boyle and Sons LTDA"
                        id="account_number"
                        type="text"
                        value={formData.account_number}
                        onChange={handleInputChange}
                    />
                    <InputField
                        extra="mb-4"
                        label="Beneficiary Name"
                        placeholder="eg. Boyle and Sons LTDA"
                        id="beneficiary_name"
                        type="text"
                        value={formData.beneficiary_name}
                        onChange={handleInputChange}
                    />
                    <InputField
                        extra="mb-4"
                        label="Beneficiary Address"
                        placeholder="eg. Boyle and Sons LTDA"
                        id="beneficiary_address"
                        type="text"
                        value={formData.beneficiary_address}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        </div >
    );
};

export default BankDetails;
