import { MdEdit } from "react-icons/md";
import Content from "./Content";
import Card from "components/card";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import useAuth from "hooks/useAuth";

const Receipt = ({ order }) => {
  const resource = 'order/sales-order';
  const formattedDate = dayjs(order.created_at).format('MMMM DD, YYYY [at] hh:mm A');
  const { auth, authLoading } = useAuth();
  if (authLoading) return <div>Loading...</div>;

  return (
    <Card extra={"w-full h-full px-6 py-6"}>
      {/* Banner */}
      <div
        className="flex w-full items-center justify-between rounded-2xl bg-gradient-to-br from-brandLinear to-blueSecondary bg-cover bg-no-repeat px-6 pb-10 pt-11 md:!h-[180px] md:items-start md:pl-[50px] md:pr-[57px] 2xl:!h-[190px]"
      >
        <div className="">
          <h2 className="text-sm font-bold text-white sm:text-lg md:text-[30px] xl:!text-[40px] 2xl:pt-3">
            Order {order.order_identifier}
          </h2>
          <p className="text-xs text-white sm:text-sm md:mt-1 md:text-xl xl:!text-xl 2xl:pt-1">
            {formattedDate}
          </p>
        </div>
        {/* buttons */}
        {auth?.groups?.find(role => ["Administrator", "Staff"]?.includes(role))
          ? <div className="mt-[13px] flex gap-[12px] md:mt-0">
            <Link to={`/admin/${resource}/edit/${order.id}`}>
              <button className="flex items-center justify-center rounded-lg bg-brandLinear p-2 text-xl text-white transition-opacity hover:cursor-pointer hover:opacity-80">
                <MdEdit />
              </button>
            </Link>
          </div>
          : null
        }
      </div>
      {/* content */}
      <div className="h-full w-full">
        <Content order={order} />
      </div>
    </Card>
  );
};

export default Receipt;
