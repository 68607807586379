import React, { useEffect, useState } from "react";
import { useFetchData } from 'services/queries';
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { GridActionsCellItem } from '@mui/x-data-grid';
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import { BsFillEyeFill } from "react-icons/bs";
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import ToolbarCrud from 'components/toolbar/ToolbarCrud';

const ListObjects = () => {
    const resource = 'shipper';
    const resource_name = 'Shipper';
    const { data: data, error: errorData, isLoading: isLoadingData } = useFetchData(resource, true);
    const { auth, authLoading } = useAuth();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);


    const columnsData = [
        {
            field: 'fantasy_name',
            headerName: 'Fantasy Name',
            width: 400,
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'string',
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'string',
        },
        {
            field: 'telephone',
            headerName: 'Phone',
            width: 150,
            editable: false,
            groupable: false,
            type: 'string',
            aggregable: false,
        },

        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            editable: false,
            groupable: false,
            aggregable: false,
            visibleTo: ['Administrator', 'Staff'],
            getActions: (params) => {
                const { id } = params.row;
                return [
                    <GridActionsCellItem
                        icon={<BsPencilSquare />}
                        color="primary"
                        label="Edit"
                        component={Link}
                        to={`/admin/${resource}/edit/${id}`}
                    />,
                    <GridActionsCellItem
                        color="primary"
                        icon={<BsTrash />}
                        label="Delete"
                        onClick={() => {
                            setSelectedItem(params.row.id); // Set the selected item
                            setOpenDeleteDialog(true); // Open the dialog
                        }}
                    />,
                ];
            }
        },
    ];
    const columns = useRoleBasedColumns(columnsData);

    if (isLoadingData | authLoading) return <div>Loading...</div>;
    if (errorData) return <div>Error! {errorData.message}</div>;

    return (
        <Card extra={'w-full h-full bg-white mt-3 p-3'}>
            <Box>
                <DataGridPremium
                    rows={data}
                    columns={columns.map(column => ({
                        ...column,
                        editable: false
                    }))}
                    initialState={{
                        density: 'comfortable',
                        pinnedColumns: { left: ['actions'] },
                    }}
                    slots={{
                        toolbar: () => <ToolbarCrud
                            resource={resource}
                            name={resource_name} />
                    }}


                />
            </Box>
            <DeleteConfirmDialog
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                selectedItem={selectedItem}
                resource={resource}
            />
        </Card>

    )
}

export default ListObjects