import DynamicFormField from "components/form/DynamicFormField";
import dayjs from 'dayjs';
import { useFetchData } from "services/queries";
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect } from 'react';
import getInputChange from "utils/handleInputChange";
import { useState } from "react";
import { useUpdateData, useFetchDataId, useCreateData } from "services/queries";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useMediaQuery from '@mui/material/useMediaQuery';

const OrderInfoClient = () => {
    const simple_resource_path = 'order/simple-sales-order';
    const resource_path = 'order/sales-order';
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const navigate = useNavigate();
    const { data: clients, isLoading: isLoadingClients, error: errorClients } = useFetchData('client', true);

    const { data: statusSalesOrder, isLoading: isLoadingStatusSalesOrder, error: errorStatusSalesOrder } = useFetchData('order/status-sales-order', true);
    const { data: order, error: errorOrder, isLoading: isLoadingOrder, refetch: refetchOrder } = useFetchDataId(simple_resource_path, id, true);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const updateOrder = useUpdateData(`${resource_path}`);
    const createOrder = useCreateData(`${resource_path}`);

    const [orderStatus, setOrderStatus] = useState('Open');


    const [formData, setFormData] = useState({});
    const handleInputChange = (e) => (getInputChange(e, formData, setFormData));


    useEffect(() => {
        setFormData(() => order);
    }, [order]);



    const handleSave = () => {
        isEditMode ? updateOrder.mutate({ id, newData: formData }) : createOrder.mutate(formData);
    };

    useEffect(() => {
        if (createOrder.isSuccess) {
            // navigate("/admin/order/sales-order/tracking/");
            navigate(`/admin/order/sales-order/edit/${createOrder.data.id}`)
        }
        if (updateOrder.isSuccess) {
            refetchOrder();
        }
    }, [updateOrder.isSuccess, createOrder.isSuccess, navigate, createOrder.data?.id, isEditMode]);


    useEffect(() => {
        if (statusSalesOrder && order) {
            const status = statusSalesOrder.find(status => status.id === order?.status_id)?.status || 'Open'
            setOrderStatus(() => status);
        }
    }, [statusSalesOrder, order]);


    if (isLoadingClients || isLoadingStatusSalesOrder || isLoadingOrder) {
        return <div>Loading...</div>;
    }
    if (errorClients || errorStatusSalesOrder || errorOrder) {
        return <div>Error...</div>;
    }

    if (orderStatus !== "Open" && orderStatus) {
        toast("You cannot change this order anymore.", { id: 'toast-warning' })
    }

    const formFields = [
        {
            id: "issue_date",
            label: "Issue Date",
            type: "date",
            disabled: true,
            default: dayjs(new Date()).format("YYYY-MM-DD"),
            mdColSpan: 6,
            handleInputChange,
            value: formData?.issue_date
        },
        {
            id: "customer_ref",
            label: "Customer Reference",
            type: "text",
            mdColSpan: 6,
            handleInputChange,
            value: formData?.customer_ref,
            // if orderStatus is not "Open" disable the field
            disabled: orderStatus !== "Open"
        },
        {
            id: "buyer_id",
            label: "Client",
            type: "select",
            options: clients?.map(client => ({ value: client.id, label: client.social_reason })),
            mdColSpan: 12,
            handleInputChange,
            value: formData?.buyer_id,
            disabled: orderStatus !== "Open"
        },
        {
            id: "status_id",
            label: "Order Status",
            type: "select",
            options: statusSalesOrder
                .filter(status => ["Open", "Ready for Processing"].includes(status.status))
                .map(status => ({ value: status.id, label: status.status })),
            mdColSpan: 12,
            handleInputChange,
            value: formData?.status_id,
            disabled: orderStatus !== "Open"
        },

    ];

    return (
        <Grid container spacing={2} margin={2} >
            {/* Header */}
            <Grid container display="flex" justifyContent="space-between" xs={12}>
                <Grid>
                    <h4 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">
                        Order Info
                    </h4>
                </Grid>
                {orderStatus === 'Open' && (
                    <Grid>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                )}

            </Grid>
            {/* content */}
            {/* inputs */}
            <Grid container display="flex" spacing={2} xs={12}>
                {formFields.map(field => {
                    return (
                        <DynamicFormField
                            key={field.id}
                            field={field}
                            formData={formData}
                            handleInputChange={handleInputChange}
                        />
                    );
                })}
            </Grid>

        </Grid >


    );
}

export default OrderInfoClient