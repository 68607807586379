// src/services/api.js
import axios from 'axios';
import { API_BASE_URL } from 'config';

export const createAPI = (authToken, refreshAccessToken) => {
    const api = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
        },
    });

    api.interceptors.request.use((config) => {
        // Only set 'Content-Type' to 'application/json' if not already set (to handle FormData properly)
        if (config.headers['Content-Type'] === undefined) {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    });

    api.interceptors.response.use(undefined, async (error) => {
        if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            const newToken = await refreshAccessToken();
            if (newToken) {
                error.config.__isRetryRequest = true;
                error.config.headers.Authorization = `Bearer ${newToken}`;
                return api(error.config);
            }
        }

        return Promise.reject(error);
    });

    return api;
};
