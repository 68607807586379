import { useState } from 'react';

const PhotoGallery = ({ picture }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="rounded-xl w-fit max-h-[250px] xl:max-h-[250px]">
        <img
          src={picture}
          className="w-full h-full object-cover rounded-xl"
          alt="img"
        />
      </div>
    </div>
  );
};

export default PhotoGallery;
