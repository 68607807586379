import { useDropzone } from "react-dropzone";
// Assets
import React, { useState, useEffect } from "react";

const DropZonefile = ({ content, formData, handleImageUpload }) => {
  const [file, setFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      handleImageUpload(acceptedFiles[0]);
    },
  });

  return (
    <div
      className="flex h-full !w-[700px] cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700"
      {...getRootProps({ className: "dropzone" })}
    >
      <input {...getInputProps()} />
      {formData.picture ? (
        <img
          src={typeof formData.picture === "string" ? formData.picture : URL.createObjectURL(formData.picture)}
          alt="Uploaded"
          name="picture"
          className="max-h-[200px] max-w-[200px] rounded-sm"
        />
      ) : (
        <button type="button" className="h-full w-full">{content}</button>
      )}
    </div>
  );
};

export default DropZonefile;
