import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import React, { useState } from 'react';
import { Box } from '@mui/material';

const ValueAttributes = ({category_attribute}) => {
  const resourcePath = 'product/attribute/attribute-value';
  const relatedResources = `product/attribute/attribute-value/?category_attribute=${category_attribute.id}`;
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      field: 'value',
      headerName: 'Value Options',
      editable: true,
      type: 'string',
      flex: 1,
    },
  ];

  const actions = {
    create: true,
    edit: true,
    delete: true
};
const newRowTemplate = {
  category_attribute: category_attribute.id,
  value: ''
};

  return (
    <Box className="h-full w-full rounded-[20px] pt-7 ">
        <Box>
          <EditableDataGrid
            columns={columns}
            resourcePath={resourcePath}
            tableData={tableData}
            setTableData={setTableData}
            relatedResources={relatedResources}
            actions={actions}
            autosizeOnMount={true}
            newRowTemplate={newRowTemplate}
            pagination={false} 
          />
      </Box>
    </Box>
  )
}

export default ValueAttributes;