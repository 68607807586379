import React from "react";
import { useFetchData } from 'services/queries';
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { GridActionsCellItem } from '@mui/x-data-grid';
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import ToolbarCrud from 'components/toolbar/ToolbarCrud';

const ListObjects = () => {
  const resource = 'product/category';
  const resource_name = 'Category';
  const { data: data, error: errorData, isLoading: isLoadingData } = useFetchData(resource, true);
  const { auth, authLoading } = useAuth();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const columnsData = [
    {
      field: 'name',
      headerName: 'NAME',
      width: 300,
      editable: false,
      groupable: false,
      type: 'string',
      aggregable: false,
    },
    {
      field: 'value',
      headerName: 'Code',
      width: 100,
      editable: false,
      groupable: false,
      type: 'string',
      aggregable: false,
    },
    {
      field: 'parent_name_path',
      headerName: 'Hierarchy',
      width: 500,
      editable: false,
      groupable: false,
      type: 'string',
      aggregable: false,
    },
    {
      field: 'value_path',
      headerName: 'ID Hierarchy',
      width: 200,
      editable: false,
      groupable: false,
      type: 'string',
      aggregable: false,
    },
    {
      field: 'name_path',
      headerName: 'Full Path',
      width: 300,
      editable: false,
      groupable: false,
      type: 'string',
      aggregable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      groupable: false,
      aggregable: false,
      visibleTo: ['Administrator', 'Staff'],
      getActions: (params) => {
        const { id } = params.row;
        return [
          <GridActionsCellItem
            icon={<BsPencilSquare />}
            color="primary"
            label="Edit"
            component={Link}
            to={`/admin/${resource}/edit/${id}`}
          />,
          <GridActionsCellItem
            color="primary"
            icon={<BsTrash />}
            label="Delete"
            onClick={() => {
              setSelectedItem(params.row.id);
              setOpenDeleteDialog(true);
            }}
          />,
        ];
      }
    },
  ];
  const columns = useRoleBasedColumns(columnsData);

  if (isLoadingData | authLoading) return <div>Loading...</div>;
  if (errorData) return <div>Error! {errorData.message}</div>;

  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box>
        <DataGridPremium
          rows={data}
          columns={columns.map(column => ({
            ...column,
            editable: false
          }))}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            density: 'comfortable',
            pinnedColumns: { left: ['actions'] },
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          slots={{
            toolbar: () => <ToolbarCrud
              resource={resource}
              name={resource_name} />
          }}
        />
      </Box>
      <DeleteConfirmDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedItem={selectedItem}
        resource={resource}
      />
    </Card>
  )
}

export default ListObjects