import { useState, useEffect } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import EditableDataGrid from 'components/EditableDataGrid/EditableDataGrid';
import { useFetchData, useCreateData } from 'services/queries';
import { Chip } from '@mui/material';

const ContainerTrackingTable = ({
  salesOrderFormData,
  deliveryInfo,
  updateDeliveryInfo,
  refetchDeliveryInfo
}) => {
  const resourcePath = 'delivery/delivery-container';
  const relatedResources = `delivery/delivery-container/?delivery_information=${deliveryInfo?.id}`;

  const {
    data: deliveryContainer,
    isLoading: isLoadingDeliveryContainer,
    error: errorDeliveryContainer,
  } = useFetchData('delivery/delivery-container', true);
  const {
    data: locations,
    isLoading: isLoadingLocations,
    error: errorLocations,
  } = useFetchData('delivery/location', true);
  const {
    data: ports,
    isLoading: isLoadingPorts,
    error: errorPorts,
    refetch: refetchPorts,
  } = useFetchData('delivery/port', true);
  const {
    data: shippingLines,
    isLoading: isLoadingShippingLines,
    error: errorShippingLines,
    refetch: refetchShippingLines,
  } = useFetchData('delivery/shipping-line', true);
  const {
    data: moveStatus,
    isLoading: isLoadingMoveStatus,
    error: errorMoveStatus,
  } = useFetchData('delivery/move-status', true);
  const {
    data: statuses,
    isLoading: isLoadingStatuses,
    error: errorStatuses,
  } = useFetchData('delivery/status', true);
  const {
    data: vessel,
    isLoading: isLoadingVessel,
    error: errorVessel,
    refetch: refetchVessel,
  } = useFetchData('delivery/vessel', true);
  const {
    data: voyage,
    isLoading: isLoadingVoyage,
    error: errorVoyage,
    refetch: refetchVoyage,
  } = useFetchData('delivery/voyage', true);
  const {
    data: transshipmentPorts,
    isLoading: isLoadingTransshipmentPorts,
    error: errorTransshipmentPorts,
  } = useFetchData('delivery/transshipment-port', true);
  const {
    data: containers,
    isLoading: isLoadingContainers,
    error: errorContainers,
    refetch: refetchContainers,
  } = useFetchData('delivery/container', true);

  const deliveryInfoId = deliveryInfo?.id;

  const [tableData, setTableData] = useState([]);
  const apiRef = useGridApiRef();

  const actions = {
    create: true,
    edit: true,
    delete: true,
    export: false,
    search: false,
  };
  const newRowTemplate = {
    container: '',
    delivery_information: deliveryInfoId,
    isNew: true,
  };

  useEffect(() => {
    refetchContainers();
    refetchPorts();
    refetchShippingLines();
    refetchVessel();
    refetchVoyage();
    refetchDeliveryInfo();
  }, [tableData]);

  // Definindo a função getColorByStatusName
  const getColorByStatusName = (statusName) => {
    const colors = {
      Untracked: 'error',
      Booked: 'default',
      Arrived: 'primary',
      Sailing: 'secondary',
      Loaded: 'primary',
      Discharged: 'success',
      'In Progress': 'warning',
    };
    return colors[statusName] || 'default';
  };

  if (
    isLoadingLocations ||
    isLoadingPorts ||
    isLoadingShippingLines ||
    isLoadingMoveStatus ||
    isLoadingStatuses ||
    isLoadingVessel ||
    isLoadingVoyage ||
    isLoadingTransshipmentPorts ||
    isLoadingContainers ||
    isLoadingDeliveryContainer
  )
    return <p>Loading...</p>;
  if (
    errorLocations ||
    errorPorts ||
    errorShippingLines ||
    errorMoveStatus ||
    errorStatuses ||
    errorVessel ||
    errorVoyage ||
    errorTransshipmentPorts ||
    errorContainers ||
    errorDeliveryContainer
  )
    return <p>Error...</p>;

  const columns = [
    // container_number
    {
      field: 'container',
      headerName: 'Container',
      editable: true,
      minWidth: 130,
      flex: 1,
    },
    // first_teu_type
    {
      field: 'first_teu_type',
      headerName: '',
      editable: false,
      type: 'date',
      minWidth: 60,
      disableExport: true,
      renderCell: (params) => {
        const containerTeu = containers.find(
          (container) => container.container_number === params.row.container
        )?.container_teu;
        if (!containerTeu) return '';
        const containerType = containers.find(
          (container) => container.container_number === params.row.container
        )?.container_type;
        return (
          <div>
            <span
              style={{
                backgroundColor: '#6d757c',
                color: 'white',
                padding: 5,
                borderRadius: 3,
                fontSize: 11,
              }}
            >
              <span>{containerTeu}</span>{' '}
              <span style={{ marginLeft: 8 }}>{containerType}</span>
            </span>
          </div>
        );
      },
    },
    // current_status
    {
      field: 'current_status',
      headerName: 'Current Status',
      editable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        if (!container) return '';
        const status = moveStatus.find(
          (status) => status.id === container.current_status
        );
        return status?.name;
      },
      renderCell: (params) => {
        const container = containers.find(
          (container) => container.container_number === params.row.container
        );
        if (!container) return '';
        const status = moveStatus.find(
          (status) => status.id === container.current_status
        );
        return status ? (
          <Chip
            label={status.name}
            color={getColorByStatusName(status.name)}
            sx={{
              textAlign: 'center',
              borderRadius: '3px',
              width: '100%',
            }}
          />
        ) : (
          ''
        );
      },
    },
    // first_eta
    {
      field: 'first_eta',
      headerName: 'First ETA',
      editable: false,
      type: 'date',
      minWidth: 115,
      valueGetter: (value, row) => {
        const first_eta = containers.find(
          (container) => container.container_number === row.container
        )?.first_eta;
        return first_eta ? new Date(first_eta + 'T12:00:00') : null;
      },
    },
    // eta
    {
      field: 'eta',
      headerName: 'ETA',
      editable: false,
      type: 'date',
      minWidth: 115,
      valueGetter: (value, row) => {
        const eta = containers.find(
          (container) => container.container_number === row.container
        )?.eta;
        return eta ? new Date(eta + 'T12:00:00') : null;
      },
    },
    // schedule_ajustment
    {
      field: 'schedule_ajustment',
      headerName: 'Schedule Adjustment',
      editable: false,
      minWidth: 170,
      valueGetter: (value, row) => {
        const schedule_ajustment = containers.find(
          (container) => container.container_number === row.container
        )?.schedule_ajustment;
        return schedule_ajustment ? schedule_ajustment : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.shipping_line.name
    {
      field: 'shipping_line',
      headerName: 'Shipping Line',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const shipping_line_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.shipping_line;
        const shipping_line_name = shippingLines.find(
          (shippingLine) => shippingLine.id === shipping_line_id
        )?.name;
        return shipping_line_name ? shipping_line_name : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.vessel.imo
    {
      field: 'vessel_imo',
      headerName: 'Vessel IMO',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel;
        const vessel_imo = vessel.find(
          (vessel) => vessel.id === vessel_id
        )?.imo;
        return vessel_imo ? vessel_imo : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.vessel.name
    {
      field: 'vessel_name',
      headerName: 'Vessel',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel;
        const vessel_name = vessel.find(
          (vessel) => vessel.id === vessel_id
        )?.name;
        return vessel_name ? vessel_name : '';
      },
    },

    // container.main_voyage = voyage.id -> show voyage.vessel_voyage
    {
      field: 'main_voyage',
      headerName: 'Main Voyage',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_voyage = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel_voyage;
        return vessel_voyage ? vessel_voyage : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.from_port
    {
      field: 'from_port',
      headerName: 'From Port',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const from_port_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.from_port;
        const from_port = ports.find((port) => port.id === from_port_id)?.name;
        return from_port ? from_port : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.from_country
    {
      field: 'from_country',
      headerName: 'From Country',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const from_country = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.from_country;
        return from_country ? from_country : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.to_port
    {
      field: 'to_port',
      headerName: 'To Port',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const to_port_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.to_port;
        const to_port = ports.find((port) => port.id === to_port_id)?.name;
        return to_port ? to_port : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.to_country
    {
      field: 'to_country',
      headerName: 'To Country',
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const to_country = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.to_country;
        return to_country ? to_country : '';
      },
    },
    // container_teu
    {
      field: 'container_teu',
      headerName: 'TEU',
      editable: false,
      valueGetter: (value, row) => {
        const container_teu = containers.find(
          (container) => container.container_number === row.container
        )?.container_teu;
        return container_teu ? container_teu : '';
      },
    },
    // container_type
    {
      field: 'container_type',
      headerName: 'Container Type',
      editable: false,
      valueGetter: (value, row) => {
        const container_type = containers.find(
          (container) => container.container_number === row.container
        )?.container_type;
        return container_type ? container_type : '';
      },
    },
  ];

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-2">
      <Box>
        <EditableDataGrid
          columns={columns}
          resourcePath={resourcePath}
          tableData={tableData}
          setTableData={setTableData}
          relatedResources={relatedResources}
          apiRef={apiRef}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          actions={actions}
          autosizeOptions={{
            includeOutliers: true,
            includeHeaders: true,
          }}
          autosizeOnMount
          newRowTemplate={newRowTemplate}
        />
      </Box>
    </div>
  );
};

export default ContainerTrackingTable;