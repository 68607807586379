import React from 'react'
import { useFetchData } from 'services/queries';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import Grid from '@mui/material/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import PieChart from 'components/pieChart/PieChart';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const OrderStatus = (props) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { startDate, endDate } = props;
    let params_url = ''
    if (startDate || endDate) {
        params_url = `?issue_date__gte=${startDate}&issue_date__lte=${endDate}`
    }
    const { data: dataOrderCountByStatus, error: errorDataOrderCountByStatus, isLoading: isLoadingDataOrderCountByStatus } = useFetchData(`order/sales-order/get-order-count-by-status/${params_url}`, true);

    if (isLoadingDataOrderCountByStatus) {
        return <div>Loading...</div>
    }
    if (errorDataOrderCountByStatus) {
        return <div>Error</div>
    }
    const columns = [
        { field: 'status', headerName: 'Status', flex: 1 },
        { field: 'count', headerName: 'Qt' },
        {
            field: 'percentage',
            headerName: 'Pct (%)',
            valueGetter: (value) => `${value.toFixed(2)}%`,
        },
    ];
    const totalOrderCount = dataOrderCountByStatus.reduce((acc, data) => acc + data.count, 0);
    const rows = dataOrderCountByStatus.map((data) => ({
        id: data.status__status,
        status: data.status__status,
        count: data.count,
        percentage: (data.count / totalOrderCount) * 100,
    }));
    const chartData = rows.map((data, index) => {
        return {
            id: index,
            name: data.status,
            value: data.count,
        }
    })

    console.log("dataOrderCountByStatus", dataOrderCountByStatus)

    return (

        <Grid container spacing={isXs ? 1 : 2} justifyContent="space-around" alignItems="center" padding={1}>

            <Grid item xs={12} md={5} padding={3} order={{ xs: 2, md: 1 }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    pagination
                    density='compact'
                    autoHeight
                    disableSelectionOnClick
                    autosizeOptions={{
                        columns: ['count', 'status', 'percentage'],
                        includeOutliers: true,
                        includeHeaders: true,
                    }}
                    autosizeOnMount
                    hideFooter
                />
            </Grid>
            <Grid item xs={12} md={6} padding={1} order={{ xs: 1, md: 2 }}>
                {isMdDown && (
                    <Typography variant="h6" align="center">
                        Order per Status
                    </Typography>
                )}
                <PieChart
                    data={chartData}
                />
            </Grid>
        </Grid>

    )
}

export default OrderStatus