export const withLoadingAndAlert = (setLoading, setNotification, asyncFunction) => {
    return async function (...args) {
        setLoading(true);
        try {
            const result = await asyncFunction(...args);
            if (result.status_code < 400) {
                setLoading(false);
                return result;
            }
            else if (result.status_code >= 400) {
                setLoading(false);
                throw result;
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            let response = null;
            try {
                response = await error.json();
            }
            catch (e) {
                response = error;
            }
            try {
                console.error(response);
                if (response && response.code == 'server_error') {
                    if (response.hasOwnProperty('error')) {
                        if (response.error.hasOwnProperty('message')) {
                            if (Array.isArray(response.error.message)) {
                                const errorMessages = response.error.message;
                                const errorList = errorMessages.map((errorMessage, index) => (
                                    <li key={index}>{errorMessage}</li>
                                ));
                                setNotification({
                                    title: 'Error',
                                    description: <ul>{errorList}</ul>,
                                    display: true,
                                    type: 'error'
                                });
                            }
                            else {

                                setNotification({
                                    title: 'Error',
                                    description: response.error.message,
                                    display: true,
                                    type: 'error'
                                });
                            }
                        }
                    }
                    else {
                        setNotification({ title: 'Error', description: response.error.message.toString(), display: true, type: 'error' });
                    }
                }
                else if (response.errorMessage) {
                    setNotification({ title: 'Error', description: response.errorMessage, display: true, type: 'error' });
                }
                else if (response.hasOwnProperty('error')) {
                    console.log("error property");
                    setNotification({ title: 'Error', description: response.error, display: true, type: 'error' });
                }
                else if (typeof response === 'object') {
                    let fullErrorList = [];
                    const key_errors = Object.fromEntries(
                        Object.entries(response).filter(([key]) => key !== 'status_code')
                    );
                    console.log(key_errors);
                    for (const field in key_errors) {
                        const fieldErrors = key_errors[field];
                        if (Array.isArray(fieldErrors)) {
                            const errorList = fieldErrors.map((fieldErrorMessage, index) => (
                                <li key={index}>{field}: {fieldErrorMessage}</li>
                            ));
                            fullErrorList = fullErrorList.concat(errorList);
                        } else if (typeof fieldErrors === 'object') {
                            for (const subfield in fieldErrors) {
                                const subfieldErrors = fieldErrors[subfield];
                                const errorList = subfieldErrors.map((subfieldErrorMessage, index) => (
                                    <li key={index}>{subfield}: {subfieldErrorMessage}</li>
                                ));
                                fullErrorList = fullErrorList.concat(errorList);
                            }
                        }
                    }
                    setNotification({
                        title: 'Error',
                        description: <ul>{fullErrorList}</ul>,
                        display: true,
                        type: 'error'
                    });
                }
                else {
                    setNotification({ title: 'Error', description: 'Something went wrong', display: true, type: 'error' });
                }
            } catch (e) {
                console.error(e);
                setNotification({ title: 'Error', description: 'Something went wrong', display: true, type: 'error' });
            }
            setLoading(false);
            return error
        }
        setLoading(false);
    };

};


export const withLoading = (setLoading, asyncFunction) => {
    return async function (...args) {
        setLoading(true);
        try {
            const result = await asyncFunction(...args);
            setLoading(false);
            return result;
        } catch (error) {
            setLoading(false);
            console.error(error);
            throw error;

        }
    };
};