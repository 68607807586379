import DynamicFormField from "components/form/DynamicFormField";
import Grid from '@mui/material/Unstable_Grid2';
import { SUPPLIER_TYPE_CHOICES } from "constants";

const Resource = ({ formData, setFormData, handleInputChange }) => {
  const formFields = [
    {
      id: "name",
      label: "Name",
      placeholder: "eg. Boyle and Sons LTDA",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.name

    },
    {
      id: "email",
      label: "Email",
      placeholder: "eg. email@email.com",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.email
    },
    {
      id: "phone_number",
      label: "Phone",
      placeholder: "eg. +55 11 99999-9999",
      type: "tel",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.phone_number
    },
    {
      id: 'category',
      label: 'Type',
      type: 'select',
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.category || 'FACTORY',
      options: SUPPLIER_TYPE_CHOICES
    },
    {
      id: "wechat",
      label: "WeChat",
      placeholder: "eg. https://...",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.wechat
    }
  ];




  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Supplier
      </h4>

      {/* content */}
      {/* inputs */}
      <Grid container spacing={2}>
        {formFields.map(field => {
          return (
            <DynamicFormField
              key={field.id}
              field={field}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          );
        })}

      </Grid>
    </div >
  );
};

export default Resource;
