import { useState } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import EditableDataGrid from 'components/EditableDataGrid/EditableDataGrid';
import { useFetchData } from 'services/queries';
import Card from 'components/card';
import { Chip, Link } from '@mui/material';
import { IconButton, Modal } from '@mui/material';
import MovesGrid from '../MovesGrid';
import Badge from '@mui/material/Badge';
import useAuth from 'hooks/useAuth';



const ListObjects = ({ formData }) => {
  const { auth } = useAuth();
  const resourcePath = 'delivery/delivery-container';
  const relatedResources = `delivery/delivery-container`;

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const {
    data: locations,
    isLoading: isLoadingLocations,
    error: errorLocations,
  } = useFetchData('delivery/location', true);
  const {
    data: ports,
    isLoading: isLoadingPorts,
    error: errorPorts,
    refetch: refetchPorts,
  } = useFetchData('delivery/port', true);
  const {
    data: shippingLines,
    isLoading: isLoadingShippingLines,
    error: errorShippingLines,
    refetch: refetchShippingLines,
  } = useFetchData('delivery/shipping-line', true);
  const {
    data: moveStatus,
    isLoading: isLoadingMoveStatus,
    error: errorMoveStatus,
  } = useFetchData('delivery/move-status', true);
  const {
    data: statuses,
    isLoading: isLoadingStatuses,
    error: errorStatuses,
  } = useFetchData('delivery/status', true);
  const {
    data: vessel,
    isLoading: isLoadingVessel,
    error: errorVessel,
    refetch: refetchVessel,
  } = useFetchData('delivery/vessel', true);
  const {
    data: voyage,
    isLoading: isLoadingVoyage,
    error: errorVoyage,
    refetch: refetchVoyage,
  } = useFetchData('delivery/voyage', true);
  const {
    data: transshipmentPorts,
    isLoading: isLoadingTransshipmentPorts,
    error: errorTransshipmentPorts,
  } = useFetchData('delivery/transshipment-port', true);
  const {
    data: containers,
    isLoading: isLoadingContainers,
    error: errorContainers,
    refetch: refetchContainers,
  } = useFetchData('delivery/container', true);
  const {
    data: orders,
    isLoading: isLoadingOrders,
    error: errorOrders,
    refetch: refetchOrders,
  } = useFetchData('order/simple-sales-order', true);

  const getColorByStatusName = (statusName) => {
    const colors = {
      Untracked: 'error',
      Booked: 'default',
      Arrived: 'primary',
      Sailing: 'secondary',
      Loaded: 'primary',
      Discharged: 'success',
      'In Progress': 'warning'
    };
    return colors[statusName] || 'default';
  };

  const [tableData, setTableData] = useState([]);
  const apiRef = useGridApiRef();

  const actions = {
    create: false,
    edit: false,
    delete: false,
    export: true,
    search: true,
  };
  const newRowTemplate = {
    container_number: '',
  };

  if (
    isLoadingLocations ||
    isLoadingPorts ||
    isLoadingShippingLines ||
    isLoadingMoveStatus ||
    isLoadingStatuses ||
    isLoadingVessel ||
    isLoadingVoyage ||
    isLoadingTransshipmentPorts ||
    isLoadingContainers ||
    isLoadingOrders
  )
    return <p>Loading...</p>;

  if (
    errorLocations ||
    errorPorts ||
    errorShippingLines ||
    errorMoveStatus ||
    errorStatuses ||
    errorVessel ||
    errorVoyage ||
    errorTransshipmentPorts ||
    errorContainers ||
    errorOrders
  )
    return <p>Error...</p>;

  const columns = [
    //Order identifier
    {
      field: 'order_id',
      headerName: 'Order',
      editable: false,
      aggregable: false,
      minWidth: 90,
      flex: 1,
      valueGetter: (value, row) => {
        const order = orders.find(
          (order) => order.delivery_info === row.delivery_information
        );
        if (!order) return null;
        return order.order_identifier;
      },
      renderCell: (params) => {
        if (params.field === 'order_id' && params.rowNode.type === 'group') {
          return '';
        }
        if (params.rowNode.type === 'group') {
          return params.rowNode.groupingKey;
        }
        const order = orders.find(
          (order) => order.delivery_info === params.row.delivery_information
        );
        if (!order) return null;
        const isClient = auth?.groups.includes("Client Administrator") || auth?.groups.includes("Client Staff");
        const linkPath = isClient ? `/admin/order/sales-order/detail/${order.id}` : `/admin/order/sales-order/edit/${order.id}`;


        return (
          <Link href={linkPath}>
            {order.order_identifier}
          </Link>
        );
      },
      groupingValueGetter: (value, row) => {
        const order = orders.find(
          (order) => order.delivery_info === row.delivery_information
        );
        if (!order) return null;
        return order.order_identifier;
      },
    },
    // container_number
    {
      field: 'container',
      headerName: 'Container Number',
      editable: false,
      aggregable: false,
      minWidth: 140,
      flex: 1,
    },
    // first_teu_type
    {
      field: 'first_teu_type',
      headerName: '',
      editable: false,
      groupable: false,
      aggregable: false,
      filterable: false,
      type: 'date',
      minWidth: 60,
      disableExport: true,
      renderCell: (params) => {
        if (params.rowNode.type === 'group') {
          return null;
        }
        const containerTeu = containers.find(
          (container) => container.container_number === params.row.container
        )?.container_teu;
        const containerType = containers.find(
          (container) => container.container_number === params.row.container
        )?.container_type;
        return (
          <div>
            <span
              style={{
                backgroundColor: '#6d757c',
                color: 'white',
                padding: 5,
                borderRadius: 3,
                fontSize: 11,
              }}
            >
              <span>{containerTeu}</span>{' '}
              <span style={{ marginLeft: 8 }}>{containerType}</span>
            </span>
          </div>
        );
      },
    },
    // current_status
    {
      field: 'current_status',
      headerName: 'Current Status',
      editable: false,
      aggregable: false,
      flex: 1,
      minWidth: 120,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const status = moveStatus.find(
          (status) => status.id === container?.current_status
        );
        return status?.name || null;
      },
      groupingValueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const status = moveStatus.find(
          (status) => status.id === container?.current_status
        );
        return status?.name || null;
      },
      renderCell: (params) => {
        if (params.field === 'current_status' && params.rowNode.type === 'group') {
          return '';
        }
        if (params.rowNode.type === 'group') {
          return params.rowNode.groupingKey;
        }
        const container = containers.find(
          (container) => container.container_number === params.row.container
        );
        const status = moveStatus.find(
          (status) => status.id === container?.current_status
        );
        return status ? (
          <Chip
            label={status.name}
            color={getColorByStatusName(status.name)}
            sx={{
              textAlign: 'center',
              borderRadius: '3px',
              width: '100%',
            }}
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'route_details',
      headerName: 'Moves',
      editable: false,
      groupable: false,
      aggregable: false,
      filterable: false,
      width: 55,
      display: 'flex',
      justifyContent: 'center',
      disableExport: true,
      renderCell: (params) => {
        if (params.rowNode.type === 'group') {
          return params.value;
        }
        const main_voyage_id = containers.find(
          (container) => container.container_number === params.row.container
        )?.main_voyage;
        // filter transshipmentPorts by container and main_voyage
        const containerTransshipmentPorts = transshipmentPorts?.filter(
          (transshipmentPort) =>
            transshipmentPort.container === params.row.container &&
            transshipmentPort.main_voyage === main_voyage_id
        );
        const displayContent = containerTransshipmentPorts && containerTransshipmentPorts.length > 0 ? containerTransshipmentPorts.length : 'D';
        return (
          <Box display="flex" justifyContent="center" minWidth="100%">
            <IconButton onClick={() => handleOpenModal(params.row)}>
              <Badge badgeContent={displayContent} color="primary" />
            </IconButton>
          </Box>
        );
      },
    },
    // first_eta
    {
      field: 'first_eta',
      headerName: 'First ETA',
      editable: false,
      groupable: false,
      type: 'date',
      minWidth: 115,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const first_eta = container?.first_eta;
        return first_eta ? new Date(first_eta + 'T12:00:00') : null;
      },
    },
    // eta
    {
      field: 'eta',
      headerName: 'ETA',
      editable: false,
      groupable: false,
      type: 'date',
      minWidth: 115,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const eta = container?.eta;
        if (!eta || isNaN(Date.parse(eta))) {
          return null;
        }
        return new Date(eta + 'T12:00:00');
      },
    },
    // schedule_ajustment
    {
      field: 'schedule_ajustment',
      headerName: 'Schedule Adjustment',
      editable: false,
      groupable: false,
      aggregable: false,
      minWidth: 170,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const schedule_ajustment = container?.schedule_ajustment;
        return schedule_ajustment ? schedule_ajustment : '';
      },
    },
    // shipping_line
    {
      field: 'shipping_line',
      headerName: 'Shipping Line',
      aggregable: false,
      editable: false,
      minWidth: 140,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const shipping_line_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.shipping_line;
        const shipping_line_name = shippingLines.find(
          (shippingLine) => shippingLine.id === shipping_line_id
        )?.name;
        return shipping_line_name ? shipping_line_name : '';
      },
    },
    // container.main_voyage = voyage.id -> show voyage.vessel.imo
    {
      field: 'vessel_imo',
      headerName: 'Vessel IMO',
      aggregable: false,
      editable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel;
        const vessel_imo = vessel.find(
          (vessel) => vessel.id === vessel_id
        )?.imo;
        return vessel_imo ? vessel_imo : '';
      },
    },
    // vessel
    {
      field: 'vessel',
      headerName: 'Vessel',
      editable: false,
      aggregable: false,
      minWidth: 170,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel;
        const vessel_name = vessel.find(
          (vessel) => vessel.id === vessel_id
        )?.name;
        return vessel_name ? vessel_name : '';
      },
    },
    // main_voyage
    {
      field: 'main_voyage',
      headerName: 'Voyage',
      editable: false,
      aggregable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_voyage = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel_voyage;
        return vessel_voyage ? vessel_voyage : '';
      },
      groupingValueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const vessel_voyage = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.vessel_voyage;
        return vessel_voyage ? vessel_voyage : '';
      }
    },
    // from_port
    {
      field: 'from_port',
      headerName: 'From Port',
      editable: false,
      aggregable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const from_port_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.from_port;
        const from_port = ports.find((port) => port.id === from_port_id)?.name;
        return from_port ? from_port : '';
      },
    },
    // from_country
    {
      field: 'from_country',
      headerName: 'From Country',
      editable: false,
      aggregable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const from_country = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.from_country;
        return from_country ? from_country : '';
      },
    },
    // to_port
    {
      field: 'to_port',
      headerName: 'To Port',
      editable: false,
      aggregable: false,
      minWidth: 130,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const to_port_id = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.to_port;
        const to_port = ports.find((port) => port.id === to_port_id)?.name;
        return to_port ? to_port : '';
      },
    },
    // to_country
    {
      field: 'to_country',
      headerName: 'To Country',
      editable: false,
      aggregable: false,
      valueGetter: (value, row) => {
        const main_voyage_id = containers.find(
          (container) => container.container_number === row.container
        )?.main_voyage;
        const to_country = voyage.find(
          (voyage) => voyage.id === main_voyage_id
        )?.to_country;
        return to_country ? to_country : '';
      },
    },
    // container_teu
    {
      field: 'container_teu',
      headerName: 'Container TEU',
      editable: false,
      aggregable: false,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const container_teu = container?.container_teu;
        return container_teu ? container_teu : '';
      },
    },
    // container_type
    {
      field: 'container_type',
      headerName: 'Container Type',
      editable: false,
      aggregable: false,
      valueGetter: (value, row) => {
        const container = containers.find(
          (container) => container.container_number === row.container
        );
        const container_type = container?.container_type;
        return container_type ? container_type : '';
      },
    },
  ];

  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box>
        <EditableDataGrid
          columns={columns}
          resourcePath={resourcePath}
          tableData={tableData}
          setTableData={setTableData}
          relatedResources={relatedResources}
          apiRef={apiRef}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ field: 'order_id', sort: 'desc' }],
            },
          }}
          autosizeOptions={{
            includeOutliers: true,
            includeHeaders: true,
          }}
          actions={actions}
          autosizeOnMount={true}
          newRowTemplate={newRowTemplate}
        />
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ width: { xs: '90%', md: '80%', lg: '60%' }, height: 'auto', maxHeight: '80vh', bgcolor: 'white', p: 4, m: 'auto', mt: '5%', overflow: 'auto' }}>
          <MovesGrid
            containerNumber={containers.find(
              (container) => container.container_number === selectedRow?.container
            )?.container_number}
            mainVoyageId={containers.find(
              (container) => container.container_number === selectedRow?.container
            )?.main_voyage}
            voyage={voyage}
          />
        </Box>
      </Modal>
    </Card>
  );
};

export default ListObjects;
