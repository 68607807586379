import React from 'react'
import { useFetchData } from 'services/queries';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from "@mui/material";
import PieChart from 'components/pieChart/PieChart';
import ProductProfitableTooltip from './ProductProfitableTooltip';
import ProductBestSellingTooltip from './ProductBestSellingTooltip';
import avatar from 'assets/img/avatars/avatar_bewise.png';


const TopProducts = (props) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { startDate, endDate } = props;
    let params_url = ''
    if (startDate || endDate) {
        params_url = `?issue_date__gte=${startDate}&issue_date__lte=${endDate}`
    }
    const { data: dataMostSoldProducts, error: errorDataMostSoldProducts, isLoading: isLoadingDataMostSoldProducts } = useFetchData(`order/sales-order/get-most-sold-products/${params_url}`, true);

    if (isLoadingDataMostSoldProducts) {
        return <div>Loading...</div>
    }
    if (errorDataMostSoldProducts) {
        return <div>Error</div>
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Product',
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'string',
            flex: 1,
            minWidth: 200,
            valueGetter: (value, row) => {
                return row?.name;
            },
            renderCell: (params) => {
                if (params.rowNode.isAutoGenerated) {
                    return null;
                }
                const { name, sku, picture } = params.row;
                return (
                    <Grid container alignItems="center" pr={2} wrap="nowrap">
                        <Grid
                            mx={0.5} marginRight={1}
                            maxWidth={45}
                            minWidth={45}
                        >
                            <img src={picture || avatar} className="w-10 h-10 object-contain drop-shadow-md" />
                        </Grid>
                        <Grid sx={{ lineHeight: 'normal' }} py={0.5}>
                            <Typography
                                variant="subtitle2"
                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                {name}
                            </Typography>
                            <Typography variant="caption">{sku}</Typography>
                        </Grid>
                    </Grid>
                );
            }
        },
        // quantity
        {
            field: 'quantity',
            headerName: 'Quantity',
            editable: false,
            groupable: false,
            aggregable: true,
            type: 'number',
            minWidth: 70,
        },
        {
            field: 'total_usd_invoice_value',
            headerName: 'Total Income ($)',
            minWidth: 150,
            editable: false,
            groupable: false,
            aggregable: true,
            type: 'number',
            valueGetter: (value, row) => {
                const total_amount = row?.total_usd_invoice_value || 0;
                return parseFloat(total_amount);
            },
            renderCell: (params) => {
                const value = params.value;
                return (
                    <div>
                        {value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                );
            }
        },
        // total_usd_cost_value
        {
            field: 'total_usd_cost_value',
            headerName: 'Cost ($)',
            editable: false,
            groupable: false,
            aggregable: true,
            type: 'number',
            valueGetter: (value, row) => {
                const total_amount = row?.total_usd_cost_value || 0;
                return parseFloat(total_amount);
            },
            renderCell: (params) => {
                const value = params.value;
                return (
                    <div>
                        {value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                );
            }
        },
        // profit
        {
            field: 'profit',
            headerName: 'Profit ($)',
            editable: false,
            groupable: false,
            aggregable: true,
            type: 'number',
            valueGetter: (value, row) => {
                const total_amount = row?.total_usd_invoice_value || 0;
                const total_cost = row?.total_usd_cost_value || 0;
                return parseFloat(total_amount - total_cost);
            },
            renderCell: (params) => {
                const value = params.value;
                return (
                    <div>
                        {value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                );
            }
        },
    ];

    const rows = Object.entries(dataMostSoldProducts)
        .map(([sku, product], index) => {
            return {
                id: index,
                name: product.name,
                picture: product.picture,
                sku: product.sku,
                quantity: product.quantity,
                total_usd_invoice_value: product.total_usd_invoice_value,
                total_usd_cost_value: product.total_usd_cost_value,
            };
        }
        );
    const totalProfit = rows.reduce((sum, row) => sum + (row.total_usd_invoice_value - row.total_usd_cost_value), 0);
    // order by profit
    rows.sort((a, b) => (b.total_usd_invoice_value - b.total_usd_cost_value) - (a.total_usd_invoice_value - a.total_usd_cost_value));
    const chartDataProfitable = rows
        .map((row) => ({
            name: row.name,
            value: row.total_usd_invoice_value - row.total_usd_cost_value,
            quantity: row.quantity,
            total_usd_invoice_value: row.total_usd_invoice_value,
            total_usd_cost_value: row.total_usd_cost_value,
            usd_margin: row.usd_margin,
            totalProfit: totalProfit,
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 5);


    const othersProfit = rows
        .slice(5)
        .reduce((sum, row) => sum + (row.total_usd_invoice_value - row.total_usd_cost_value), 0);

    // get the 5 to the end
    console.log("SLICEL: ", rows.slice(2))

    let othersQuantity = rows
        .slice(5)
        .reduce((sum, row) => sum + row.quantity, 0);

    let othersTotalInvoiceValue = rows
        .slice(5)
        .reduce((sum, row) => sum + row.total_usd_invoice_value, 0);

    let othersTotalCostValue = rows
        .slice(5)
        .reduce((sum, row) => sum + row.total_usd_cost_value, 0);



    chartDataProfitable.push({ name: 'Others', value: othersProfit, quantity: othersQuantity, total_usd_invoice_value: othersTotalInvoiceValue, total_usd_cost_value: othersTotalCostValue, totalProfit: totalProfit });

    // order by quantity
    rows.sort((a, b) => b.quantity - a.quantity);
    const total_quantity = rows.reduce((sum, row) => sum + row.quantity, 0);
    const chartDataBestSelling = rows
        .map((row) => ({
            name: row.name,
            value: row.quantity,
            profit: row.total_usd_invoice_value - row.total_usd_cost_value,
            total_usd_invoice_value: row.total_usd_invoice_value,
            total_usd_cost_value: row.total_usd_cost_value,
            usd_margin: row.usd_margin,
            total_quantity: total_quantity,
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 5);

    const restSumBestSelling = rows
        .slice(5)
        .reduce((sum, row) => sum + row.quantity, 0);

    othersQuantity = rows
        .slice(5)
        .reduce((sum, row) => sum + row.quantity, 0);

    othersTotalInvoiceValue = rows
        .slice(5)
        .reduce((sum, row) => sum + row.total_usd_invoice_value, 0);

    othersTotalCostValue = rows
        .slice(5)
        .reduce((sum, row) => sum + row.total_usd_cost_value, 0);

    chartDataBestSelling.push({ name: 'Others', value: restSumBestSelling, total_quantity: total_quantity, total_usd_invoice_value: othersTotalInvoiceValue, total_usd_cost_value: othersTotalCostValue });
    if (rows.length === 0) {
        return (
            <div className='h-100'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <Typography variant="body1" align="center">
                        No data for this period. Please try a different date range.
                    </Typography>
                </div>
            </div>
        )
    }

    return (
        <>
            <Grid container spacing={isXs ? 1 : 2} justifyContent="space-around" alignItems="center" padding={1}>
                <Grid item xs={12} md={6} padding={1} order={{ xs: 2, md: 1 }} justifyContent="center" alignItems="center">
                    {/* mui title 2 */}
                    <PieChart
                        data={chartDataProfitable}
                        title="Top 5 most profitable products"
                        CustomTooltipComponent={() => <ProductProfitableTooltip />}
                    />

                </Grid>
                <Grid item xs={12} md={6} padding={1} order={{ xs: 2, md: 1 }} justifyContent="center" alignItems="center">
                    <PieChart
                        data={chartDataBestSelling}
                        title="Top 5 best-selling products"
                        CustomTooltipComponent={() => <ProductBestSellingTooltip />}
                    />

                </Grid>
            </Grid>

            <Box padding={3} paddingTop={5}>
                <Divider variant="middle" light margin={10} />
            </Box>


            <Grid container spacing={isXs ? 1 : 2} justifyContent="space-around" alignItems="center" padding={1}>
                <Grid item xs={12} md={12} padding={3}>
                    <DataGridPremium
                        rows={rows}
                        columns={columns}
                        pagination
                        autoHeight
                        disableSelectionOnClick
                        autosizeOptions={{
                            columns: ['name', 'quantity', 'total_usd_invoice_value', 'total_usd_cost_value', 'profit'],
                            includeOutliers: true,
                            includeHeaders: true,
                        }}
                        autosizeOnMount
                        padding={2}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                            aggregation: {
                                model: {
                                    quantity: 'sum',
                                    total_usd_invoice_value: 'sum',
                                    total_usd_cost_value: 'sum',
                                    profit: 'sum',
                                },
                            },
                            // order it by quantity
                            sorting: {
                                sortModel: [{ field: 'quantity', sort: 'desc' }],
                            },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                    />
                </Grid>

            </Grid>
        </>
    )
}

export default TopProducts