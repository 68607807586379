import React from 'react';
import { Box, Typography } from '@mui/material';

const ClientIncomingTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { name, value, totalInvoiceValue } = payload[0].payload;

        const pct = (value / totalInvoiceValue) * 100;

        return (
            <Box
                sx={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '4px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '250px',
                }}
            >
                <Typography variant="body2" color="textPrimary" mb={1.5} sx={{ fontWeight: 'bold' }}>
                    {name}
                </Typography>
                <Typography variant="body1" color="textPrimary" mb={1}>
                    Invoice: {value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} | ({pct.toFixed(2)}%)
                </Typography>
            </Box>
        );
    }

    return null;
};

export default ClientIncomingTooltip;