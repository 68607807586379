import React from 'react';
import MaskedInput from 'react-text-mask';

const MaskedInputWrapper = React.forwardRef((props, ref) => {
    // Destructure props to isolate onChange and otherProps
    const { onChange, ...otherProps } = props;

    // Pass all props to MaskedInput and manually forward the ref
    return (
        <MaskedInput
            {...otherProps}
            ref={(input) => ref(input ? input.inputElement : null)}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
            guide={false}
            onChange={onChange}
        />
    );
});

export const CNPJMask = React.forwardRef((props, ref) => {
    // Simply render the wrapper component with forwarded ref
    return <MaskedInputWrapper {...props} ref={ref} />;
});
