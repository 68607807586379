import DynamicFormField from "components/form/DynamicFormField";
import { useFetchData, useUpdateData, useFetchDataId, useCreateData } from "services/queries";
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import getInputChange from "utils/handleInputChange";
import ContainerTrackingTable from "./delivery/ContainerTrackingTable";

const DELIVERY_INFO_RESOURCE_PATH = 'order/delivery-information';
const IMPORTERS_RESOURCE_PATH = 'client/importer';
const SALES_ORDER_RESOURCE_PATH = 'order/sales-order';
const DELIVERY_CONTAINER_RESOURCE_PATH = 'delivery/delivery-container';

const getFormFields = (formData, handleInputChange, importers, hasDeliveryContainers) => [
    // container_loading
    {
        id: 'container_loading',
        name: 'container_loading',
        label: 'Container Loading',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        value: formData.container_loading,
        handleInputChange: handleInputChange,
        mdColSpan: 6
    },
    // importer_id
    {
        id: 'importer_id',
        name: 'importer_id',
        label: 'Importer',
        type: 'select',
        value: formData.importer_id,
        handleInputChange: handleInputChange,
        options: importers?.map((importer) => ({ value: importer.id, label: importer.social_reason })),
        mdColSpan: 6,
    },
    // port_of_origin
    {
        id: 'port_of_origin',
        name: 'port_of_origin',
        label: 'Port of Origin',
        type: 'text',
        value: formData.port_of_origin,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
    // port_of_destination
    {
        id: 'port_of_destination',
        name: 'port_of_destination',
        label: 'Port of Destination',
        type: 'text',
        value: formData.port_of_destination,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
    // incoterms: { label: 'Incoterms', type: 'text' },
    {
        id: 'incoterms',
        name: 'incoterms',
        label: 'Incoterms',
        type: 'text',
        value: formData.incoterms,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
    },
    // origin_of_goods: { label: 'Origin of Goods', type: 'text' },
    {
        id: 'origin_of_goods',
        name: 'origin_of_goods',
        label: 'Origin of Goods',
        type: 'text',
        value: formData.origin_of_goods,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
    },
    // bl_number: { label: 'BL Number', type: 'text' },
    {
        id: 'bl_number',
        name: 'bl_number',
        label: 'BL Number',
        type: 'text',
        value: formData.bl_number,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
    },
    // vessel_name: { label: 'Vessel Name', type: 'text' },
    {
        id: 'vessel_name',
        name: 'vessel_name',
        label: 'Vessel Name',
        type: 'text',
        value: formData.vessel_name,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
    // voyage_number: { label: 'Voyage Number', type: 'text' },
    {
        id: 'voyage_number',
        name: 'voyage_number',
        label: 'Voyage Number',
        type: 'text',
        value: formData.voyage_number,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
    // container_number: { label: 'Container Number', type: 'text' },
    {
        id: 'container_number',
        name: 'container_number',
        label: 'Container Number',
        type: 'text',
        value: formData.container_number,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
    // etd: { label: 'ETD', type: 'date', dateFormat: 'yyyy-MM-dd' },
    {
        id: 'etd',
        name: 'etd',
        label: 'ETD',
        type: 'date',
        value: formData.etd,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
    // eta: { label: 'ETA', type: 'date', dateFormat: 'yyyy-MM-dd' },
    {
        id: 'eta',
        name: 'eta',
        label: 'ETA',
        type: 'date',
        value: formData.eta,
        handleInputChange: handleInputChange,
        mdColSpan: 6,
        disabled: hasDeliveryContainers,
    },
];

const Delivery = ({ formData: salesOrderFormData }) => {
    const { id: order_id } = useParams();
    const [deliveryInfoId, setDeliveryInfoId] = useState(salesOrderFormData.delivery_info);
    const isEditMode = Boolean(deliveryInfoId);
    const [formData, setFormData] = useState({});

    const {
        data: deliveryContainer,
        isLoading: isLoadingDeliveryContainer,
        error: errorDeliveryContainer,
      } = useFetchData(`${DELIVERY_CONTAINER_RESOURCE_PATH}/?delivery_information=${deliveryInfoId}`, true);

    const handleInputChange = (e) => (getInputChange(e, formData, setFormData));

    const createDeliveryInfo = useCreateData(DELIVERY_INFO_RESOURCE_PATH);
    const updateDeliveryInfo = useUpdateData(DELIVERY_INFO_RESOURCE_PATH);
    const updateOrder = useUpdateData(SALES_ORDER_RESOURCE_PATH);

    const { data: deliveryInfo, error: errorDeliveryInfo, isLoading: isLoadingDeliveryInfo, refetch: refetchDeliveryInfo } = useFetchDataId(DELIVERY_INFO_RESOURCE_PATH, deliveryInfoId, true);
    const { data: importers, error: errorImporters, isLoading: isLoadingImporters } = useFetchData(IMPORTERS_RESOURCE_PATH, null, true);

    const handleSave = () => {
        isEditMode ? updateDeliveryInfo.mutate({ id: deliveryInfoId, newData: formData }) : createDeliveryInfo.mutate(formData);
    };

    useEffect(() => {
        if (createDeliveryInfo.isSuccess) {
            updateOrder.mutate({ id: order_id, newData: { delivery_info: createDeliveryInfo.data.id } });
            setDeliveryInfoId(() => createDeliveryInfo.data.id);
        }
    }, [createDeliveryInfo.isSuccess])

    useEffect(() => {
        if (deliveryInfo) {
            setFormData(() => deliveryInfo);

        }
    }, [deliveryInfo]);

    if (isLoadingDeliveryInfo || isLoadingImporters || isLoadingDeliveryContainer) {
        return <div>Loading...</div>;
    }
    if (errorDeliveryInfo || errorImporters || errorDeliveryContainer) {
        return <div>Error: {errorDeliveryInfo.message}</div>;
    }

    const hasDeliveryContainers = deliveryContainer.length > 0;
    const formFields = getFormFields(formData, handleInputChange, importers, hasDeliveryContainers);


    return (
        <>
            <Grid container rowSpacing={0} columnSpacing={2} margin={1}>
                {/* Header */}
                <Grid container display="flex" justifyContent="space-between" xs={12} marginTop={1} marginBottom={2}>
                    <Grid>
                        <h4 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">
                            Delivery Information
                        </h4>
                    </Grid>

                    <Grid>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>

                </Grid>
                {/* content */}
                {/* inputs */}
                <Grid container spacing={2}>
                    {formFields.map(field => {
                        return (
                            <DynamicFormField
                                key={field.id}
                                field={field}
                                formData={formData}
                                handleInputChange={handleInputChange}
                            />
                        );
                    })}
                </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={2} margin={1}>
                <ContainerTrackingTable salesOrderFormData={salesOrderFormData} deliveryInfo={deliveryInfo} updateDeliveryInfo={updateDeliveryInfo} refetchDeliveryInfo={refetchDeliveryInfo} />
            </Grid>
        </>
    );
};

export default Delivery;