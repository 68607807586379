import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import OrderSale from "./components/steps/OrderSale";
import Payments from "./components/steps/Payments";
import Delivery from "./components/steps/Delivery";
import Items from "./components/steps/Items";
import PackingList from "./components/steps/PackingList";
import CommercialInvoice from "./components/steps/CommercialInvoice";
import ProductPurchaseOrder from "./components/steps/ProductPurchaseOrder";
import Card from "components/card";
import SolidSubtleAlert from "./components/SolidSubtleAlert";
import { AiFillExclamationCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useFetchDataId, useCreateData, useUpdateData } from "services/queries";
import getInputChange from "utils/handleInputChange";
import AdditionalCost from "./components/steps/AdditionalCost";
import useAuth from "hooks/useAuth";
import OrderInfoClient from "./components/steps/OrderInfoClient";


const Form = () => {
  const resource = 'order/sales-order';
  const resource_path = 'order/sales-order';
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const isEditMode = Boolean(id);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const [message, setMessage] = useState({ title: `Problem with ${resource}`, description: '', display: false });
  const navigate = useNavigate();
  const [shouldUpdateData, setShouldUpdateData] = useState(false);
  const { auth, authLoading } = useAuth();
  const [steps, setSteps] = useState([]);

  const createOrder = useCreateData(resource_path);
  const updateOrder = useUpdateData(resource_path);

  const { data: order, error: errorOrder, isLoading: isLoadingOrder } = useFetchDataId(resource_path, id, true);

  useEffect(() => {
    if (order) {
      if (order.delivery_info === null) {
        delete order.delivery_info;
      }
      setFormData(() => order);
    }
  }, [order]);

  const handleInputChange = (e) => {
    getInputChange(e, formData, setFormData)
    console.log(formData);
  };


  const getSteps = () => {
    let steps = [];

    if (auth?.groups?.find(role => ["Administrator", "Staff"].includes(role))) {
      if (id) {
        steps.push(
          { stepNo: 1, name: `Order Info` },
          { stepNo: 2, name: `Items` },
          { stepNo: 3, name: `Packing` },
          { stepNo: 4, name: `Invoice` },
          { stepNo: 5, name: `Purchase` },
          { stepNo: 6, name: `Payments` },
          { stepNo: 7, name: `Costs` },
          { stepNo: 8, name: `Delivery` }
        );
      }
      else {
        steps.push(
          { stepNo: 1, name: `Order Info` },
        );
      }
    }
    else if (auth?.groups?.find(role => ["Client Administrator", "Client Staff"].includes(role))) {

      if (id) {
        console.log(auth?.groups);
        steps.push(
          { stepNo: 1, name: `Order Info` },
          { stepNo: 2, name: `Items` }
        );
      }
      else {
        steps.push(
          { stepNo: 1, name: `Order Info` },
        );
      }
    }
    return steps;
  };


  const displayStep = (step) => {
    switch (step) {
      case 1:
        if (auth?.groups?.find(role => ["Administrator", "Staff"].includes(role))) {
          return <OrderSale
            formData={formData}
            setFormData={setFormData}
            handleInputChange={handleInputChange}
            setShouldUpdateData={setShouldUpdateData}
            setError={setError}
            setMessage={setMessage}
          />;
        }
        else if (auth?.groups?.find(role => ["Client Administrator", "Client Staff"].includes(role))) {
          return <OrderInfoClient formData={formData} />;
        }

      case 2:
        return <Items
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateData={setShouldUpdateData}
          shouldUpdateData={shouldUpdateData}
          setError={setError}
          setMessage={setMessage}
        />;

      case 3:
        return <PackingList
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateData={setShouldUpdateData}
          shouldUpdateData={shouldUpdateData}
          setError={setError}
          setMessage={setMessage}
        />;
      case 4:
        return <CommercialInvoice
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateData={setShouldUpdateData}
          shouldUpdateData={shouldUpdateData}
          setError={setError}
          setMessage={setMessage}
        />;
      case 5:
        return <ProductPurchaseOrder
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateData={setShouldUpdateData}
          shouldUpdateData={shouldUpdateData}
          setError={setError}
          setMessage={setMessage}
        />;
      case 6:
        return <Payments
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
          setShouldUpdateData={setShouldUpdateData}
          shouldUpdateData={shouldUpdateData}
          setError={setError}
          setMessage={setMessage}
        />;
      case 7:
        return <AdditionalCost formData={formData} setFormData={setFormData} />;
      case 8:
        return <Delivery formData={formData} />;
      default:
        return <>Loading</>
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    isEditMode ? updateOrder.mutate({ id, newData: formData }) : createOrder.mutate(formData);
  };


  useEffect(() => {
    if (isEditMode) {
      if (updateOrder.isSuccess) {
        navigate("/admin/order/sales-order/tracking");
      }
    }
    else {
      if (createOrder.isSuccess) {
        // navigate("/admin/order/sales-order/tracking/");
        navigate(`/admin/order/sales-order/edit/${createOrder.data.id}`)
      }
    }
  }, [updateOrder.isSuccess, createOrder.isSuccess, navigate, createOrder.data?.id, isEditMode]);

  useEffect(() => {
    const steps = getSteps();
    setSteps(() => steps);
  }, [auth]);

  if (isLoadingOrder | authLoading) {
    return <div>Loading...</div>;
  }
  if (errorOrder) {
    return <div>Error...</div>;
  }

  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-[95%] md:px-3  3xl:w-7/12">

        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          {error.display && (
            <div className="h-100">
              {
                <div className="pt-4 px-4">

                  <SolidSubtleAlert
                    title={error.title}
                    description={error.description}
                    icon={<AiFillExclamationCircle />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-red-500 dark:!bg-red-300"
                    mb="mb-0"
                    solid="solid"
                    data={error}
                    setAlert={setError}
                  />
                </div>

              }
            </div>

          )}
          {message.display && (
            <div className="h-100">
              {
                <div className="pt-4 px-4">

                  <SolidSubtleAlert
                    title={message.title}
                    description={message.description}
                    icon={<BsFillCheckCircleFill />}
                    iconColor="text-white dark:!text-navy-900"
                    closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
                    bg="bg-green-500 dark:!bg-green-300"
                    mb="mb-6"
                    solid="solid"
                    data={message}
                    setAlert={setMessage}
                  />
                </div>

              }
            </div>

          )}

          <form onSubmit={handleSubmit}>

            <div className="rounded-[20px]">

              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
            </div>
            {/* navigation button */}
            <StepperControl
              handleClick={handleClick}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              steps={steps}
            />
          </form>
        </Card>
      </div>
    </div>
  );
};

export default Form;
