import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "config";

const Address = ({ formData, setFormData, handleInputChange }) => {
  const resource = "address";

  if (!formData.address) {
    setFormData({ ...formData, address: {} });
  }

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Address
      </h4>

      {/* content */}
      {/* inputs */}
      <div className="mt-6 grid grid-cols-1 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-4"
            label="Street"
            placeholder="eg. Street"
            id="address.street"
            type="text"
            value={formData.address.street}
            onChange={handleInputChange}
          />
          <InputField
            extra="mb-4"
            label="City"
            placeholder="eg. City"
            id="address.city"
            type="text"
            value={formData.address.city}
            onChange={handleInputChange}
          />
          <InputField
            extra="mb-4"
            label="Province"
            placeholder="eg. Province"
            id="address.province"
            type="text"
            value={formData.address.province}
            onChange={handleInputChange}
          />
          <InputField
            extra="mb-4"
            label="Country"
            placeholder="eg. Country"
            id="address.country"
            type="text"
            value={formData.address.country}
            onChange={handleInputChange}
          />
          <InputField
            extra="mb-4"
            label="Postal Code"
            placeholder="eg. 123456-123"
            id="address.postal_code"
            type="text"
            value={formData.address.postal_code}
            onChange={handleInputChange}
          />
        </div>

      </div>
    </div >
  );
};

export default Address;
