import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { MdOutlineCloudUpload } from "react-icons/md";
import DropZonefile from "components/dropZone/DropZoneFile";
import useAuth from "hooks/useAuth";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { CNPJMask } from "components/CNPJInput/CNPJInput";
import Grid from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';


dayjs.locale('pt-br');

const DynamicFormField = ({ field, formData = {} }) => {
    const mdColSpan = field.mdColSpan || 12;
    const { auth, authLoading } = useAuth();



    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (field.showIf) {
            setIsVisible(field.showIf(formData));
        }

    }, [formData, field]);

    if (authLoading) return <div>Loading...</div>;

    if (!isVisible) {
        return null;
    }

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((o, p) => (o ? o[p] : null), obj);
    };

    const userRoles = auth?.groups || [];

    if (field.visibleTo) {
        const showField = field.visibleTo.some(role => userRoles.includes(role));
        if (!showField) {
            return null;
        }
    }

    const disabledTo = field.disabledTo || []; // Add this line to get the disabledTo value

    const isDisabled = disabledTo.some(role => userRoles.includes(role)) || field.disabled; // Modify this line to check if any of the roles in disabledTo are included in userRoles

    switch (field.type) {
        case "select":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControl fullWidth margin='normal'>
                        <Autocomplete
                            key={field.id}
                            id={field.id}
                            name={field.id}
                            options={[{ label: '', value: null }, ...field.options]} // Add an empty option at the beginning of the options array
                            getOptionLabel={(option) => option.label}
                            defaultValue={field.options.find((option) => option.value === field.value || null)}
                            onChange={(event, newValue) => {
                                field.handleInputChange({ target: { id: field.id, name: field.id, value: newValue?.value || null } });
                            }}
                            renderInput={(params) => <TextField {...params} label={field.label} />}
                            value={field.options.find((option) => option.value === field.value) || null}
                            disabled={isDisabled} // Add the disabled prop here
                        />
                    </FormControl>
                </Grid>
            )
        case "multiselect":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControl fullWidth margin='normal'>
                        <Autocomplete
                            multiple
                            key={field.id}
                            id={field.id}
                            name={field.id}
                            options={field.options}
                            getOptionLabel={(option) => option.label}
                            defaultValue={field.value || []}
                            onChange={(event, newValue) => {
                                // convert the array of options to an array of values
                                const newValues = newValue.map(option => option.value);

                                field.handleInputChange({ target: { id: field.id, name: field.id, value: newValues || null } });
                            }}
                            renderInput={(params) => <TextField {...params} label={field.label} />}
                            value={field.options.filter(option => (field.value || []).includes(option.value))}
                            disabled={isDisabled}
                        />
                    </FormControl>
                </Grid>
            );
        case "date":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControl fullWidth margin='normal'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                            <DatePicker
                                fullWidth
                                id={field.id}
                                label={field.label}
                                value={getNestedValue(formData, field.id) || "" ? dayjs(getNestedValue(formData, field.id) || "") : null}
                                onChange={(date) => {
                                    field.handleInputChange({ target: { id: field.id, name: field.id, value: field.dateFormat ? date.format(field.dateFormat) : date.format('YYYY-MM-DD') } });
                                }}
                                disabled={isDisabled} // Add the disabled prop here
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
            );
        case "textarea":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <TextField
                        key={field.id}
                        label={field.label}
                        placeholder={field.placeholder}
                        id={field.id}
                        name={field.id}
                        type={field.type}
                        value={getNestedValue(formData, field.id) || "" || ""}
                        onChange={field.handleInputChange}
                        disabled={isDisabled} // Add the disabled prop here
                        multiline
                        rows={3}
                        fullWidth

                    />
                </Grid>
            )
        case "switch":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControlLabel control={<Switch
                        checked={getNestedValue(formData, field.id) || "" || false} // Add a conditional check to ensure getNestedValue(formData, field.id) || "" is defined
                        onChange={field.handleInputChange}
                        name={field.id}
                        inputProps={{ 'aria-label': 'controlled' }}
                        key={field.id}
                        id={field.id}
                        disabled={isDisabled} // Add the disabled prop here
                    />} label={field.label} />
                </Grid>
            );
        case "dropzone":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <DropZonefile
                        content={
                            <div className="flex h-[225px] w-full flex-col items-center justify-center rounded-md border-[2px] border-gray-300 bg-gray-100 dark:bg-gray-800 dark:border-gray-500 sm:w-[200px] md:w-[250px] lg:w-[300px] xl:w-[250px] 2xl:w-[300px] 3xl:w-[300px] p-2">
                                <p className="text-[80px] text-navy-700">
                                    <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
                                </p>
                                <p className="text-lg font-bold text-navy-700 dark:text-white">
                                    Drop your files here, or{" "}
                                    <span className="font-bold text-brand-500 dark:text-brand-400">
                                        browse
                                    </span>
                                </p>
                                <p className="pt-2 text-sm text-gray-600">
                                    PNG, JPG and GIF files are allowed
                                </p>
                            </div>
                        }
                        formData={formData}
                        setFormData={field.setFormData}
                        handleImageUpload={(file) => {
                            field.setFormData({ ...formData, picture: file });
                        }}
                        disabled={isDisabled} // Add the disabled prop here
                    />
                </Grid>
            );
        case "tel": // Add a new case for "tel" type
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControl fullWidth margin='normal'>
                        <MuiTelInput
                            id={field.id}
                            value={getNestedValue(formData, field.id) || ""}
                            label={field.label}
                            onChange={(value) => {
                                field.handleInputChange({ target: { id: field.id, name: field.id, value } });
                            }
                            }
                            error={getNestedValue(formData, field.id) || "" && !matchIsValidTel(getNestedValue(formData, field.id) || "")}
                            helperText={getNestedValue(formData, field.id) || "" && !matchIsValidTel(getNestedValue(formData, field.id) || "") && 'Invalid phone number'}
                            disabled={isDisabled} // Add the disabled prop here
                        />
                    </FormControl>
                </Grid>
            );
        case "cnpj":
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControl fullWidth margin='normal'>
                        <TextField
                            key={field.id}
                            label={field.label}
                            placeholder="00.000.000/0000-00"
                            id={field.id}
                            name={field.id}
                            type="text"
                            value={getNestedValue(formData, field.id) || "" || ""}
                            onChange={field.handleInputChange}
                            disabled={isDisabled}
                            InputProps={{
                                inputComponent: CNPJMask,
                            }}
                        />
                    </FormControl>
                </Grid>
            );

        default:
            return (
                <Grid xs={12} md={mdColSpan} key={field.id}>
                    <FormControl fullWidth margin='normal'>
                        <TextField
                            key={field.id}
                            label={field.label}
                            placeholder={field.placeholder}
                            id={field.id}
                            name={field.id}
                            type={field.type}
                            value={getNestedValue(formData, field.id) || "" || ""}
                            onChange={field.handleInputChange}
                            disabled={isDisabled} // Add the disabled prop here
                        />
                    </FormControl>
                </Grid>
            );
    }
};


export default DynamicFormField;
