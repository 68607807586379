import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import React, { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import jwt_decode from 'jwt-decode';

import { API_BASE_URL } from 'config';

import { useLoading } from 'contexts/LoadingContext';
import { useNotification } from 'contexts/NotificationContext';

function ForgotPasswordDefault() {
  const { setAuth } = useAuth();

  const { setLoading } = useLoading();
  const { setNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/auth/reset-password';

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking if the email field is empty
    if (!email) {
      setNotification({
        title: 'Error',
        description: 'Please enter your email.',
        display: true,
        type: 'error',
      });
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/password/generate_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();
      if (response.status === 201) {
        localStorage.setItem('email', email);
        navigate(from, { replace: true });
      } else {
        setNotification({
          title: 'Error',
          description: 'Email not registered',
          display: true,
          type: 'error',
        });
      }
    } catch (error) {
      console.error(error);
      setNotification({
        title: 'Error',
        description: 'Something went wrong. Try again later.',
        display: true,
        type: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <Default
      maincard={
        <div className="mb-12 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[9vh] flex flex-col place-content-center rounded-[20px] pb-0 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[100%] xl:pr-0">
            <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
              Forgot Your Password?
            </h2>
            <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
              No problem. Just let us know your email address and we'll email
              you a password reset link that will allow you to choose a new one.
            </p>
            {/* Email */}
            <InputField
              variant="auth"
              label="Email"
              placeholder="mail@simmmple.com"
              id="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(() => e.target.value)}
            />
            {/* button */}
            <button
              onClick={handleSubmit}
              className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Send validation code
            </button>
          </div>
        </div>
      }
    />
  );
}

export default ForgotPasswordDefault;
