import { useEffect, useState, useRef } from 'react';
import { useFetchData } from 'services/queries';
import DynamicFormField from 'components/form/DynamicFormField';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const Resource = ({ formData, setFormData, handleInputChange }) => {
  const {
    data: categories,
    error: errorCategories,
    isLoading: isLoadingCategories,
  } = useFetchData('product/category');

  const [openModal, setOpenModal] = useState(false);
  const [hasShownModal, setHasShownModal] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const prevFormDataRef = useRef(formData);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (!isInitialized && formData.id) {
      setIsInitialized(true);
      prevFormDataRef.current = formData;
      return;
    }

    const prevFormData = prevFormDataRef.current;

    if (
      isInitialized &&
      !hasShownModal &&
      formData.id && // Check if it's an edit mode
      (formData.value !== prevFormData.value || formData.parent !== prevFormData.parent)
    ) {
      setOpenModal(true);
      setHasShownModal(true);
    }

    prevFormDataRef.current = formData;
  }, [formData, hasShownModal, isInitialized]);

  if (isLoadingCategories) return <div>Loading...</div>;
  if (errorCategories) return <div>Error</div>;

  const formFields = [
    {
      id: 'name',
      name: 'Name',
      label: 'Name',
      placeholder: 'e.g., Electronics',
      type: 'text',
      value: formData.name,
      handleInputChange: handleInputChange,
      mdColSpan: 12,
    },
    {
      id: 'value',
      name: 'Value',
      label: 'Value',
      placeholder: 'e.g., 123',
      type: 'text',
      value: formData.value,
      handleInputChange: handleInputChange,
      mdColSpan: 12,
    },
    {
      id: 'parent',
      name: 'Parent',
      label: 'Parent Category',
      type: 'select',
      value: formData.parent || "",
      handleInputChange: handleInputChange,
      options: categories?.map((category) => {
        return {
          value: category.id,
          label: category.name_path,
        }
      }) || [],
      mdColSpan: 12,
      style: { marginTop: '16px' },
    },
    {
      id: 'description',
      name: 'Description',
      label: 'Description',
      placeholder: 'e.g., Category for electronic items',
      type: 'textarea',
      value: formData.description,
      handleInputChange: handleInputChange,
      mdColSpan: 12,
    },
  ];

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Important Information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All products with “Auto SKU” enabled, related to this category, will have their SKU updated due to changes in the field "Value" or "Parent Category".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Product Category
      </h4>

      {/* content */}
      {/* inputs */}
      <Grid container spacing={2} item xs={12} >
        {formFields?.map((field) => (
            <DynamicFormField
              key={field.id}
              field={field}
              formData={formData}
              handleInputChange={handleInputChange}
            />
        ))}
      </Grid>
    </div>
  );
};

export default Resource;