// src/services/useAPI.js
import { useContext } from 'react';
import { createAPI } from './api';
import AuthContext from 'contexts/AuthProvider';

export const useAPI = () => {
    const { auth, refreshAccessToken } = useContext(AuthContext);
    const api = createAPI(auth.accessToken, refreshAccessToken);

    return api;
};
