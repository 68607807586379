import React from 'react';
import { Stack, Box, Button } from '@mui/material';
import {
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

const ToolbarSearch = () => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
        >
            <Box color="secondary">
                <GridToolbarQuickFilter />
            </Box>
        </Stack>
    );
}

export default ToolbarSearch