import React from 'react'
import { Box } from '@mui/system';
import { useState } from 'react';
import EditableDataGrid from 'components/EditableDataGrid/EditableDataGrid';
import { useFetchData } from "services/queries";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';


const SubProducts = ({ formData }) => {
    const resourcePath = 'product/sub-product';
    const relatedResources = `product/sub-product/?master_product=${formData.id}`;
    const apiRef = useGridApiRef();
    const [tableData, setTableData] = useState([]);
    // fetch products
    const { data: products, error: errorProducts, isLoading: isLoadingProducts } = useFetchData('product');

    if (isLoadingProducts) return <div>Loading...</div>
    if (errorProducts) return <div>Error...</div>

    const columns = [
        {
            field: 'sub_product',
            headerName: 'Sub Product',
            editable: true,
            groupable: false,
            minWidth: 250,
            type: 'string',
            flex: 1,
            renderCell: (params) => {
                const product = products.find((product) => product.id === params.row.sub_product);
                return (
                    <Grid container alignItems="center" pr={2} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid
                            xs={2}
                            mx={0.5} marginRight={1}
                            maxWidth={45}
                        >
                            {
                                product && product.picture ? (
                                    <img src={product.picture} className="w-10 h-10 object-contain drop-shadow-md" />
                                ) : (
                                    <div className="w-10 h-10 bg-gray-100"></div>
                                )
                            }
                        </Grid>
                        <Grid flex={1}
                            sx={{ lineHeight: 'normal' }} py={0.5}>
                            <Typography
                                variant="subtitle2"
                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                {product?.name}
                            </Typography>
                            <Typography variant="caption">{product?.sku}</Typography>
                        </Grid>
                    </Grid>
                );
            },
            renderEditCell: (params) => {
                return (
                    <Autocomplete
                        fullWidth
                        value={
                            params.value && params.value !== ''
                                ? products.find((product) => product.id === params.value) || null
                                : null
                        }
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        options={products.filter((product) => product.product_type === 'part')}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(props, option) => {
                            const sku = option.sku || '';
                            const name = option.name || '';
                            const picture = option.picture || '';
                            return (
                                <li {...props}>
                                    <Grid key={option.id} container alignItems="center" pr={2} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Grid
                                            xs={2}
                                            mx={0.5} marginRight={1}
                                            maxWidth={45}
                                        >
                                            {
                                                picture ? (
                                                    <img src={picture} className="w-10 h-10 object-contain drop-shadow-md" />
                                                ) : (
                                                    <div className="w-10 h-10 bg-gray-100"></div>
                                                )
                                            }
                                        </Grid>
                                        <Grid flex={1}
                                            sx={{ lineHeight: 'normal' }} py={0.5}>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            >
                                                {name}
                                            </Typography>
                                            <Typography variant="caption">{sku}</Typography>
                                        </Grid>
                                    </Grid>
                                </li>

                            );
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Sub Product" />}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                apiRef.current.setEditCellValue({ id: params.id, field: 'sub_product', value: newValue.id });
                            }
                        }}
                    />
                )
            }
        },
        // quantity
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 150,
            editable: true,
            groupable: false,
            type: 'number',
        },
    ];

    let actions = {
        create: true,
        edit: true,
        delete: true,
    };

    const newRowTemplate = {
        id: 'RANDOM**',
        master_product: formData.id,
        sub_product: '',
        quantity: 0,
        isNew: true
    };




    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">Sub Products</h3>
            <Box>
                <EditableDataGrid
                    columns={columns}
                    resourcePath={resourcePath}
                    relatedResources={relatedResources}

                    tableData={tableData}
                    setTableData={setTableData}

                    actions={actions}
                    apiRef={apiRef}

                    newRowTemplate={newRowTemplate}

                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        pinnedColumns: { left: ['actions'] },
                    }}
                    autosizeOnMount={true}
                />
            </Box>
        </div>
    )
}

export default SubProducts