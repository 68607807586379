export const columnsData = [
  {
    Header: 'PRODUCT NAME',
    accessor: 'name',
    type: 'text',
  },
  {
    Header: 'SKU',
    accessor: 'sku',
    type: 'text',
  },
  {
    Header: 'ACTIONS',
    accessor: 'id',
    type: 'actions',
  },
];
