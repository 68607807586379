import React from "react";
import { useFetchData } from 'services/queries';
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { GridActionsCellItem } from '@mui/x-data-grid';
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import ToolbarCrud from 'components/toolbar/ToolbarCrud';
import ToolbarSearch from 'components/toolbar/ToolbarSearch';
import { FaCheckCircle } from "react-icons/fa";

const ListObjects = () => {
  const resource = 'authentication/users';
  const resource_name = 'Users';
  const { data: data, error: errorData, isLoading: isLoadingData } = useFetchData(resource, true);
  const { data: dataClients, error: errorDataClients, isLoading: isLoadingDataClients } = useFetchData('client', true);
  const { auth, authLoading } = useAuth();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);


  const columnsData = [
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 150,
      editable: false,
      groupable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.picture ? (
              <img src={params.row.picture} alt={params.row.name} className="w-10 max-h-10 object-contain rounded-full" />
            ) : (
              <div className="w-10 h-10 rounded-full bg-gray-300"></div>
            )}
            <p className="ml-2">{params.row.name}</p>
          </div>
        )
      }

    },
    {
      field: 'email',
      headerName: 'EMAIL',
      editable: false,
      groupable: false,
      aggregable: false,
      type: 'string',
      flex: 1,
    },
    {
      field: 'is_active',
      headerName: 'Active',
      // width: 160,
      editable: false,
      groupable: false,
      type: 'boolean',
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.is_active ? (
              <FaCheckCircle className="text-green-500" />
            ) : (
              <FaCheckCircle className="text-gray-500" />
            )}
          </div>
        )
      }
    },
    // is_superuser
    {
      field: 'is_superuser',
      headerName: 'Superuser',
      editable: false,
      groupable: false,
      type: 'boolean',
      visibleTo: ['Administrator', 'Staff'],
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.is_superuser ? (
              <FaCheckCircle className="text-green-500" />
            ) : (
              <FaCheckCircle className="text-gray-500" />
            )}
          </div>
        )
      }
    },
    {
      field: 'is_staff',
      headerName: 'Staff',
      editable: false,
      groupable: false,
      type: 'boolean',
      visibleTo: ['Administrator', 'Staff'],
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.is_staff ? (
              <FaCheckCircle className="text-green-500" />
            ) : (
              <FaCheckCircle className="text-gray-500" />
            )}
          </div>
        )
      }
    },
    {
      field: 'is_client_admin',
      headerName: 'Client Admin',
      editable: false,
      groupable: false,
      type: 'boolean',
      visibleTo: ['Administrator', 'Staff'],
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.is_client_admin ? (
              <FaCheckCircle className="text-green-500" />
            ) : (
              <FaCheckCircle className="text-gray-500" />
            )}
          </div>
        )
      }
    },
    {
      field: 'is_client_staff',
      headerName: 'Client Staff',
      editable: false,
      groupable: false,
      type: 'boolean',
      visibleTo: ['Administrator', 'Staff'],
      renderCell: (params) => {
        return (
          <div className="flex items-center">
            {params.row.is_client_staff ? (
              <FaCheckCircle className="text-green-500" />
            ) : (
              <FaCheckCircle className="text-gray-500" />
            )}
          </div>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      groupable: false,
      aggregable: false,
      visibleTo: ['Administrator', 'Staff', 'Client Administrator'],
      getActions: (params) => {
        const { id } = params.row;
        return [
          <GridActionsCellItem
            icon={<BsPencilSquare />}
            color="primary"
            label="Edit"
            component={Link}
            to={`/admin/${resource}/edit/${id}`}
          />,
          <GridActionsCellItem
            color="primary"
            icon={<BsTrash />}
            label="Delete"
            onClick={() => {
              setSelectedItem(params.row.id); // Set the selected item
              setOpenDeleteDialog(true); // Open the dialog
            }}
          />,
        ];
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      groupable: false,
      aggregable: false,
      visibleTo: ['Administrator', 'Staff', 'Client Administrator'],
      getActions: (params) => {
        const { id } = params.row;
        return [
          <GridActionsCellItem
            icon={<BsPencilSquare />}
            color="primary"
            label="Edit"
            component={Link}
            to={`/admin/${resource}/edit/${id}`}
          />,
          <GridActionsCellItem
            color="primary"
            icon={<BsTrash />}
            label="Delete"
            onClick={() => {
              setSelectedItem(params.row.id); // Set the selected item
              setOpenDeleteDialog(true); // Open the dialog
            }}
          />,
        ];
      }
    },
    // last login
    {
      field: 'last_login',
      headerName: 'Last login',
      editable: false,
      groupable: false,
      type: 'dateTime',
      minWidth: 170,
      valueGetter: (value, row) => {
        return row.last_login ? new Date(row.last_login) : null;
      }
    },
  ];
  const columns = useRoleBasedColumns(columnsData);

  if (isLoadingData | authLoading | isLoadingDataClients) return <div>Loading...</div>;
  if (errorData) return <div>Error! {errorData.message}</div>;
  if (errorDataClients) return <div>Error! {errorDataClients.message}</div>;

  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box>
        <DataGridPremium
          rows={data}
          columns={columns.map(column => ({
            ...column,
            editable: false
          }))}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pinnedColumns: { left: ['actions'] },
            pagination: { paginationModel: { pageSize: 10 } },
            density: 'comfortable',
            columns: {
              columnVisibilityModel: {
                last_login: false,
              }
            }
          }}
          slots={{
            toolbar: () => {
              return (
                auth?.groups?.find(role => ["Administrator", "Staff", "Client Administrator"]?.includes(role))
                  ? <ToolbarCrud
                    resource={resource}
                    name={resource_name}

                  />
                  : <ToolbarSearch />
              )
            }
          }}
          autosizeOptions={{
            columns: ['name', 'email', 'client'],
            includeOutliers: true,
            includeHeaders: true,
          }}

        />
      </Box>
      <DeleteConfirmDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        selectedItem={selectedItem}
        resource={resource}
      />
    </Card>

  )
}

export default ListObjects