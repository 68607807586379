import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";

import { ColorModeProvider } from "contexts/ColorModeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
  <BrowserRouter>
    <ColorModeProvider>
      <App />
    </ColorModeProvider>
  </BrowserRouter>
);
