import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import useAuth from "hooks/useAuth";
import avatar from "assets/img/avatars/avatar_bewise.png";
import { ColorModeContext } from "contexts/ColorModeContext";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const { auth, loading } = useAuth();

  const { toggleColorMode, isDarkMode } = React.useContext(ColorModeContext);

  if (loading) {
    return <div>Loading...</div>; // or replace with a loading spinner
  }

  return (
    <nav
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
      className="fixed top-3 right-3 flex w-[calc(100vw_-_6%)] flex-row items-center justify-between rounded-xl bg-white/30  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:top-[20px] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
    >
      {/* Page Link */}
      <div className="ml-[6px] flex">
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      {/* Buttons (sidenav, dark mode and profile) */}
      <div className="ml-5 relative mt-[3px] flex h-[61px] w-[290px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-1.5 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        {/* <div className="relative mt-[3px] flex h-[48px] w-[280px] flex-grow items-center justify-around gap-1 rounded-full bg-white px-1.5 py-1 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[300px] md:flex-grow-0 md:gap-0.5 xl:w-[300px] xl:gap-1"> */}


        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (isDarkMode) {
              document.body.classList.remove("dark");
              toggleColorMode();
              // setMuiDarkmode(false);
            } else {
              document.body.classList.add("dark");
              toggleColorMode();
              // setMuiDarkmode(true);
            }
          }}
        >
          {isDarkMode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        {console.log(auth.picture)}
        <Dropdown

          button={
            <img
              className="h-10 w-10 rounded-full"
              src={auth.picture ? auth.picture : avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {auth.name}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                {/* <a
                  href={`/admin/users/edit/${auth.user_id}`}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a> */}
                <Link
                  to={`/admin/authentication/users/edit/${auth.user_id}`}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>

                <a
                  // href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    window.location.reload();
                  }}
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>


    </nav>
  );
};

export default Navbar;
