import { useState } from "react";
import React from 'react';
import { useFetchData } from "services/queries";
import Box from '@mui/material/Box';
import {
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import { GRID_AGGREGATION_ROOT_FOOTER_ROW_ID } from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';



const USDcurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

const RMBcurrencyFormatter = new Intl.NumberFormat('zh-CN', {
  style: 'currency',
  currency: 'CNY',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

const ProductPurchaseOrder = ({ formData }) => {
  const resourcePath = 'order/purchase-product-order';
  const relatedResources = `order/purchase-product-order/?product_item_sales_order__sales_order=${formData.id}`;
  const [tableData, setTableData] = useState([]);
  const { data: products, isLoading: isLoadingProducts, error: errorProducts } = useFetchData('product', true);
  const { data: suppliers, isLoading: isLoadingSuppliers, error: errorSuppliers } = useFetchData('supplier', true);
  const { data: skuClientData, error: errorSkuClientData, isLoading: isLoadingSkuClientData } = useFetchData(`product/client-sku/?client=${formData.buyer_id}`, true);
  const apiRef = useGridApiRef();

  const handleSupplierChange = (event, newValue, rowId) => {
    if (!newValue) {
      return;
    }
    const updatedRows = tableData.map(row => {
      if (row.id === rowId) {
        return { ...row, supplier: newValue, supplier_id: newValue.id };
      }
      return row;
    });
    setTableData(updatedRows);
    apiRef.current.setEditCellValue({ id: rowId, field: 'supplier', value: newValue });
    apiRef.current.setEditCellValue({ id: rowId, field: 'supplier_id', value: newValue.id });
  };


  const actions = {
    create: false,
    edit: true,
    delete: false,
  }

  const columnsDataItems = [
    // product picture
    {
      field: 'picture',
      headerName: '',
      editable: false,
      groupable: false,
      aggregable: false,
      maxWidth: 50,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
          return [];
        }
        return (
          <Box style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', justify: 'center' }}>
            {
              params.row.product && params.row.product.picture ? (
                <img src={params.row.product.picture} className="w-10 h-10 object-contain rounded-full drop-shadow-md" />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-100"></div>
              )
            }
          </Box>
        )
      }
    },
    {
      field: 'product_id',
      headerName: 'PRODUCT',
      editable: false,
      groupable: false,
      type: 'singleSelect',
      minWidth: 250,
      maxWidth: 350,
      flex: 1,
      renderCell: (params) => {
        const { product, quantity } = params.row;
        if (product) {
          return (
            <Grid container>
              <Grid sx={{ lineHeight: 'normal' }} py={0.5} justifyContent="center" alignItems="center">
                <Typography variant="subtitle2">{product.name}</Typography>
                <Typography variant="caption">{product.sku} - {quantity} pcs</Typography>
              </Grid>
            </Grid>

          );
        }
        return '';
      },
      valueSetter: (value, row) => {
        const selectedProduct = products.find(product => product.id === value);
        if (!selectedProduct) {
          return row;
        }
        const updatedRow = {
          ...row,
          product_id: selectedProduct.id,
          product: selectedProduct,
          quantity: row.quantity
        };
        return updatedRow;
      },
      valueGetter: (value, row) => {
        return `${row.product?.name} - ${row.product?.sku}`;
      },
    },
    {
      field: 'sku',
      headerName: 'SKU',
      editable: false,
      groupable: false,
      valueGetter: (value, row, column, apiRef) => {
        return row.product?.sku;
      },
    },
    // client sku
    {
      field: 'client_sku',
      headerName: 'Client SKU',
      editable: false,
      groupable: false,
      type: 'string',
      valueGetter: (value, row) => {
        return skuClientData?.find(sku => sku.product === row.product?.id)?.sku;
      },
    },
    {
      field: 'quantity',
      headerName: 'QUANTITY',
      editable: false,
      type: 'number',
      groupable: false,
    },
    // supplier
    {
      field: 'supplier_id',
      headerName: 'SUPPLIER',
      editable: true,
      groupable: true,
      type: 'singleSelect',
      minWidth: 150,
      valueGetter: (value, row, column, apiRef) => {
        return row.supplier?.id;
      },
      valueOptions: suppliers?.map((supplier) => ({
        value: supplier.id,
        label: supplier.name,
      })),
      renderEditCell: (params) => {
        return (
          <Autocomplete
            value={params.row.supplier || null}
            onChange={(event, newValue) => handleSupplierChange(event, newValue, params.id)}
            options={suppliers}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => <TextField {...params} label="Select Supplier" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
          />
        )
      },
      valueSetter: (value, row) => {
        const selectedSupplier = suppliers.find(supplier => supplier.id === value);
        if (!selectedSupplier) {
          return row;
        }
        const updatedRow = {
          ...row,
          supplier: selectedSupplier,
          supplier_id: selectedSupplier.id,
        };
        return updatedRow;
      },
    },
    // usd_per_unit
    {
      field: 'usd_per_unit',
      headerName: 'USD/UNIT',
      editable: true,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return USDcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    },
    // rmb_per_unit
    {
      field: 'rmb_per_unit',
      headerName: 'RMB/UNIT',
      editable: true,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return USDcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    },
    // total_price_usd
    {
      field: 'total_price_usd',
      headerName: 'TOTAL COST USD',
      editable: false,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return USDcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    },
    // total_price_rmb
    {
      field: 'total_price_rmb',
      headerName: 'TOTAL COST RMB',
      editable: false,
      type: 'number',
      valueFormatter: (value) => {
        if (!value) {
          return value
        }
        return USDcurrencyFormatter.format(value);
      },
      valueGetter: (value) => (value ? parseFloat(value) : ''),
    }
  ];
  if (isLoadingProducts || isLoadingSuppliers || isLoadingSkuClientData) return <div>Loading...</div>;
  if (errorProducts) return <div>Error: {errorProducts.message}</div>;
  if (errorSuppliers) return <div>Error: {errorSuppliers.message}</div>;
  if (errorSkuClientData) return <div>Error: {errorSkuClientData.message}</div>;

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">Purchase Order Items</h3>
      <Box>
        <EditableDataGrid
          columns={columnsDataItems}
          resourcePath={resourcePath}
          tableData={tableData}
          setTableData={setTableData}

          relatedResources={relatedResources}
          apiRef={apiRef}

          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            pinnedColumns: { left: ['actions', 'picture'] },
            aggregation: {
              model: {
                total_price_usd: 'sum',
                total_price_rmb: 'sum',
              },
            },
            columns: {
              columnVisibilityModel: {
                sku: false,
                quantity: false,
              }
            }
          }}
          autosizeOptions={{
            columns: ['product_id', 'sku', 'quantity', 'supplier_id', 'usd_per_unit', 'rmb_per_unit', 'total_price_usd', 'total_price_rmb'],
            includeOutliers: true,
            includeHeaders: true,
          }}
          autosizeOnMount={true}
          actions={actions}
        />
      </Box>
    </div>
  )

};

export default ProductPurchaseOrder;
