import DynamicFormField from "components/form/DynamicFormField";
import Grid from '@mui/material/Unstable_Grid2';

const Address = ({ formData, setFormData, handleInputChange }) => {
  const formFields = [
    {
      id: "address.street",
      label: "Street",
      placeholder: "eg. Street",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.address.street
    },
    {
      id: "address.city",
      label: "City",
      placeholder: "eg. City",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.address.city
    },
    {
      id: "address.province",
      label: "Province",
      placeholder: "eg. Province",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.address.province
    },
    {
      id: "address.country",
      label: "Country",
      placeholder: "eg. Country",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.address.country
    },
    {
      id: "address.postal_code",
      label: "Postal Code",
      placeholder: "eg. Postal Code",
      type: "text",
      mdColSpan: 12,
      handleInputChange: handleInputChange,
      value: formData.address.postal_code
    }
  ];

  console.log(formData);

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Address
      </h4>

      {/* content */}
      {/* inputs */}
      <Grid container spacing={2}>
        {formFields.map(field => {
          return (
            <DynamicFormField
              key={field.id}
              field={field}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          );
        })}
      </Grid>
    </div >
  );
};

export default Address;