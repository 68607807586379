import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ClientIncoming from './components/ClientIncoming';
import TopProducts from './components/TopProducts';
import OrderStatus from "./components/OrderStatus";
import HeaderBoxes from './components/HeaderBoxes';
import Paper from '@mui/material/Paper';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { set_date_to_end_of_day } from 'utils';
import { useFetchData } from 'services/queries';


const predefinedRanges = [
  { label: 'Current Month', days: dayjs().date() - 1 },
  { label: 'Last 30 Days', days: 30 },
  { label: 'Last 60 Days', days: 60 },
  { label: 'Last 90 Days', days: 90 },
  { label: 'Last 180 Days', days: 180 },
  { label: 'Last 365 Days', days: 365 },

];

const Dashboard = () => {
  const { data: dataStatusSalesOrder, error: errorDataStatusSalesOrder, isLoading: isLoadingDataStatusSalesOrder } = useFetchData(`order/status-sales-order/`, true);

  const [selectedTab, setSelectedTab] = useState(0);
  let first_day_of_month = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  first_day_of_month.setHours(0, 0, 0);
  first_day_of_month = dayjs(first_day_of_month).format().replace('+', '%2B');
  let today = new Date()
  let endOftoday = set_date_to_end_of_day(today);
  endOftoday.setHours(23, 59, 59);
  endOftoday = dayjs(endOftoday).format().replace('+', '%2B');
  const [dateRange, setDateRange] = useState([first_day_of_month, endOftoday]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const handleDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue[0] && newValue[1]) {
      const startDate = newValue[0].format().replace('+', '%2B');
      let endDate = newValue[1];
      endDate = dayjs(endDate).endOf('day').format().replace('+', '%2B');
      setDateRange(() => [startDate, endDate]);
    }
  };

  const handlePredefinedRange = (days) => {
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - days);

    endDate = set_date_to_end_of_day(endDate);
    startDate.setHours(0, 0, 0, 0);

    // Set dates in the correct format and encode the '+' sign
    startDate = dayjs(startDate).format().replace('+', '%2B');
    endDate = dayjs(endDate).format().replace('+', '%2B');

    setDateRange([startDate, endDate]);
  };


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (isLoadingDataStatusSalesOrder) {
    return <div>Loading...</div>;
  }
  if ( errorDataStatusSalesOrder){
    return <div>Error...</div>;
  }

  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="left" alignItems="center">

        <IconButton onClick={handleDialogToggle} color="primary" aria-label="filter date range">
          <FilterListIcon />
        </IconButton>
        <Box>
          {
            isMdDown ? (
              <Chip
                key={predefinedRanges[0].label}
                label={predefinedRanges[0].label}
                onClick={() => handlePredefinedRange(predefinedRanges[0].days)}
                sx={{ mr: 1, cursor: 'pointer' }}
                variant="outlined"
              />
            ) :
              (predefinedRanges.map((range) => (
                <Chip
                  key={range.label}
                  label={range.label}
                  onClick={() => handlePredefinedRange(range.days)}
                  sx={{ mr: 1, cursor: 'pointer' }}
                  variant="outlined"
                />
              )))}
        </Box>
        <Typography variant={isXs ? "body2" : "overline"} display="block" gutterBottom>
          {dateRange[0] && dateRange[1] ? `Orders opened from ${dateRange[0].split('T')[0]} to ${dateRange[1].split('T')[0]}` : 'All'}
        </Typography>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogToggle} maxWidth="xs" fullWidth>
        <DialogTitle>Date Range</DialogTitle>
        <DialogContent >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              startText="Start Date"
              margin={2}
              endText="End Date"
              value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
              onChange={handleDateRangeChange}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} fullWidth />
                  <Box sx={{ mx: 2 }}>to</Box>
                  <TextField {...endProps} fullWidth />
                </>
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => setDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>


      <HeaderBoxes
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        status={dataStatusSalesOrder}
      />

      <Box sx={{ flexGrow: 1 }} alignContent='center' margin={2} marginTop={3}>
        <Grid container justifyContent='space-between' display='flex'>
          <Grid xs={12}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              orientation="horizontal"
              variant={isXs ? "scrollable" : "standard"}
              scrollButtons="auto"
              allowScrollButtonsMobile
              centered={!isXs}
            >
              <Tab label="Order Status" />
              <Tab label="Client Incoming" />
              <Tab label="Most Sold Products" />
            </Tabs>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Paper elevation={16}>

          {selectedTab === 0 && (
            <OrderStatus
              startDate={dateRange[0]}
              endDate={dateRange[1]}
            />
          )}

          {selectedTab === 1 && (
            <Box sx={{ flexGrow: 1 }}>
              <ClientIncoming
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                status={dataStatusSalesOrder}
              />
            </Box>
          )}

          {selectedTab === 2 && (
            <Box sx={{ flexGrow: 1 }}>
              <TopProducts
                startDate={dateRange[0]}
                endDate={dateRange[1]}
              />
            </Box>
          )}

        </Paper >
      </Box >

    </Box>
  );
};

export default Dashboard;