const getInputChange = (e, formData, setFormData) => {
    const { id, value, type, checked } = e.target;
    if (type === "checkbox") {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: checked,
        }));
    } else if (id.includes(".")) {
        const nestedFields = id.split(".");
        let updatedFormData = { ...formData };
        let currentField = updatedFormData;

        for (let i = 0; i < nestedFields.length - 1; i++) {
            const field = nestedFields[i];
            currentField = currentField[field] || {};
        }

        const lastField = nestedFields[nestedFields.length - 1];
        currentField[lastField] = value;

        setFormData(updatedFormData);
    } else {
        const previous_value = formData && formData[id] ? formData[id] : null;
        // if it has value before and now it is null, then set to undefied
        const new_value = previous_value && !value ? undefined : value;
        console.debug(`New value for field ${id}: `, new_value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: new_value,
        }));
    }
};

export default getInputChange;