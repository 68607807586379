import DynamicFormField from "components/form/DynamicFormField";
import { useFetchData } from 'services/queries';
import useAuth from "hooks/useAuth";
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect } from "react";

const Resource = ({ formData, setFormData, handleInputChange }) => {
  const { data: clients, error: errorClient, isLoading: isLoadingClient } = useFetchData('client', true);
  const { data: clientStaffGroups, error: errorClientStaffGroups, isLoading: isLoadingClientStaffGroups } = useFetchData('authentication/groups/?name__icontains=[Client Staff]', true);
  const { data: clientAdminGroups, error: errorClientAdminGroups, isLoading: isLoadingClientAdminGroups } = useFetchData('authentication/groups/?name__icontains=[Client Admin]', true);

  const { auth, authLoading } = useAuth();

  if (isLoadingClient || isLoadingClientStaffGroups || isLoadingClientAdminGroups) return <div>Loading...</div>;
  if (errorClient || errorClientStaffGroups || errorClientAdminGroups) return <div>Error...</div>;
  if (authLoading) return <div>Loading...</div>;

  const isClient = auth?.groups.includes("Client Administrator") || auth?.groups.includes("Client Staff");

  // set setFormData client if no client is set
  if (isClient && !formData.client) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      client: clients?.[0]?.id,
    }));
  }


  const formFields = [
    {
      id: "picture",
      label: "Picture",
      type: "dropzone",
      mdColSpan: 12, // Define the column span for medium-sized screens
      setFormData: setFormData,
      value: formData.picture
    },
    {
      id: "first_name",
      label: "First Name",
      placeholder: "eg. Boyle",
      type: "text",
      mdColSpan: 6, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.first_name
    },
    {
      id: "last_name",
      label: "Last Name",
      placeholder: "eg. Son",
      type: "text",
      mdColSpan: 6, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.last_name
    },
    {
      id: "email",
      label: "Email",
      placeholder: "eg. email@email.com",
      type: "text",
      mdColSpan: 12, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.email
    },
    {
      id: "password",
      label: "Password",
      placeholder: "eg. password",
      type: "password",
      mdColSpan: 12, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.password,
      showIf: () => {
        console.log(auth?.user_id, formData.id)
        return auth?.user_id === formData.id
      }
    },
    {
      id: "client_staff_groups",
      label: "Client Staff Of",
      type: "multiselect",
      options: clientStaffGroups?.map((group) => ({
        value: group.id,
        label: group.name.replace("[Client Staff] ", "").replace("[", "").replace("]", "")
      })),
      mdColSpan: 12, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.client_staff_groups || [],
      visibleTo: ["Administrator", "Staff", "Client Administrator"],
      disabledTo: ["Client Staff"]
    },
    {
      id: "client_admin_groups",
      label: "Client Admin Of",
      type: "multiselect",
      options: clientAdminGroups?.map((group) => ({
        value: group.id,
        label: group.name.replace("[Client Admin] ", "").replace("[", "").replace("]", "")
      })),
      mdColSpan: 12, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.client_admin_groups || [],
      visibleTo: ["Administrator", "Staff", "Client Administrator"],
      disabledTo: ["Client Staff"]
    },
    {
      id: "is_active",
      label: "Active",
      type: "switch",
      mdColSpan: 4, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.is_active,
    },
    {
      id: "is_superuser",
      label: "Super user",
      type: "switch",
      mdColSpan: 4, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.is_superuser,
      visibleTo: ["Administrator"],
    },
    {
      id: "is_staff",
      label: "Staff",
      type: "switch",
      mdColSpan: 4, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.is_staff,
      visibleTo: ["Administrator"]
    },

    {
      id: "is_client_admin",
      label: "Client Admin",
      type: "switch",
      mdColSpan: 4, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.is_client_admin,
      visibleTo: ["Administrator", "Staff", "Client Administrator"],
      disabledTo: ["Client Staff"]
    },
    {
      id: "is_client_staff",
      label: "Client Staff",
      type: "switch",
      mdColSpan: 4, // Define the column span for medium-sized screens
      handleInputChange: handleInputChange,
      value: formData.is_client_staff,
      visibleTo: ["Administrator", "Staff", "Client Administrator"]
    },
  ];



  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        User
      </h4>

      {/* content */}
      {/* inputs */}
      <Grid container spacing={2}>
        {formFields.map(field => {
          return (
            <DynamicFormField
              key={field.id}
              field={field}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          );
        })}

      </Grid>
      {/* show user last login */}
      <div className="mt-5 text-sm text-gray-500 dark:text-gray-400">
        {formData.last_login ? `Last login: ${new Date(formData.last_login).toLocaleString()}` : ""}
      </div>
    </div >
  );
};

export default Resource;
