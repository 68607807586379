import React, { useState } from 'react';
import { useFetchData } from "services/queries";
import EditableDataGrid from "components/EditableDataGrid/EditableDataGrid";
import { Box, TextField, Autocomplete } from '@mui/material';

const ProductAttribute = ({ formData }) => {
  const resourcePath = 'product/attribute/product-attribute';
  const relatedResources = `product/attribute/product-attribute/?product=${formData.id}`;
  const { data: ValuesAttributes, isLoading: isLoadingValuesAttributes, error: errorValuesAttributes } = useFetchData(`product/attribute/attribute-value/`, true);
  const { data: relatedCategoryAttributes, isLoading: isLoadingRelatedCategoryAttributes, error: errorRelatedCategoryAttributes} = useFetchData(`product/category/${formData.category_id}/get_related_category_attributes/`, true); 

  const [tableData, setTableData] = useState([]);

  const handleAttributeChange = (event, newValue, id) => {
    const updatedRow = tableData.map(row => {
      if (row.id === id) {
        return {
          ...row,
          name: newValue ? newValue.name : null,
          value: '',
        };
      }
      return row;
    });
    setTableData(updatedRow);
  };

  const handleValueChange = (event, newValue, id) => {
    const updatedRow = tableData.map(row => {
      if (row.id === id) {
        return {
          ...row,
          value: newValue ? newValue.id : null,
        };
      }
      return row;
    });
    setTableData(updatedRow);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Attribute Name',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      minWidth: 250,
      valueGetter: (params) => {
        return params?.row?.name || '';
      },
      valueOptions: relatedCategoryAttributes?.map((attr) => ({
        value: attr.id,
        label: attr.name,
      })),
      renderCell: (params) => {
        const selectedValueAttr = ValuesAttributes.find(val => val.id === params.row.value);
        const selectedCategoryAttribute = relatedCategoryAttributes.find(attr => attr?.id === selectedValueAttr?.category_attribute);
      return selectedCategoryAttribute ? selectedCategoryAttribute.name : '';
      },
      renderEditCell: (params) => {
        return (
          <Autocomplete
            value={relatedCategoryAttributes.find(attr => attr.name === params.row.name) || null}
            onChange={(event, newValue) => handleAttributeChange(event, newValue, params.id)}
            options={relatedCategoryAttributes}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <li {...props}>
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Select Attribute" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
          />
        );
      },
      valueSetter: (value, row) => {
        const selectedAttribute = relatedCategoryAttributes.find(attr => attr.id === value);
        if (!selectedAttribute) {
          return row;
        }
        const updatedRow = {
          ...row,
          name: selectedAttribute.name,
          value: '',
        };
        return updatedRow;
      },
    },
    {
      field: 'value',
      headerName: 'Attribute Value',
      editable: true,
      type: 'string',
      flex: 1,
      valueGetter: (params) => {
        return params?.row?.value || '';
      },
      renderCell: (params) => {
        const selectedValueAttr = ValuesAttributes.find(val => val.id === params.row.value);
        return selectedValueAttr ? selectedValueAttr.value : '';
      },
      renderEditCell: (params) => {
        const selectedAttribute = relatedCategoryAttributes.find(attr => attr.name === params.row.name);
        const filteredValueOptions = selectedAttribute
          ? ValuesAttributes.filter(val => val.category_attribute === selectedAttribute.id)
          : [];
        return (
          <Autocomplete
            value={filteredValueOptions.find(val => val.id === params.row.value) || null}
            onChange={(event, newValue) => handleValueChange(event, newValue, params.id)}
            options={filteredValueOptions}
            getOptionLabel={(option) => option.value}
            renderOption={(props, option) => (
              <li {...props}>
                {option.value}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Select Value" />}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
          />
        );
      },
      valueSetter: (value, row) => {
        const selectedValue = ValuesAttributes.find(val => val.id === value);
        if (!selectedValue) {
          return row;
        }
        const updatedRow = {
          ...row,
          value: selectedValue.value,
        };
        return updatedRow;
      },
    },
  ];

  if (isLoadingValuesAttributes || isLoadingRelatedCategoryAttributes) return 'Loading...';
  if (errorValuesAttributes || errorRelatedCategoryAttributes) return 'Error...';

  const actions = {
    create: true,
    edit: true,
    delete: true,
  };

  const newRowTemplate = {
    product: formData.id,
    name: '',
    value: '',
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">
        Product Attributes
      </h3>
      <Box>
        <EditableDataGrid
          columns={columns}
          resourcePath={resourcePath}
          tableData={tableData}
          setTableData={setTableData}
          relatedResources={relatedResources}
          actions={actions}
          autosizeOnMount={true}
          newRowTemplate={newRowTemplate}
        />
      </Box>
    </div>
  );
};

export default ProductAttribute;