import React from "react";
import Card from 'components/card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { useFetchData } from 'services/queries';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

const StyledCard = styled(Card)(({ theme }) => ({
    width: 220,
    borderRadius: '16px',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.text.secondary,
}));

const Amount = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.2rem',
}));
const SubInfo = styled(Typography)(({ theme }) => ({
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
}));
const HeaderBoxes = (props) => {
    const { startDate, endDate, status } = props;

    const notInvoiceIncomingStatuses = [
      'Open',
      'Awaiting Approval',
      'Discontinued',
      'Ready for Processing'
  ];

  const invoiceIncomingStatuses = status
      .filter(status => !notInvoiceIncomingStatuses.includes(status.status))
      .map(status => status.id)
      .join(',');


    const openStatusesOrders = [
      'Open', 
      'Awaiting Approval',
      'Ready for Processing',
    ];
    const openIds = status
      .filter(status => openStatusesOrders.includes(status.status))
      .map(status => status.id)
      .join(',');

    const statusDiscontinuedId = status.find(status => status.status === 'Discontinued').id;
    
    let params_url = ''
    if (startDate || endDate) {
        params_url = `?issue_date__gte=${startDate}&issue_date__lte=${endDate}`;
    }
    const { data: dataOpenOrder, error: errorDataOpenOrder, isLoading: isLoadingDataOpenOrder } = useFetchData(`order/sales-order/total-invoice-value/${params_url}&status__in=${openIds}`, true);
    const { data: dataClosedOrder, error: errorDataClosedOrder, isLoading: isLoadingDataClosedOrder } = useFetchData(`order/sales-order/total-invoice-value/${params_url}&status__in=${invoiceIncomingStatuses}`, true);
    const { data: dataGrossMargin, error: errorDataGrossMargin, isLoading: isLoadingDataGrossMargin } = useFetchData(`order/sales-order/get-gross-margin/${params_url}&status__in=${invoiceIncomingStatuses}`, true);
    const { data: dataAverageTicket, error: errorDataAverageTicket, isLoading: isLoadingDataAverageTicket } = useFetchData(`order/sales-order/get-average-ticket/${params_url}`, true);
    const { data: dataTotalDiscontinued, error: errorDataTotalDiscontinued, isLoading: isLoadingDataTotalDiscontinued } = useFetchData(`order/sales-order/total-invoice-value/${params_url}&status=${statusDiscontinuedId}`, true);

    if (isLoadingDataOpenOrder || isLoadingDataClosedOrder || isLoadingDataGrossMargin || isLoadingDataAverageTicket || isLoadingDataTotalDiscontinued) {
        return <div>Loading...</div>;
    }
    if (errorDataOpenOrder || errorDataClosedOrder || errorDataGrossMargin || errorDataAverageTicket || errorDataTotalDiscontinued){
        return <div>Error...</div>;
    }
    return (
        <div>
            <Grid container spacing={1} justifyContent="space-around" alignItems="center" padding={1} wrap="nowrap">
                <Grid xs={10} md={6} lg={2.5}>
                    <StyledCard>
                        <CardContent style={{ padding: '18px' }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Opened Orders</Title>
                                    <Amount>{dataOpenOrder.total_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    {dataOpenOrder.count}
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid xs={10} md={6} lg={2.5}>
                    <StyledCard>
                        <CardContent style={{ padding: '18px' }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Total Revenue</Title>
                                    <Amount>{dataClosedOrder.total_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    {dataClosedOrder.count}
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid xs={10} md={6} lg={2.5}>
          <StyledCard>
            <CardContent style={{ padding: '18px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Title>Total Discontinued</Title>
                  <Amount>
                    {dataTotalDiscontinued && dataTotalDiscontinued.total_value
                      ? dataTotalDiscontinued.total_value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                      : '$0.00'}
                  </Amount>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="primary.main"
                  color="primary.contrastText"
                  borderRadius="50%"
                  width="40px"
                  height="40px"
                  marginRight="10px"
                >
                  {dataTotalDiscontinued ? dataTotalDiscontinued.count : 0}
                </Box>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
                <Grid xs={10} md={6} lg={2.5}>
                    <StyledCard>
                        <CardContent style={{ padding: '18px' }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Gross Margin</Title>
                                    <Amount>{dataGrossMargin.gross_margin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    $
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>
                <Grid xs={10} md={6} lg={2.5}>
                    <StyledCard>
                        <CardContent style={{ padding: '18px' }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Title>Avg. Ticket / Order</Title>
                                    <Amount>{dataAverageTicket.average_ticket.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Amount>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="primary.main"
                                    color="primary.contrastText"
                                    borderRadius="50%"
                                    width="40px"
                                    height="40px"
                                    marginRight="10px"
                                >
                                    $
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>

            </Grid>
        </div>
    );
};

export default HeaderBoxes;