import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { API_BASE_URL } from 'config';

import { useLoading } from 'contexts/LoadingContext';
import { useNotification } from 'contexts/NotificationContext';

function SignInDefault() {
  const { setAuth } = useAuth();

  const { setLoading } = useLoading();
  const { setNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/authentication/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password: pwd }),
      });
      const data = await response.json();
      if (response.status === 200) {
        const accessToken = data.access;
        const refreshToken = data.refresh;
        const decodedToken = jwt_decode(accessToken);
        const is_superuser = decodedToken.is_superuser;
        const is_staff = decodedToken.is_staff;
        const groups = decodedToken.groups;
        const user_id = decodedToken.user_id;
        const name = decodedToken.first_name;

        setAuth(() => ({ ...decodedToken, accessToken, refreshToken }));

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        navigate(from, { replace: true });
      } else {
        setNotification({
          title: 'Error',
          description: 'Incorrect username and/or password.',
          display: true,
          type: 'error',
        });
      }
    } catch (error) {
      console.error(error);
      setNotification({
        title: 'Error',
        description: 'Something went wrong. Try again later.',
        display: true,
        type: 'error',
      });
    }
    setLoading(false);
  };

  return (
    <Default
      maincard={
        <div className="mb-16 mt-8 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
          {/* Sign in section */}
          <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="mb-2.5 text-center text-4xl font-bold text-navy-700  dark:text-white">
              Sign In
            </h3>
            <p className="mb-9 ml-1 text-center text-base text-gray-600">
              Enter your email and password to sign in!
            </p>

            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="mail@simmmple.com"
              id="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(() => e.target.value)}
            />

            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Min. 8 characters"
              id="password"
              type="password"
              value={pwd}
              onChange={(e) => setPwd(() => e.target.value)}
            />

            <button
              onClick={handleSubmit}
              className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Sign In
            </button>

            <Link
              to={'/auth/forgot-password'}
              className="text-base leading-10 text-brand-500"
            >
              Forgot password?
            </Link>
          </div>
        </div>
      }
    />
  );
}

export default SignInDefault;
